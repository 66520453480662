import { toggleMatchesTypeFilterRoutine, setSelectedMatchesTypeFilterRoutine } from '@/store/modules/matchesTypeFilter/routines'

export default {
  [toggleMatchesTypeFilterRoutine.TRIGGER] (state, filterName) {
    const index = state.selectedFilters.findIndex(el => el === filterName)
    if (index === -1) {
      state.selectedFilters.push(filterName)
    } else {
      state.selectedFilters.splice(index, 1)
    }
  },
  [setSelectedMatchesTypeFilterRoutine.TRIGGER] (state, selectedFilters) {
    state.selectedFilters = selectedFilters
  }
}
