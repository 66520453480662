<template>
  <div class="swoop-logo-container">
    <img class="swoop-logo" :src="logo" :height="height" :width="width" alt="logo" @click="$emit('click')" />
  </div>
</template>

<script>
import { base64ToSVG } from '../utils/utils'

export default {
  name: 'SwoopLogo',
  mixins: {
    base64ToSVG
  },
  props: {
    logoType: {
      type: String,
      default: 'default'
    },
    height: {
      type: String,
      default: 'auto'
    },
    width: {
      type: String,
      default: 'auto'
    }
  },
  data() {
    return {
      logo: require('@/assets/images/logo.svg')
    }
  },
  methods: {
    changeLogo() {
      if (window.WHITE_LABEL_STYLES && this.logoType === 'default') {
        this.logo = window.WHITE_LABEL_STYLES.logo
      } else if (window.WHITE_LABEL_STYLES && this.logoType === 'signup') {
        this.logo = window.WHITE_LABEL_STYLES.signupLogo
      }
    }
  },
  mounted() {
    this.changeLogo()
  },
  watch: {
    logoType() {
      this.changeLogo()
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/styles/swoop/variables';

.swoop-logo {
  display: block;
  max-height: 250px;
  max-width: 280px;
  margin-right: auto;
  margin-left: auto;

  @media only screen and (max-width: $breakpoint-sm-max) {
    max-width: 200px;
    max-height: 100px;
  }
}
</style>
