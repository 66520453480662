import * as auth from './auth'
import * as bank from './bank'
import * as tenant from './tenant'
import * as sidebar from './sidebar'
import * as company from './company'
import * as forwardAI from './forwardAI'
import * as onboarding from './onboarding'
import * as formFields from './formFields'
import * as transition from './transition'
import * as creditScore from './creditScore'
import * as opportunity from '@product/store/modules/opportunity'
import * as formMetaData from './formMetaData'
import * as pageOverlay from './pageOverlay'
import * as notification from './notification'
import * as illionIframeRoutine from './illion'
import * as navigationBars from './navigationBars'
import * as swoopAssistant from './swoopAssistant'
import * as legacyLoansData from './legacyLoansData'
import * as dynamicFormList from './dynamicFormList'
import * as goalsStepCounter from './goalsStepCounter'
import * as matchesTypeFilter from './matchesTypeFilter'
import * as currentFormDetails from './currentFormDetails'
import * as geometricBackground from './geometricBackground'
import * as notificationMessages from './notificationMessages'
import * as cashFlow from './cashFlow'
import * as aggregator from '@product/store/modules/aggregator'
import * as sortBar from './sortBar'

export default {
  auth,
  bank,
  tenant,
  sidebar,
  company,
  sortBar,
  forwardAI,
  formFields,
  onboarding,
  aggregator,
  transition,
  creditScore,
  pageOverlay,
  formMetaData,
  opportunity,
  notification,
  swoopAssistant,
  navigationBars,
  dynamicFormList,
  legacyLoansData,
  goalsStepCounter,
  matchesTypeFilter,
  currentFormDetails,
  geometricBackground,
  notificationMessages,
  illionIframeRoutine,
  cashFlow
}
