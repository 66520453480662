import { swoopAPI, recommendationApi } from '@/api'

export const getEquityFormData = companyId => swoopAPI.get(`/api/v1/product/equity/${companyId}`)
export const putEquityFormData = data => swoopAPI.put('/api/v1/product/equity', data)

export const formFieldsMetaData = payload => swoopAPI.post('/api/v1/metadata', payload)

export const getGrantFormData = companyId => swoopAPI.get(`/api/v1/product/grant/${companyId}`)
export const putGrantFormData = data => swoopAPI.put('/api/v1/product/grant', data)

export const getLoanFormData = companyId => swoopAPI.get(`/api/v1/product/debt/${companyId}`)
export const putLoanFormData = data => swoopAPI.put('/api/v1/product/debt', data)

export const getGrant = opportunityId => swoopAPI.get(`/api/v1/opportunity/${opportunityId}/details`)

const dummyCompanyId = '00000000-0000-0000-0000-000000000000'

export const getGrants = (params) => recommendationApi.get(`/recommendations/${dummyCompanyId}?useCase=grant`, { params })

export const grantFinderGrantRegistration = data => swoopAPI.post('/api/v1/opportunity/grant/register', data)
