import requestFlow from '@/utils/requestFlow'
import { tenantDictionaryRoutine } from '@/store/modules/tenant/routines'

export default {
  ...requestFlow(tenantDictionaryRoutine).mockMutations,

  [tenantDictionaryRoutine.SUCCESS](state, payload) {
    state.tenantDictionary = payload
  }
}
