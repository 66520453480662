import createPersistedState from 'vuex-persistedstate'

// Excluding formFileToUpload here because it's a file object and can exceed 5MB(Max storage Quota)
const plugins = [
  createPersistedState({
    filter: (mutation) => mutation.type !== 'updateFormFileToUploadRoutine/TRIGGER'
  })
]

const devPlugins = [...plugins]

export default process.env.NODE_ENV !== 'production'
  ? plugins
  : devPlugins
