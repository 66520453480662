export default {
  path: 'open-banking',
  name: 'journey-open-banking',
  component: () => import(/* webpackChunkName: "open-banking" */ './openBanking.vue'),
  meta: {
    permissions: {
      roles: ['user'],
      redirectTo: {
        name: 'login'
      }
    }
  }
}
