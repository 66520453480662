import { CLEAR_GET_STARTED_FORM_DATA, saveGetStartedFormData, saveStageGoal } from './routines'

export default {
  [saveStageGoal.TRIGGER](context, payload) {
    context.commit(saveStageGoal.TRIGGER, payload)
  },
  [saveGetStartedFormData.TRIGGER](context, payload) {
    context.commit(saveGetStartedFormData.TRIGGER, payload)
  },
  [CLEAR_GET_STARTED_FORM_DATA](context) {
    context.commit(CLEAR_GET_STARTED_FORM_DATA)
  }
}
