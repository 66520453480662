import createRoutine from '@/utils/createRoutine'

export const forwardAIIntegrationRoutine = createRoutine('forwardAIIntegrationRoutine')
export const forwardAIErrorRoutine = createRoutine('forwardAIErrorRoutine')
export const routeNameAfterIntegrationRoutine = createRoutine('routeNameAfterIntegrationRoutine')

export const forwardAIGetters = {
  FORWARDAI_INTEGRATION: 'FORWARDAI_INTEGRATION',
  FORWARD_AI_ERROR: 'FORWARD_AI_ERROR',
  ROUTE_NAME_AFTER_INTEGRATION: 'ROUTE_NAME_AFTER_INTEGRATION'
}
export const FORWARDAI_CLEANUP = 'FORWARDAI_CLEANUP'
