import { authGetters } from '@/store/modules/auth/routines'
import { swoopOpenBankingPartnerTypeValue } from '@/utils/constants'

export default {
  [authGetters.USER]: state => state.user,
  [authGetters.IS_USER_OPEN_BANKING]: state => !!state.user && state.user.originType === swoopOpenBankingPartnerTypeValue,
  [authGetters.FULL_TOKEN]: state => state.auth.payload && `${state.auth.payload.tokenType} ${state.auth.payload.accessToken}`,
  [authGetters.AUTH_PAYLOAD]: state => state.auth.payload,
  [authGetters.IS_AUTHENTICATED]: state => !!(state.auth.payload && state.auth.payload.accessToken && state.user),
  [authGetters.TOKEN_EXPIRE_DATE]: state => state.auth.payload && new Date(Date.parse(state.auth.payload.loginDate) + state.auth.payload.expiresIn * 1000),
  [authGetters.PRODUCTS_UNLOCKED]: state => !!state.user && state.user.goalsCompleted?.length > 0,
  [authGetters.GROUP_ID]: state => state.groupId,
  [authGetters.GROUP_NAME]: state => state.groupName,
  [authGetters.IS_EXTERNAL_GROUP]: state => state.isExternalGroup,
  [authGetters.IS_INVITED_USER]: state => state.fromInvite,
  [authGetters.INVITED_USER_SOURCE]: state => state.inviteSource
}
