import router from '@/router'
import { AppSidebar, AppHeader, AppFooter } from '@/components'
import { allowOnlyWithCompletedJourney } from '@/router/routerGuards'

export default () => {
  router.addRoutes([
    {
      path: '/banks/performance-details',
      name: 'banks-performance-details',
      components: {
        default: () => import(/* webpackChunkName: "banks-performance-details" */ './performance-details/performance-details.vue'),
        sidebar: AppSidebar,
        header: AppHeader,
        footer: AppFooter
      },
      meta: {
        permissions: {
          roles: ['user'],
          redirectTo: {
            name: 'login'
          }
        }
      },
      beforeEnter: allowOnlyWithCompletedJourney()
    },
    {
      path: '/banks/unsecured-loans',
      name: 'banks-unsecured-loans',
      components: {
        default: () => import(/* webpackChunkName: "banks-unsecured-loans" */ './unsecured-loans/unsecured-loans.vue'),
        sidebar: AppSidebar,
        header: AppHeader,
        footer: AppFooter
      },
      meta: {
        permissions: {
          roles: ['user'],
          redirectTo: {
            name: 'login'
          }
        }
      },
      beforeEnter: allowOnlyWithCompletedJourney()
    },
    {
      path: '/banks/switch-account',
      name: 'banks-switch-account',
      components: {
        default: () => import(/* webpackChunkName: "banks-switch-account" */ './switch-account/switch-account.vue'),
        sidebar: AppSidebar,
        header: AppHeader,
        footer: AppFooter
      },
      meta: {
        permissions: {
          roles: ['user'],
          redirectTo: {
            name: 'login'
          }
        }
      },
      beforeEnter: allowOnlyWithCompletedJourney()
    },
    {
      path: '/banks/switch-barclays',
      name: 'banks-switch-barclays',
      component: () => import(/* webpackChunkName: "banks-switch-barclays" */ './switch-barclays/switch-barclays.vue'),
      meta: {
        permissions: {
          roles: ['user'],
          redirectTo: {
            name: 'login'
          }
        }
      },
      beforeEnter: allowOnlyWithCompletedJourney()
    },
    {
      path: '/bank/:bankName',
      name: 'bank-info',
      components: {
        default: () => import(/* webpackChunkName: "bank-info" */ './bank-info/bank-info.vue'),
        sidebar: AppSidebar,
        header: AppHeader,
        footer: AppFooter
      },
      meta: {
        permissions: {
          roles: ['user'],
          redirectTo: {
            name: 'login'
          }
        }
      },
      beforeEnter: allowOnlyWithCompletedJourney()
    },
    {
      path: '/bank-details/:bankAccountId/:bankDataId',
      name: 'bank-details',
      redirect: { name: 'bank-details-features-and-benefits' },
      component: () => import(/* webpackChunkName: "bank-details" */ './bank-details/bank-details.vue'),
      children: [
        {
          path: 'fee-and-tariffs',
          name: 'bank-details-fee-and-tariffs',
          component: () => import(/* webpackChunkName: "bank-details-fee-and-tariffs" */ './bank-details/children/FeesAndTariffs.vue')
          // beforeEnter: allowOnlyWithCompletedJourney()
        },
        {
          path: 'features-and-benefits',
          name: 'bank-details-features-and-benefits',
          component: () => import(/* webpackChunkName: "bank-details-features-and-benefits" */ './bank-details/children/FeaturesAndBenefits.vue')
          // beforeEnter: allowOnlyWithCompletedJourney()
        }
      ]
    },
    {
      path: '/static-bank-details/:bankAccountId/:bankDataId',
      name: 'static-bank-details',
      redirect: { name: 'static-bank-details-features-and-benefits' },
      component: () => import(/* webpackChunkName: "static-bank-details" */ './static-bank-details/static-bank-details.vue'),
      children: [
        {
          path: 'static-fee-and-tariffs',
          name: 'static-bank-details-fee-and-tariffs',
          component: () => import(/* webpackChunkName: "static-bank-details-fee-and-tariffs" */ './static-bank-details/children/StaticFeesAndTariffs.vue')
        },
        {
          path: 'static-features-and-benefits',
          name: 'static-bank-details-features-and-benefits',
          component: () => import(/* webpackChunkName: "static-bank-details-features-and-benefits" */ './static-bank-details/children/StaticFeaturesAndBenefits.vue')
        }
      ]
    }
  ])
}
