import Vue from 'vue'
import config from '@/config/global'
import dictionary from '@/config/dictionary'
import VueAxios from 'vue-axios'
import axios from 'axios'
import VueAuthorize from '@swoop-ltd/vue-authorize'
import VueInstant from '@swoop-ltd/vue-instant'
import VuePromiseBtn from 'vue-promise-btn'
import VModal from 'vue-js-modal'
import Notifications from 'vue-notification'
import Vuebar from 'vuebar'
import VShowSlide from 'v-show-slide'
import { VPopover } from 'v-tooltip'
import Toasted from 'vue-toasted'

import router from './router'
import initRoutes from './router/routes'

import App from './App'
import store from './store'

import Icon from './config/vue-awesome'
import roles from './config/roles'
import permissions from './config/permissions'
import initVeeValidations from './config/vee-validate.validations.js'
import vuePromiseBtnConf from './config/vue-promise-btn'
import VueMultianalytics, { vueMultianalyticsConf } from './config/vue-multianalytics.config'
import { getWhitelabelConfig } from './api/whitelabelAdmin/index'
import Meta from 'vue-meta'
import CurrencySymbol from '@/components/CurrencySymbol'

import registerComponents from './components'
import registerDirectives from './directives'
import registerFilters from './filters'
import { initSentry } from '@/config/sentry'
import { loadExternal, loadGeneratedScripts } from '@/utils/3rdPartyScripts'
import { getWhitelabelNameFromSubdomain, updateObject, updateCurrentGroup } from './utils/utils'
import { defaultWhitelabelStyles, whitelabelsWithoutConfigRequest } from './utils/constants'

import './registerServiceWorker'
import './assets/styles/index.scss'
// Setup vue config
Vue.config.productionTip = false

// Setup vue middlewares
Vue.use(VueAxios, axios)
Vue.use(VueAuthorize, {
  roles,
  permissions
})
Vue.use(VueInstant)
Vue.use(VModal)
Vue.use(Notifications)
Vue.use(VuePromiseBtn, vuePromiseBtnConf)

Vue.use(CurrencySymbol)
Vue.use(VueMultianalytics, vueMultianalyticsConf)
Vue.use(Vuebar)
Vue.use(VShowSlide)
Vue.use(Toasted)
Vue.use(Meta)

if (window.Cypress) {
  // Add `store` to the window object only when testing with Cypress
  window.store = store
}

initSentry()

// Init routes
initRoutes()

// Init vee-validate validations
initVeeValidations()

// Declare global custom components
registerComponents(Vue)
Vue.component('icon', Icon)
Vue.component('popover', VPopover)
// Declare global directives
registerDirectives(Vue)

// Declare global filters
registerFilters(Vue)

// Globals
Vue.prototype.$config = config
Vue.prototype.$dictionary = dictionary

const whitelabelNameFromSubdomain = getWhitelabelNameFromSubdomain()
let whitelabelStyles = defaultWhitelabelStyles

function getWhitelabel() {
  getWhitelabelConfig(whitelabelNameFromSubdomain).then(res => {
    if (res.data.config) updateObject(JSON.parse(res.data.config), config.whitelabel)
    if (res.data.dictionary) updateObject(JSON.parse(res.data.dictionary), config.dictionary)

    whitelabelStyles = {
      ...defaultWhitelabelStyles,
      logo: res.data.logo,
      signupLogo: res.data.signupLogo,
      subdomain: res.data.subdomain
    }

    if (res.data.group) whitelabelStyles.group = res.data.group
    if (res.data.colors) whitelabelStyles.colors = JSON.parse(res.data.colors)
  }).catch(e => {
    console.error('Failed to load whitelabel: \n', e)
  }).finally(() => { createApp() })
}

function createApp() {
  updateCurrentGroup(whitelabelStyles.group)
  window.WHITE_LABEL_STYLES = whitelabelStyles
  // eslint-disable-next-line no-unused-vars
  return new Vue({
    el: '#swoop-root',
    router,
    store,
    render: h => h(App),
    mounted() {
      document.dispatchEvent(new Event('render-event'))
      if (config.whitelabel.components.hubspotEnabled) {
        loadExternal({ src: 'https://js-eu1.hs-scripts.com/25104510.js' })
      }
      loadGeneratedScripts()
    }
  })
}

if (whitelabelsWithoutConfigRequest.includes(whitelabelNameFromSubdomain)) {
  whitelabelStyles.subdomain = whitelabelNameFromSubdomain
  createApp()
} else {
  getWhitelabel()
}
