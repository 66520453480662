import { sortByAmountRoutine, sortByInterestRateRoutine, sortByDecisionTimeRoutine, sortByProductTypeRoutine, SORT_BAR_CLEANUP } from './routines'
import { logoutRoutine } from '@/store/modules/auth/routines'
import { state as initialState } from './index'

export default {
  [sortByAmountRoutine.TRIGGER](context, payload) {
    context.commit(sortByAmountRoutine.TRIGGER, payload)
  },
  [sortByInterestRateRoutine.TRIGGER](context, payload) {
    context.commit(sortByInterestRateRoutine.TRIGGER, payload)
  },
  [sortByDecisionTimeRoutine.TRIGGER](context, payload) {
    context.commit(sortByDecisionTimeRoutine.TRIGGER, payload)
  },
  [sortByProductTypeRoutine.TRIGGER](context, payload) {
    context.commit(sortByProductTypeRoutine.TRIGGER, payload)
  },
  [logoutRoutine.SUCCESS](context) {
    context.commit(SORT_BAR_CLEANUP, initialState)
  }
}
