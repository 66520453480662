import router from './index'
import { companyGetters } from '@/store/modules/company/routines'
import { authGetters } from '@/store/modules/auth/routines'
import whitelabel from '@/config/whitelabel'
import config from '@/config/global'

const journeyState = {
  fundingApplication: {
    NOTFILLED: 'NotFilled',
    COMPLETED: 'Completed',
    AMOUNTSET: 'AmountSet'
  },
  integration: {
    SKIP: 'Skip',
    LINKED: 'Linked',
    NOTLINKED: 'NotLinked'
  },
  documentsState: {
    NOT_FILLED: 'NotFilled',
    SKIP: 'Skip',
    COMPLETED: 'Completed'
  },
  path: {
    DASHBOARD: 'dashboard',
    // BUSINESS: 'journey-business',
    INTEGRATE: 'journey-integrate',
    DOCUMENTS: 'journey-documents',
    FUNDINGREQUIREMENTS: 'journey-funding-requirements',
    FINANCIALINFORMATION: 'journey-financial-information'
  }
}

// Routes for which the Google Anayltics is disabled.
export const routeNamesWithoutGA = ['forgot-password', 'forgot-password-check-email', 'goals-form']

// Onboarding routes.
export const onboardingRoutes = ['product', 'goals', 'select-goal', 'goals-form', 'goals-form-sign-up', 'get-started', 'stage', 'details-page']

const handleOnBoardingJourneyRoutes = (redirectTo, next) => {
  const companyState = router.app.$store.getters[companyGetters.COMPANY_STATE]
  const productsUnlocked = router.app.$store.getters[authGetters.PRODUCTS_UNLOCKED]
  if (!config.whitelabel.journey.classicJourneyEnabled || productsUnlocked) {
    return next()
  }

  if (companyState.fundingApplicationState === journeyState.fundingApplication.NOTFILLED) {
    return next({ name: journeyState.path.FUNDINGREQUIREMENTS })
  } else if (
    companyState.isAccountancySoftwareLinked === journeyState.integration.NOTLINKED ||
    (whitelabel.features.linkBankAccount && companyState.isBankAccountLinked === journeyState.integration.NOTLINKED)
  ) {
    return next({ name: journeyState.path.INTEGRATE })
  } else if (
    companyState.documentsState === journeyState.documentsState.NOT_FILLED &&
    config.whitelabel.journey.classicJourney.find(step => step.name === journeyState.path.DOCUMENTS)
  ) {
    return next({ name: journeyState.path.DOCUMENTS })
  } else if (companyState.financialState === journeyState.fundingApplication.NOTFILLED) {
    return next({ name: journeyState.path.FINANCIALINFORMATION })
  } else if (
    companyState.fundingApplicationState === journeyState.fundingApplication.COMPLETED ||
    companyState.fundingApplicationState === journeyState.fundingApplication.AMOUNTSET
  ) {
    if (redirectTo.name !== journeyState.path.DASHBOARD) {
      return next()
    }
  }
  next(redirectTo)
}

export const allowOnlyGuests = (redirectTo = { name: journeyState.path.DASHBOARD }) => {
  let redirect = redirectTo

  // allows passing of passwordCreated when redirecting from login -> dashboard
  // for amplitude tracking
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const passwordCreated = urlParams.get('password_created')

  if (passwordCreated) {
    redirect = { name: journeyState.path.DASHBOARD, params: { passwordCreated: true } }
  }
  return (to, from, next) => {
    router.app.$authorize
      .isAuthorized(['guest'])
      .then(next())
      .catch(e => {
        return next(redirect)
      })
  }
}

export const allowOnlyWithCompany = (redirectTo = { name: journeyState.path.BUSINESS }) => {
  return (to, from, next) => {
    const companyId = router.app.$store.getters[companyGetters.COMPANY_ID]

    if (companyId) {
      next()
    } else {
      next(redirectTo)
    }
  }
}

export const allowOnlyWithCompletedJourney = (redirectTo = { name: journeyState.path.BUSINESS }) => {
  return (to, from, next) => {
    const currentCompanyId = router.app.$store.getters[companyGetters.COMPANY_CURRENT_ID]
    const companyId = router.app.$store.getters[companyGetters.COMPANY_ID]
    const isUserAccountant = router.app.$store.getters[authGetters.IS_USER_ACCOUNTANT]
    const lockedProducts = router.app.$store.getters[authGetters.PRODUCTS_UNLOCKED]

    if (!companyId && !currentCompanyId && isUserAccountant && to.name === 'loan-matches') {
      return next({ name: journeyState.path.DASHBOARD })
    } else if ((isUserAccountant && from.name !== journeyState.path.DASHBOARD) || (!config.whitelabel.journey.classicJourneyEnabled && lockedProducts)) {
      return next()
    }

    handleOnBoardingJourneyRoutes(redirectTo, next)
  }
}
