import createRoutine from '@/utils/createRoutine'

export const loginRoutine = createRoutine('login')
export const logoutRoutine = createRoutine('logout')
export const logoutLocalRoutine = createRoutine('logoutLocal')
export const registerRoutine = createRoutine('register')
export const userRoutine = createRoutine('user')
export const productsRoutine = createRoutine('products')
export const groupRoutine = createRoutine('group')

export const authGetters = {
  USER: 'USER',
  IS_USER_OPEN_BANKING: 'IS_USER_OPEN_BANKING',
  FULL_TOKEN: 'FULL_TOKEN',
  AUTH_PAYLOAD: 'AUTH_PAYLOAD',
  IS_AUTHENTICATED: 'IS_AUTHENTICATED',
  TOKEN_EXPIRE_DATE: 'TOKEN_EXPIRE_DATE',
  PRODUCTS_UNLOCKED: 'PRODUCTS_UNLOCKED',
  GROUP_ID: 'GROUP_ID',
  GROUP_NAME: 'GROUP_NAME',
  IS_EXTERNAL_GROUP: 'IS_EXTERNAL_GROUP',
  IS_INVITED_USER: 'IS_INVITED_USER',
  INVITED_USER_SOURCE: 'INVITED_USER_SOURCE'
}
