import router from '@/router'

export default (redirect) => {
  router.addRoutes([
    {
      path: '/',
      name: 'default',
      redirect
    }
  ])
}
