// DEPRECATED! Please, do not use this component going forward. This should be replaced with the same component inside /dynamicForms folder.
<template>
  <label :id="`${label.toLowerCase()}-radio-label`" class="ui radio" :class="{ 'is-checked': isChecked }">
    <input class="input-radio" type="radio" :name="name" :checked="isChecked" :value="modelValue" v-bind="{ disabled, readonly, required, tabindex }" @click="clickHandler" />
    <span>
      <i class="icon material-icons m-r-5">{{ isChecked ? 'radio_button_checked' : 'radio_button_unchecked' }}</i>
      <span>{{ label }}</span>
    </span>
  </label>
</template>

<script>
export default {
  name: 'InputRadio',
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      required: true
    },
    modelValue: {
      required: true
    },
    label: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: Number
    }
  },
  computed: {
    isChecked() {
      return this.value === this.modelValue
    }
  },
  methods: {
    clickHandler(e) {
      if (this.disabled || this.readonly || this.modelValue === this.value) return
      this.$emit('input', this.modelValue)
    }
  },
  // Should emit 'input' for correct vee-validation after another radio has been checked
  watch: {
    value(newValue) {
      if (this.isChecked) return
      this.$emit('input', newValue)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/swoop/variables';

.radio {
  display: inline-flex;
  align-items: center;
  font-weight: normal;
  padding-right: 10px;
  cursor: pointer;
  &.is-checked {
    .icon {
      color: $color-primary;
    }
  }
  &.invalid {
    .icon {
      color: var(--color-error-500);
    }
  }
  .holder {
    display: inline-table;
    white-space: nowrap;
  }
  .text {
    margin: 0 0 0 7px;
    white-space: normal;
  }
}
.input-radio {
  position: absolute;
  z-index: -1;
  opacity: 0;
  .icon {
    z-index: 1;
  }
  &:checked {
    + .holder .icon {
      color: $color-primary;
      .icon-checked {
        display: inline;
      }
    }
  }
  &:focus {
    + .holder {
      outline: none;
      box-shadow: $focus-box-shadow;
    }
  }
}
.icon-checked {
  display: none;
}
.icon,
.text {
  display: inline-block;
  vertical-align: middle;
}

$radio-types: ('primary' $color-primary) ('success' var(--color-success-500)) // ('info' var(--color-tertiary-400))
  ('warning' var(--color-warning-500)) ('danger' var(--color-error-500));

@each $radio-type in $radio-types {
  $radio-name: nth($radio-type, 1);
  $radio-color: nth($radio-type, 2);
  .radio-#{$radio-name} {
    .icon {
      color: $radio-color;
    }
  }
}
</style>
