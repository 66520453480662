import router from '@/router'

export default () => {
  router.addRoutes([
    {
      path: '/bulk-companies',
      name: 'bulk-companies',
      component: () => import(/* webpackChunkName: "bulk-companies" */ './bulkCompanies.vue'),
      meta: {
        permissions: {
          roles: ['user'],
          redirectTo: {
            name: 'login'
          }
        }
      }
    }
  ])
}
