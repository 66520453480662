import router from '@/router'

export default () => {
  router.addRoutes([
    {
      path: '/forwardai',
      name: 'forwardaiCallback',
      component: () => import(/* webpackChunkName: "forwardai" */ './forwardAI.Callback.vue')
    }
  ])
}
