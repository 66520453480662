import createRoutine from '@/utils/createRoutine'

export const currentFormNameRoutine = createRoutine('currentFormNameRoutine')
export const fundingAmountRoutine = createRoutine('fundingAmountRoutine')

export const currentFormNameGetters = {
  CURRENT_FORM_NAME: 'CURRENT_FORM_NAME'
}

export const fundingAmountGetters = {
  FUNDING_AMOUNT: 'FUNDING_AMOUNT'
}

export const CURRENT_FORM_NAME_CLEANUP = 'CURRENT_FORM_NAME_CLEANUP'
export const FUNDING_AMOUNT_CLEANUP = 'FUNDING_AMOUNT_CLEANUP'
