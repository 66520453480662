<template>
  <div class="root-modals">
    <modal name="accountingSystemIntegration" height="auto" :scrollable="true" :width="560" @closed="integrationModalCloseHandler">
      <div class="hello-modal text-center">
        <em class="ui title text-center m-b-1">Your accounting system integration.</em>
        <div class="ui text-lg text-center m-b-1">
          <p>You have successfully integrated with your accounting system.</p>
        </div>
        <button class="btn btn-primary" @click.prevent="integrationModalClickCloseHandler">Close</button>
      </div>
    </modal>
    <modal name="truelayerIntegrationModal" height="auto" :scrollable="true" :width="560" @closed="bankIntegrationModalCloseHandler">
      <div class="hello-modal text-center">
        <em class="ui title text-center m-b-1">Bank Account Integration.</em>
        <div class="ui text-lg text-center m-b-1">
          <p>You have successfully integrated with your bank account.</p>
        </div>
        <button class="btn btn-primary" @click.prevent="bankIntegrationModalClickCloseHandler">Close</button>
      </div>
    </modal>
    <modal name="unlinkingBankModal" height="auto" :scrollable="true" :width="560" @closed="integrationModalCloseHandler">
      <div class="hello-modal text-center">
        <em class="ui title text-center m-b-1">Unlink in progress.</em>
        <div class="ui text-lg text-center m-b-1">
          <p>Please wait until the unlink bank account is complete.</p>
        </div>
        <button class="btn btn-primary" @click.prevent="$modal.hide('unlinkingBankModal')">Close</button>
      </div>
    </modal>
    <modal name="coreDocumentsModal" height="auto" :scrollable="true" :width="850">
      <CoreDocuments />
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import { bankGetters } from '@/store/modules/bank/routines'
import { amplitudeEvents } from '@/utils/constants'
import CoreDocuments from '@/features/dashboard/components/CoreDocuments'

export default {
  name: 'RootModals',
  components: {
    CoreDocuments
  },
  computed: {
    ...mapGetters({
      userCompanies: companyGetters.COMPANIES_LIST,
      bankName: bankGetters.BANK_MANDATE_NAME
    })
  },
  methods: {
    integrationModalCloseHandler() {
      this.sendAmplitudeAccountIntegrationData()
      this.$ma.trackView({ viewName: 'FUNDING_REQS' })
    },
    bankIntegrationModalCloseHandler() {
      this.sendAmplitudeBankAccountIntegrationData()
    },
    sendAmplitudeBankAccountIntegrationData() {
      const properties = {
        'User Bank Name': this.bankName
      }
      this.$ma.setUserProperties({
        'User Bank Name': this.bankName
      })
      this.$ma.trackEvent({
        eventType: amplitudeEvents.fundingOnboarding.CONNECTED_BANK_OK,
        eventProperties: properties
      })
    },
    sendAmplitudeAccountIntegrationData() {
      this.$ma.trackEvent(amplitudeEvents.fundingOnboarding.CONNECTED_ACC_OK)
    },
    integrationModalClickCloseHandler() {
      this.$modal.hide('accountingSystemIntegration')
    },
    bankIntegrationModalClickCloseHandler() {
      this.$modal.hide('truelayerIntegrationModal')
    }
  }
}
</script>
