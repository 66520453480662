import {
  loanFormFieldsRoutine,
  enquiryFormFieldsRoutine,
  grantFormFieldsRoutine,
  equityFormFieldsRoutine,
  FORM_FIELDS_CLEANUP,
  enquiryFormSectionsRoutine
} from '@/store/modules/formFields/routines'

export default {
  [loanFormFieldsRoutine.TRIGGER](state, payload) {
    state.loanFormFields = payload
  },

  [enquiryFormFieldsRoutine.TRIGGER](state, payload) {
    state.enquiryFormFields = payload
  },

  [enquiryFormSectionsRoutine.TRIGGER](state, payload) {
    state.enquiryFormSections = payload
  },

  [grantFormFieldsRoutine.TRIGGER](state, payload) {
    state.grantFormFields = payload
  },

  [equityFormFieldsRoutine.TRIGGER](state, payload) {
    state.equityFormFields = payload
  },

  [FORM_FIELDS_CLEANUP](state, payload) {
    Object.assign(state, payload)
  }
}
