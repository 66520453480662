<template>
<footer class="container text-sm">
  <div class="row m-b-20">
    <div class="col-sm-12 col-md-10 col-md-offset-1 center-xs">Swoop Finance Ltd, authorised and regulated by the Financial Conduct Authority. Reference number 833145. If you feel you have a complaint, please visit <a href="/terms-conditions/" class="terms-conditions-link">{{origin}}/terms-conditions/</a> and read section 13 on Complaints.</div>
  </div>
  <div class="main-footer row">
    <div class="col-sm-12 col-md-5 center-xs start-md">
      <div class="row middle-xs">
        <div class="col-sm-12 col-md-3 m-b-10">
        </div>
        <div class="col-sm-12 col-md m-b-10">
          <ul class="footer__list">
            <li><a href="https://swoopfunding.com/cookie-policy/" target="_blank" rel="noopener">Cookie Policy</a></li>
            <li><a :href="$config.whitelabel.links.privacyPolicyLink" target="_blank" rel="noopener">Privacy Policy</a></li>
            <li><a :href="$config.whitelabel.links.termsAndConditionsLink" target="_blank" rel="noopener">Terms & Conditions</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-2 start-xs center-xs last-xs">
      <a class="footer__logo m-b-10">
        Swoop
      </a>
    </div>
    <div class="col-sm-12 col-md-5 center-xs end-md last-md m-b-10">
      <ul class="footer__list m-b-10">
        <li><a href="https://swoopfunding.com/about/" target="_blank" rel="noopener">About Us</a></li>
        <li><a href="https://swoopfunding.com/funding-solutions/" target="_blank" rel="noopener">Blog</a></li>
        <li><a href="https://swoopfunding.com/team/" target="_blank" rel="noopener">Team</a></li>
      </ul>
    </div>
  </div>
</footer>
</template>

<script>
import { marketingUrl } from '@/utils/constants'

export default {
  name: 'AppFooter',
  data () {
    return {
      marketingUrl,
      year: new Date().getFullYear(),
      origin: window.location.origin
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';
@import '../../assets/styles/swoop/mixins.scss';

.container {
  border-top: 1px solid $default-border-color;
  color: $color-footer-text;
  padding: 22px 0;

  .terms-conditions-link {
    color: $color-footer-text;
  }
  @media only screen and (max-width: $breakpoint-lg-max) {
    padding-bottom: 92px;
  }
  @media only screen and (max-width: $breakpoint-sm-max) {
    padding-bottom: 22px;
  }
}
.main-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

}

.footer__logo {
  @include hide-text();
  display: inline-flex;
  justify-content: center;
  align-content: center;
  background: #cbd1d5 url('../../assets/images/logo-white@2x.png') 50% 50% no-repeat;
  background-size: 10px auto;
  border-radius: $default-border-radius;
  width: 20px;
  height: 21px;
}

.footer__list {
  display: inline-flex;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin-left: 20px;
    &:first-child {
      margin-left: 0;
    }
    a {
      color: $color-link;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
