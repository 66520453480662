import router from '@/router'
import { AppSidebar, AppHeader } from '@/components'

export default () => {
  router.addRoutes([
    {
      path: '/integrations',
      name: 'integrations',
      components: {
        default: () => import(/* webpackChunkName: "integrations" */ './integrations.vue'),
        sidebar: AppSidebar,
        header: AppHeader
      },
      meta: {
        permissions: {
          roles: ['user'],
          redirectTo: {
            name: 'login'
          }
        }
      }
    }
  ])
}
