export default {
  path: 'financial-information',
  name: 'journey-financial-information',
  component: () => import(/* webpackChunkName: "funding-information" */ './financialInformation.vue'),
  meta: {
    permissions: {
      roles: ['user'],
      redirectTo: {
        name: 'login'
      }
    }
  }
}
