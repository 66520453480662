import router from '@/router'
import { allowOnlyWithCompletedJourney } from '@/router/routerGuards'

export default () => {
  router.addRoutes([
    {
      path: '/financial-information/:from?',
      name: 'financial-information',
      component: () => import(/* webpackChunkName: "financial-information" */ './financialInformation.vue'),
      meta: {
        permissions: {
          roles: ['user'],
          redirectTo: {
            name: 'login'
          }
        }
      },
      beforeEnter: allowOnlyWithCompletedJourney()
    }
  ])
}
