import { swoopAPI } from '@/api'

export const getFundingApplication = fundingApplicationId => swoopAPI.get(`/api/v1/fundingApplication/${fundingApplicationId}`)
export const setFundingApplication = data => swoopAPI.post('/api/v1/fundingApplication', data)
export const patchFundingApplication = data => swoopAPI.patch('/api/v1/fundingApplication', data)
export const patchFundingApplicationAmount = data => swoopAPI.patch('/api/v1/fundingApplication/amount', data)
export const completeFundingApplication = data => swoopAPI.post('/api/v1/fundingApplication/complete', data)
export const patchFundingApplicationType = data => swoopAPI.patch('/api/v1/fundingApplication/type', data)
export const getLegacyLoansFundingApplication = companyId => swoopAPI.get(`api/v1/${companyId}/fundingApplication`)
export const putLegacyLoansFundingApplication = data => swoopAPI.put('api/v1/product/debt', data)
