export default {
  path: 'integrate',
  name: 'journey-integrate',
  component: () => import(/* webpackChunkName: "integrate" */ './integrate.vue'),
  meta: {
    permissions: {
      roles: ['user'],
      redirectTo: {
        name: 'login'
      }
    }
  }
}
