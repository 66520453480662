// DEPRECATED! Please, do not use this component going forward. This should be replaced with the same component inside /dynamicForms folder.
<template>
  <label class="ui checkbox" :class="{ 'is-checked': value }">
    <input class="input-checkbox" type="checkbox" :name="name" :checked="value" v-bind="{ disabled, readonly, required, tabindex }" @click="clickHandler" />
    <span class="ui holder">
      <i class="icon material-icons">{{ value ? 'check_box' : 'check_box_outline_blank' }}</i>
      <span class="ui subtitle checkbox-label" v-if="label">{{ label }}</span>
    </span>
  </label>
</template>

<script>
export default {
  name: 'InputCheckbox',
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: [Number, String]
    }
  },
  methods: {
    clickHandler() {
      if (this.disabled || this.readonly) return
      this.$emit('input', !this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/swoop/variables';

.ui .checkbox {
  display: inline-block;
  font-weight: 300;
  line-height: inherit;
  white-space: nowrap;
  cursor: pointer;
  &.is-checked {
    .icon {
      color: $color-primary;
    }
  }
  .checkbox-label {
    white-space: pre-line;
  }
  .holder {
    display: inline-flex;
    i {
      margin-right: 6px;
    }
  }
  .text {
    margin: 0 0 0 7px;
  }
}
input[type='checkbox'] {
  height: 24px !important;
  width: 24px !important;

  position: absolute;
  z-index: -1;
  opacity: 0;
  &[disabled] {
    + .holder .icon {
      color: $bg-body;
    }
  }
  &:focus {
    + .holder {
      outline: none;
    }
  }
}
.text {
  white-space: normal;
}
.icon,
.text {
  display: inline-block;
  vertical-align: top;
}
</style>
