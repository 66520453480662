export default {
  path: 'company',
  name: 'journey-company',
  component: () => import(/* webpackChunkName: "company" */ './company.vue'),
  meta: {
    permissions: {
      roles: ['user'],
      redirectTo: {
        name: 'login'
      }
    }
  }
}
