<template>
  <div id="swoop-root">
    <router-view class="header" name="header" @toggle-mobile-sidebar="toggleMobileSidebar" @close-mobile-sidebar="closeMobileSidebar" />
    <div class="root-content">
      <router-view name="sidebar" ref="sidebar" :mobileSidebarOpen="mobileSidebarOpen" @close-mobile-sidebar="closeMobileSidebar" />
      <main class="content">
        <router-view />
        <div class="container">
          <router-view name="footer" />
        </div>
      </main>
    </div>
    <notifications :duration="10000" />
    <notifications group="auth" :duration="7000" :max="1" />
    <RootModals />
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import { authGetters, logoutRoutine } from '@/store/modules/auth/routines'
import { notificationGetters } from '@/store/modules/notification/routines'
import { tenantGetters } from '@/store/modules/tenant/routines'
import { LSKeys } from './utils/constants'
import RootModals from '@/components/layout/RootModals'
import { toastDefaultOptions } from './config/vue-toast'
import { routeNamesWithoutGA } from './router/routerGuards'

export default {
  name: 'App',
  components: {
    RootModals
  },
  data() {
    return {
      tokenIntervalPeriod: 2000,
      tokenIntervalTimerId: null,
      deployCheckTimerPeriod: 120000,
      deployCheckTimerId: null,
      mobileSidebarOpen: false
    }
  },
  metaInfo() {
    return {
      meta: [
        { property: 'og:title', content: 'Swoop Funding Platform' },
        { property: 'og:site_name', content: 'Swoop Funding Platform' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://swoopfunding.com/wp-content/uploads/2020/08/platform-funding-and-bank-comparison.png' },
        {
          property: 'og:description',
          content: 'Simple and speedy access to business finance. Get matched with tailored funding across loans, equity and grants, and cut business costs in one fell swoop.'
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image'
        },
        { name: 'twitter:title', content: 'Swoop Funding Platform' },
        {
          name: 'twitter:description',
          content: 'Simple and speedy access to business finance. Get matched with tailored funding across loans, equity and grants, and cut business costs in one fell swoop.'
        },
        { name: 'twitter:image', content: 'https://swoopfunding.com/wp-content/uploads/2020/08/platform-funding-and-bank-comparison.png' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      user: authGetters.USER,
      isLoggedIn: authGetters.IS_AUTHENTICATED,
      tokenExpireDate: authGetters.TOKEN_EXPIRE_DATE,
      tenantName: tenantGetters.TENANT_NAME,
      notificationConfig: notificationGetters.NOTIFICATION_CONFIG,
      isExternalGroup: authGetters.IS_EXTERNAL_GROUP
    })
  },
  methods: {
    ...mapActions({
      logout: logoutRoutine.TRIGGER
    }),
    async checkTokenExpiration() {
      if (this.tokenExpireDate <= new Date()) {
        if (!this.isLoggedIn) return
        await this.logout()
        this.$toasted.show('Session expired. Please Sign in again.', { ...toastDefaultOptions, type: 'warn', icon: 'info' })
      }
    },
    getLastDeployVersion() {
      return axios.get('/version.txt').then(res => res.data.trim())
    },
    async checkLastDeployChange() {
      const prevBuildVersion = localStorage.getItem(LSKeys.prevBuildVersion)
      const version = await this.getLastDeployVersion()
      try {
        localStorage.setItem(LSKeys.prevBuildVersion, version)
      } catch (e) {
        console.error('No access to Local Storage')
      }
      if (!prevBuildVersion) {
        this.deployCheckTimerId = setTimeout(this.checkLastDeployChange, this.deployCheckTimerPeriod)
        return
      }
      if (prevBuildVersion !== version) location.reload(true)
      this.deployCheckTimerId = setTimeout(this.checkLastDeployChange, this.deployCheckTimerPeriod)
    },
    handleOnMenuClick() {},
    toggleMobileSidebar() {
      this.mobileSidebarOpen = !this.mobileSidebarOpen
    },
    closeMobileSidebar() {
      this.mobileSidebarOpen = false
    },
    identifyAndTrackUser() {
      if (this.user) {
        if (this.$apm) {
          this.$apm.setUserContext({
            id: this.user.userId,
            email: this.user.email
          })
        }
        this.$ma.identify({
          userId: this.user.userId
        })
        this.$ma.setUserProperties({
          email: this.user.email,
          tenant: this.tenantName
        })
      }
    },
    updateWhitelabelColors() {
      if (window.WHITE_LABEL_STYLES.colors) {
        Object.keys(window.WHITE_LABEL_STYLES.colors).forEach(color => {
          document.documentElement.style.setProperty(color, window.WHITE_LABEL_STYLES.colors[color])
        })
      }
    },
    async enableGoogleAnalytics() {
      this.activateGTMInHeader(process.env.VUE_APP_GOOGLE_TAG_ID)
      this.activateGTMInBody(process.env.VUE_APP_GOOGLE_TAG_ID)
    },
    activateGTMInHeader(googleTagId) {
      const scriptId = 'google-analytics-script'

      if (document.getElementById(scriptId) || this.user?.email.endsWith('@swoopfunding.com')) return

      const dataLayer = 'dataLayer'

      window[dataLayer] = window[dataLayer] || []
      window[dataLayer].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })

      const f = document.getElementsByTagName('head')[0]
      const j = document.createElement('script')
      const dl = dataLayer !== 'dataLayer' ? '&l=' + dataLayer : ''

      j.id = scriptId
      j.async = true
      j.src = `https://www.googletagmanager.com/gtm.js?id=${googleTagId}${dl}`
      f.insertBefore(j, f.firstChild)
    },
    activateGTMInBody(googleTagId) {
      const noscriptId = 'google-analytics-noscript'

      if (document.getElementById(noscriptId) || this.user?.email.endsWith('@swoopfunding.com')) return

      const body = document.getElementsByTagName('body')[0]
      const noscript = document.createElement('noscript')

      noscript.id = noscriptId
      noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${googleTagId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`

      body.insertBefore(noscript, body.firstChild)
    }
  },
  created() {
    this.enableGoogleAnalytics()
    this.updateWhitelabelColors()
  },
  mounted() {
    if (!this.isExternalGroup) {
      this.identifyAndTrackUser()
    }
    if (this.isLoggedIn === true) {
      this.tokenIntervalTimerId = setInterval(this.checkTokenExpiration, this.tokenIntervalPeriod)
    }
    this.deployCheckTimerId = setTimeout(this.checkLastDeployChange, 1000)
  },
  destroyed() {
    clearInterval(this.tokenIntervalTimerId)
    clearTimeout(this.deployCheckTimerId)
  },
  watch: {
    notificationConfig(config) {
      if (Object(config) === config) {
        let message = ''
        if (typeof config.text === 'object' && config.text?.File) {
          message = config.text.File.errors[0].errorMessage || ''
        } else {
          const responseText = config?.text?.split('\n')
          message = responseText[1] && responseText[1].length > 1 && responseText[1]
        }
        message = message && message.match(/Unknown/) ? 'Something went wrong, please try again later' : message
        this.$toasted.clear()
        this.$toasted.show(`${message || config.text}`, { ...(config.options || toastDefaultOptions), type: config.type, icon: config.type, singleton: true })
      }
    },
    isLoggedIn(isLoggedIn) {
      if (isLoggedIn === true) {
        this.tokenIntervalTimerId = setInterval(this.checkTokenExpiration, this.tokenIntervalPeriod)
        this.$ma.identify({
          userId: this.user.userId
        })
        this.$ma.setUserProperties({
          email: this.user.email,
          tenant: this.tenantName
        })
      } else {
        clearInterval(this.tokenIntervalTimerId)
      }
    },
    $route(to) {
      const isTestUser = this.user?.email.endsWith('@swoopfunding.com')

      // Triggering Google Analytics for every page change.
      if (!routeNamesWithoutGA.includes(to.name) && window.dataLayer && !isTestUser) {
        window.dataLayer.push({ event: 'pageview' })
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/swoop/_variables.scss';

#swoop-root {
  position: relative;
  overflow-x: clip;

  .root-content {
    display: flex;
    width: 100%;
    min-height: 100vh;

    main.content {
      width: 100vw;

      @media only screen and (max-width: $breakpoint-sm-max) {
        width: 100%;
      }
    }
  }

  .header ~ .root-content {
    main.content {
      margin-top: 61px;
      background-color: #f9fafb;
    }
  }
}
</style>
