import createRoutine from '@/utils/createRoutine'

export const illionIframeRoutine = createRoutine('illionIframeRoutine')
export const illionBankstatementsRoutine = createRoutine('illionBankstatementsRoutine')

export const illionGetters = {
  IFRAME_URL: 'IFRAME_URL',
  BANK_STATEMENTS: 'BANK_STATEMENTS'
}

export const ILLION_CLEANUP = 'ILLION_CLEANUP'
