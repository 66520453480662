import { updateFormFileToUploadRoutine, formMetaDataRoutine, FORM_META_DATA_CLEANUP, updateFormFileToDeleteRoutine } from '@/store/modules/formMetaData/routines'
import { logoutRoutine } from '@/store/modules/auth/routines'
import { formFieldsMetaData } from '@product/api/products'
import requestFlow from '@/utils/requestFlow'
import { state as initialState } from './index'

export default {
  ...requestFlow(formMetaDataRoutine, formFieldsMetaData).actions,

  [logoutRoutine.SUCCESS] (context) {
    context.commit(FORM_META_DATA_CLEANUP, initialState)
  },

  [updateFormFileToUploadRoutine.TRIGGER] (context, payload) {
    context.commit(updateFormFileToUploadRoutine.TRIGGER, payload)
  },

  [updateFormFileToDeleteRoutine.TRIGGER] (context, payload) {
    context.commit(updateFormFileToDeleteRoutine.TRIGGER, payload)
  }
}
