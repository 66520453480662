import { defaultGroupId } from '@/utils/constants'

export default {
  user: null,
  auth: {
    payload: null,
    error: null
  },
  productsUnlocked: false,
  groupId: defaultGroupId,
  groupName: 'Swoop',
  isExternalGroup: false,
  fromInvite: false,
  inviteSource: null
}
