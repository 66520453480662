import { allowOnlyWithCompletedJourney } from '@/router/routerGuards'
import { AppSidebar, AppHeader } from '@/components'
import FooterOutgoingLinks from '@/components/FooterOutgoingLinks'
import router from '@/router'

const isUK = process.env.VUE_APP_TENANT === 'uk'

export default () => {
  router.addRoutes([
    {
      path: '/product',
      name: 'product',
      redirect: { name: 'dashboard' },
      components: {
        default: () => import(/* webpackChunkName: "matches-form-layout" */ './layouts/matchesFormLayout.vue'),
        header: AppHeader
      },
      children: [
        {
          path: 'loan',
          name: 'loan-matches-form',
          redirect: {
            name: 'goals',
            query: {
              type: 'loans'
            }
          }
        },
        {
          path: 'equity',
          name: 'equity-matches-form',
          redirect: {
            name: 'goals',
            query: {
              type: 'equity'
            }
          }
        },
        {
          path: 'grant',
          name: 'grant-matches-form',
          redirect: {
            name: 'goals',
            query: {
              type: 'grant'
            }
          }
        }
      ]
    },
    {
      path: '/matches',
      name: 'matches',
      redirect: { name: process.env.VUE_APP_TENANT === 'uk' ? 'matches-overview' : 'loan-matches' },
      components: {
        default: () => import(/* webpackChunkName: "matches-layout" */ './layouts/matchesLayout.vue'),
        header: AppHeader,
        sidebar: AppSidebar,
        footer: FooterOutgoingLinks
      },
      beforeEnter: allowOnlyWithCompletedJourney(),
      children: [
        {
          path: 'loans',
          name: 'loan-matches',
          component: () => import(/* webpackChunkName: "loan-matches" */ './loan/loanMatches.vue')
        },
        {
          path: 'equity',
          name: 'equity-matches',
          component: () => import(/* webpackChunkName: "equity-matches" */ './equity/equityMatches.vue')
        },
        {
          path: 'grants',
          name: 'grant-matches',
          component: () => import(/* webpackChunkName: "grant-matches" */ './grant/grantMatches.vue')
        },
        {
          path: 'commercial-mortgages',
          name: 'commercial-mortgage-matches',
          component: () => import(/* webpackChunkName: "commertcal-mortgage-mathces" */ './mortgage/mortgageMatches.vue')
        },
        {
          path: 'overview',
          name: 'matches-overview',
          component: () => import(/* webpackChunkName: "matches" */ './matches/matches.vue'),
          beforeEnter: (to, from, next) => {
            if (!isUK) {
              next({ name: 'loan-matches' })
            } else {
              next()
            }
          }
        }
      ]
    }
  ])
}
