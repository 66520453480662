import {
  loanFormFieldsRoutine,
  enquiryFormFieldsRoutine,
  grantFormFieldsRoutine,
  equityFormFieldsRoutine,
  FORM_FIELDS_CLEANUP,
  enquiryFormSectionsRoutine
} from '@/store/modules/formFields/routines'
import { logoutRoutine } from '@/store/modules/auth/routines'
import { state as initialState } from './index'

export default {
  [loanFormFieldsRoutine.TRIGGER](context, payload) {
    context.commit(loanFormFieldsRoutine.TRIGGER, payload)
  },

  [enquiryFormFieldsRoutine.TRIGGER](context, payload) {
    context.commit(enquiryFormFieldsRoutine.TRIGGER, payload)
  },

  [enquiryFormSectionsRoutine.TRIGGER](context, payload) {
    context.commit(enquiryFormSectionsRoutine.TRIGGER, payload)
  },

  [grantFormFieldsRoutine.TRIGGER](context, payload) {
    context.commit(grantFormFieldsRoutine.TRIGGER, payload)
  },

  [equityFormFieldsRoutine.TRIGGER](context, payload) {
    context.commit(equityFormFieldsRoutine.TRIGGER, payload)
  },

  [logoutRoutine.SUCCESS](context) {
    context.commit(FORM_FIELDS_CLEANUP, initialState)
  }
}
