import requestFlow from '@/utils/requestFlow'
import { SET_ACTIVE_STEP_INDEX, SET_STEP_COUNTER_LIST, RESET_STEP_COUNTER_STATE, stepCounterRoutine } from '@/store/modules/goalsStepCounter/routines'

export default {
  ...requestFlow(stepCounterRoutine).mockMutations,

  [SET_STEP_COUNTER_LIST] (state, payload) {
    state.stepCounterItems = payload
  },

  [SET_ACTIVE_STEP_INDEX] (state, payload) {
    state.activeStepIndex = payload
  },

  [RESET_STEP_COUNTER_STATE] (state) {
    state.stepCounterItems = []
    state.activeStepIndex = 0
  }
}
