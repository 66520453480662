<template>
<div class="slide-up-down-wrapper" :style="style" ref="wrapper">
  <slot />
</div>
</template>

<script>
const requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame

export default {
  name: 'SlideUpDown',
  props: {
    active: Boolean,
    duration: {
      type: Number,
      default: 500
    }
  },
  data () {
    return {
      maxHeight: 0
    }
  },
  computed: {
    style () {
      return {
        height: `${this.maxHeight}px`,
        transitionDuration: `${this.duration}ms`
      }
    }
  },
  methods: {
    animate () {
      if (requestAnimationFrame) {
        requestAnimationFrame(this.slide)
      } else {
        this.slide()
      }
    },
    slide () {
      if (this.active) {
        this.maxHeight = this.$refs.wrapper.scrollHeight
      } else {
        this.maxHeight = 0
      }
    }
  },
  mounted () {
    this.animate()
  },
  watch: {
    active () {
      this.animate()
    }
  }
}
</script>

<style scoped>
.slide-up-down-wrapper {
  overflow: hidden;
  transition-property: height;
  transition-timing-function: ease-in-out;
}
</style>
