import { toggleMatchesTypeFilterRoutine, setSelectedMatchesTypeFilterRoutine } from '@/store/modules/matchesTypeFilter/routines'

export default {
  [toggleMatchesTypeFilterRoutine.TRIGGER] (context, filterName) {
    context.commit(toggleMatchesTypeFilterRoutine.TRIGGER, filterName)
  },
  [setSelectedMatchesTypeFilterRoutine.TRIGGER] (context, selectedFilters) {
    context.commit(setSelectedMatchesTypeFilterRoutine.TRIGGER, selectedFilters)
  }
}
