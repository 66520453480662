import router from '@/router'

import company from './company/company.routes'
import integrate from './integrate/integrate.routes'
import documents from './documents/documents.routes'
import openBanking from './openBanking/openBanking.routes'
import welcome from './welcome/welcome.routes'
import fundingRequirements from './fundingRequirements/fundingRequirements.routes'
import financialInformation from './financialInformation/financialInformation.routes'
import personalInfo from './personalInfo/personalInfo.routes'

// Setup routes
export default () => {
  router.addRoutes([
    {
      path: '/journey',
      name: 'journey',
      redirect: { name: 'journey-funding-requirements' },
      component: () => import(/* webpackChunkName: "journey" */ './journey.vue'),
      meta: {
        permissions: {
          roles: ['user'],
          redirectTo: {
            name: 'login'
          }
        }
      },
      children: [company, integrate, documents, openBanking, welcome, fundingRequirements, financialInformation, personalInfo]
    }
  ])
}
