import { checkDynamicFormListUpdatedRoutine, updateDynamicFormListRoutine } from '@/store/modules/dynamicFormList/routines'

export default {
  [updateDynamicFormListRoutine.SUCCESS] (state, payload) {
    state.dynamicFormList = payload
  },

  [checkDynamicFormListUpdatedRoutine.SUCCESS] (state, payload) {
    state.dynamicFormList = payload
  }
}
