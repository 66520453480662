import { CLEAR_GET_STARTED_FORM_DATA, saveGetStartedFormData, saveStageGoal } from '@/store/modules/onboarding/routines'

export default {
  [saveStageGoal.TRIGGER](state, payload) {
    state.stageGoal = payload
  },
  [saveGetStartedFormData.TRIGGER](state, payload) {
    state.getStartedFormData = payload
  },
  [CLEAR_GET_STARTED_FORM_DATA](state) {
    state.getStartedFormData = null
  }
}
