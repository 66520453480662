import Vue from 'vue'
import Router from 'vue-router'
import { allowedRouteNamesWithoutTenantCheck, subdomainList, tenantCookieName } from '@/utils/constants'
import { authGetters, logoutRoutine } from '@/store/modules/auth/routines'
import { getCookie, getURLSubdomain, getWhitelabelNameFromSubdomain } from '@/utils/utils'
import store from '@/store'
import { lloydsGrantFinderRoutes } from '@/features/lloyds/lloyds.routes'
import { natwestGrantFinderRoutes } from '@/features/natwest/natwest.routes'

Vue.use(Router)

// Add routes dynamically in features.
const router = new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: []
})

router.beforeEach(function (to, from, next) {
  const groupName = store.getters[authGetters.GROUP_NAME] === 'Swoop' ? 'Swoop Funding' : store.getters[authGetters.GROUP_NAME]
  if (to.name?.split()) {
    document.title =
      to.name
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ') + ` - ${groupName}`
  } else {
    document.title = 'Swoop Funding Platform'
  }

  /**
   * Check that the user belongs to current tennant
   * Check user access to certain pages if they were invited to onboard
   * the default route for invited users is the integrations page
   */
  if (!checkTenant(to) || (store.getters.IS_EXTERNAL_GROUP && !checkInvitedClientsAccess(to))) {
    return next({ name: 'integrations' })
  }

  // Main.
  const { meta } = to

  if (meta && meta.permissions) {
    const { roles, permissions, redirectTo } = meta.permissions

    router.app.$authorize
      .isAuthorized(roles, permissions)
      .then(() => {
        next()
      })
      .catch(e => next(redirectTo || '/'))
  } else {
    next()
  }
})

router.beforeResolve(function (to, from, next) {
  // If matched routes doesn't exist - go to root or 404.
  if (!to.matched.length) return next('/')

  const currentWhitelabel = getWhitelabelNameFromSubdomain()
  if (['lloydsbank', 'natwest'].includes(currentWhitelabel)) {
    const validRoutes = currentWhitelabel === 'lloydsbank' ? lloydsGrantFinderRoutes : natwestGrantFinderRoutes
    if (!validRoutes.map((route) => route.name)?.includes(to.name)) return next(validRoutes[0].path)
  }

  // If matched routes doesn't exist - go to root or 404.
  return next()
})

// This tenant check needs to be moved to the backend.
function checkTenant(to) {
  // Gets the current subdomain and checks if the subdomain matches the current tenant Cookie
  // Otherwise if they are not on the routes allowed without tenant check then sign out and jump back to login page
  // for respective tenant
  const currentTenant = window.WHITE_LABEL_STYLES?.subdomain || 'swoop' // Only used to get correct tenant name from subdomainList
  const currentSubdomain = getURLSubdomain() // Returns app or hostname
  let subdomainNameFromTenantCookie = getCookie(tenantCookieName) // Returns 'swoop' or tenantName or null
  if (subdomainNameFromTenantCookie === 'swoop') {
    subdomainNameFromTenantCookie = 'app'
  }

  if (!subdomainNameFromTenantCookie || subdomainNameFromTenantCookie === currentSubdomain || subdomainList[currentTenant]?.includes(currentSubdomain)) {
    return true
  } else if (currentSubdomain !== subdomainNameFromTenantCookie) {
    if (allowedRouteNamesWithoutTenantCheck.includes(to.name)) {
      return true
    }
    store.commit(logoutRoutine.TRIGGER)
    store.dispatch(logoutRoutine.SUCCESS)

    const replaceTo = subdomainNameFromTenantCookie || 'app'
    document.location.hostname = document.location.hostname.replace(/^[^.]+/i, replaceTo)
    return false
  }
}

/*
 * Allow the user access to certain pages if they were invited by a broker or advisor
 */

function checkInvitedClientsAccess(to) {
  return [
    'stage',
    'get-started',
    'details-page',
    'aggregator-form',
    'profile',
    'data-room',
    'login',
    'sign-up',
    'sign-up-form',
    'forgot-password',
    'forgot-password-check-email',
    'product',
    'select-goal',
    'loan-matches-form',
    'equity-matches-form',
    'grant-matches-form',
    'integrations',
    'forwardai',
    'bank-integration-callback',
    'bank-integration',
    'forwardaiCallback',
    'truelayer-callback'
  ].includes(to.name)
}

export default router
