import { transitionRoutine, TRANSITION_CLEANUP } from '@/store/modules/transition/routines'

export default {
  [transitionRoutine.TRIGGER](state, payload) {
    state.transition = payload
  },
  [TRANSITION_CLEANUP](state, payload) {
    Object.assign(state, payload)
  }
}
