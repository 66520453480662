import { forwardAIIntegrationRoutine, routeNameAfterIntegrationRoutine, FORWARDAI_CLEANUP } from '@/store/modules/forwardAI/routines'
import { logoutRoutine } from '@/store/modules/auth/routines'
import { checkIntegrationStatus } from '@/api/forwardAI'
import requestFlow from '@/utils/requestFlow'
import { state as initialState } from './index'

export default {
  ...requestFlow(forwardAIIntegrationRoutine, checkIntegrationStatus).actions,

  [routeNameAfterIntegrationRoutine.TRIGGER](context, payload) {
    context.commit(routeNameAfterIntegrationRoutine.TRIGGER, payload)
  },

  [logoutRoutine.SUCCESS](context) {
    context.commit(FORWARDAI_CLEANUP, initialState)
  }
}
