import requestFlow from '@/utils/requestFlow'
import { aggregatorCompanyOpportunitiesRoutine, aggregatorOpportunityIdRoutine, OPPORTUNITY_CLEANUP } from './routines'

export default {
  ...requestFlow(aggregatorCompanyOpportunitiesRoutine).mockMutations,

  [aggregatorCompanyOpportunitiesRoutine.SUCCESS](state, payload) {
    state.companyOpportunities = payload
  },

  [aggregatorOpportunityIdRoutine.TRIGGER](state, payload) {
    state.aggregatorOpportunityId = payload
  },

  [OPPORTUNITY_CLEANUP](state, payload) {
    Object.assign(state, payload)
  }
}
