import { currentFormNameRoutine, fundingAmountRoutine, CURRENT_FORM_NAME_CLEANUP, FUNDING_AMOUNT_CLEANUP } from '@/store/modules/currentFormDetails/routines'

export default {
  [currentFormNameRoutine.TRIGGER](state, payload) {
    state.currentFormName = payload
  },
  [fundingAmountRoutine.TRIGGER](state, payload) {
    state.fundingAmount = payload
  },
  [CURRENT_FORM_NAME_CLEANUP](state, payload) {
    Object.assign(state, payload)
  },
  [FUNDING_AMOUNT_CLEANUP](state, payload) {
    Object.assign(state, payload)
  }
}
