import { opportunityRoutine, OPPORTUNITY_CLEANUP } from './routines'
import { logoutRoutine } from '@/store/modules/auth/routines'
import { getOpportunityList } from '@product/api/opportunity'
import requestFlow from '@/utils/requestFlow'
import { state as initialState } from './index'

export default {
  ...requestFlow(opportunityRoutine, getOpportunityList).actions,

  [logoutRoutine.SUCCESS] (context) {
    context.commit(OPPORTUNITY_CLEANUP, initialState)
  }
}
