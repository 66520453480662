<template>
<div class="documents-list">
  <div class="m-b-15">
    <ul class="files-list">
      <li class="file text-left" v-for="file in filesList" :key="file.documentId">
        <div class="text">
          <div class="file-name" :title="file.name | documentNameByCategoryAndTitle(file.type, file.title)">{{ file.name | documentNameByCategoryAndTitle(file.type, file.title) }}</div>
          <div class="file-description">
            <div class="file-size" v-if="'documentSize' in file && file.documentSize !== null">{{ file.documentSize | fileSize }} - </div>
            <time :datetime="file.uploadedDate">{{ file.uploadedDate | dateFormat }}</time>
          </div>
        </div>
        <a class="delete-btn" role="button" @click.prevent="deleteHandler(file.documentId)" v-promise-btn><i class="icon material-icons">close</i></a>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
import { downloadDocument, deleteDocument } from '@/api/document'
import { downloadFile, getDocumentNameByCategoryAndTitle } from '@/utils/utils'

export default {
  name: 'DocumentsList',
  props: {
    filesList: {
      type: Array
    }
  },
  methods: {
    async deleteHandler (documentId) {
      try {
        await deleteDocument(documentId)
        this.$emit('change')
      } catch (e) {
        console.error(e)
      }
    },
    async downloadClickHandler (e, file) {
      e.stopPropagation()
      const response = await downloadDocument(file.documentId)
      downloadFile(response.data, getDocumentNameByCategoryAndTitle(file.name, file.type, file.title))
    }
  },
  filters: {
    documentNameByCategoryAndTitle () {
      return getDocumentNameByCategoryAndTitle(...arguments)
    }
  }
}
</script>

<style lang="scss" scoped>
.file-type-title {
  font-weight: 500;
}
</style>
