import { fundingAppRoutine } from '@/store/routines'
import { setFundingApplication } from '@/api/fundingApplication'
import { userRoutine } from '@/store/modules/auth/routines'
import requestFlow from '@/utils/requestFlow'

export default {
  ...requestFlow(fundingAppRoutine, setFundingApplication).actions,

  async [fundingAppRoutine.SUCCESS] (context, payload) {
    await context.dispatch(userRoutine.TRIGGER)
    context.commit(fundingAppRoutine.SUCCESS, payload)
    context.dispatch(fundingAppRoutine.FULFILL, payload)
  }
}
