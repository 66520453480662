import { SET_ACTIVE_STEP_INDEX, SET_STEP_COUNTER_LIST, RESET_STEP_COUNTER_STATE } from '@/store/modules/goalsStepCounter/routines'

export default {
  [SET_STEP_COUNTER_LIST] (context, payload) {
    context.commit(SET_STEP_COUNTER_LIST, payload)
  },

  [SET_ACTIVE_STEP_INDEX] (context, payload) {
    context.commit(SET_ACTIVE_STEP_INDEX, payload)
  },

  [RESET_STEP_COUNTER_STATE] (context) {
    context.commit(RESET_STEP_COUNTER_STATE)
  }
}
