import { swoopAssistantRoutine, SWOOP_ASSISTANT_CLEANUP } from '@/store/modules/swoopAssistant/routines'
import { logoutRoutine } from '@/store/modules/auth/routines'
import { state as initialState } from './index'

export default {
  [swoopAssistantRoutine.TRIGGER](context, payload) {
    context.commit(swoopAssistantRoutine.TRIGGER, payload)
  },
  [logoutRoutine.SUCCESS](context) {
    context.commit(SWOOP_ASSISTANT_CLEANUP, initialState)
  }
}
