import { sortByAmountRoutine, sortByInterestRateRoutine, sortByDecisionTimeRoutine, sortByProductTypeRoutine, SORT_BAR_CLEANUP } from './routines'

export default {
  [sortByAmountRoutine.TRIGGER](state, payload) {
    state.sortByAmount = payload
  },
  [sortByInterestRateRoutine.TRIGGER](state, payload) {
    state.sortByInterestRate = payload
  },
  [sortByDecisionTimeRoutine.TRIGGER](state, payload) {
    state.sortByDecisionTime = payload
  },
  [sortByProductTypeRoutine.TRIGGER](state, payload) {
    state.sortByProductType = payload
  },
  [SORT_BAR_CLEANUP](state, payload) {
    Object.assign(state, payload)
  }

}
