// IMPORTANT: import in alphabetical order to make better readability
import 'vue-awesome/icons/angle-down'
import 'vue-awesome/icons/angle-right'
import 'vue-awesome/icons/bars'
import 'vue-awesome/icons/bell'
import 'vue-awesome/icons/bell-o'
import 'vue-awesome/icons/chevron-down'
import 'vue-awesome/icons/chevron-up'
import 'vue-awesome/icons/circle'
import 'vue-awesome/icons/circle-thin'
import 'vue-awesome/icons/cog'
import 'vue-awesome/icons/envelope'
import 'vue-awesome/icons/envelope-o'
import 'vue-awesome/icons/facebook-official'
import 'vue-awesome/icons/times'
import 'vue-awesome/icons/linkedin'
import 'vue-awesome/icons/lock'
import 'vue-awesome/icons/long-arrow-down'
import 'vue-awesome/icons/long-arrow-up'
import 'vue-awesome/icons/long-arrow-left'
import 'vue-awesome/icons/map-marker'
import 'vue-awesome/icons/minus'
import 'vue-awesome/icons/plus'
import 'vue-awesome/icons/user-o'
import 'vue-awesome/icons/square'
import 'vue-awesome/icons/circle-o-notch'
import 'vue-awesome/icons/shield'
import 'vue-awesome/icons/twitter'
import 'vue-awesome/icons/youtube-play'
import 'vue-awesome/icons/question-circle'

import Icon from 'vue-awesome/components/Icon'

export default Icon
