export default {
  path: 'personal-info',
  name: 'journey-personal-info',
  component: () => import(/* webpackChunkName: "personal-info" */ './personalInfo.vue'),
  meta: {
    permissions: {
      roles: ['user'],
      redirectTo: {
        name: 'login'
      }
    }
  }
}
