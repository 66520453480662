import { cashFlowRoutine, CASH_FLOW_DETAILS_CLEANUP } from './routines'
import requestFlow from '@/utils/requestFlow'
import { getShortTermCashflow } from '@/api/forwardAI'
import { logoutRoutine } from '@/store/modules/auth/routines'
import { state as initialState } from './index'

export default {
  ...requestFlow(cashFlowRoutine, getShortTermCashflow).actions,

  [logoutRoutine.SUCCESS] (context) {
    context.commit(CASH_FLOW_DETAILS_CLEANUP, initialState)
  }
}
