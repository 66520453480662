import createRoutine from '@/utils/createRoutine'

export const aggregatorCompanyOpportunitiesRoutine = createRoutine('aggregatorCompanyOpportunitiesRoutine')
export const aggregatorOpportunityIdRoutine = createRoutine('aggregatorOpportunityIdRoutine')

export const aggregatorGetters = {
  COMPANY_OPPORTUNITIES: 'COMPANY_OPPORTUNITIES',
  AGGREGATOR_OPPORTUNITY_ID: 'AGGREGATOR_OPPORTUNITY_ID'
}
export const OPPORTUNITY_CLEANUP = 'OPPORTUNITY_CLEANUP'
