import requestFlow from '@/utils/requestFlow'
import { companyRoutine, companyStateRoutine, companySelectRoutine, COMPANY_CLEANUP } from '@/store/modules/company/routines'

export default {
  ...requestFlow(companyRoutine).mockMutations,

  ...requestFlow(companyStateRoutine).mockMutations,
  [companyStateRoutine.SUCCESS] (state, payload) {
    state.companyState = payload
  },

  [companySelectRoutine.SUCCESS] (state, payload) {
    state.currentCompanyId = payload
  },

  [COMPANY_CLEANUP] (state, payload) {
    Object.assign(state, payload)
  }
}
