import router from '@/router'
import { allowOnlyWithCompletedJourney } from '@/router/routerGuards'

export default () => {
  router.addRoutes([
    {
      path: '/application-form/:step?',
      name: 'application-form',
      component: () => import(/* webpackChunkName: "application-form" */ './applicationForm.vue'),
      meta: {
        permissions: {
          roles: ['user'],
          redirectTo: {
            name: 'login'
          }
        }
      },
      beforeEnter: allowOnlyWithCompletedJourney()
    }
  ])
}
