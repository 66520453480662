import { bankMandateListRoutine, BANK_CLEANUP } from '@/store/modules/bank/routines'
import { logoutRoutine } from '@/store/modules/auth/routines'
import { getBankMandateList } from '@/api/bankMandate'
import requestFlow from '@/utils/requestFlow'
import { state as initialState } from './index'

export default {
  ...requestFlow(bankMandateListRoutine, getBankMandateList).actions,

  [logoutRoutine.SUCCESS] (context) {
    context.commit(BANK_CLEANUP, initialState)
  }
}
