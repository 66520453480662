<template>
  <overviewIcon v-if="icon === 'overview'" />
  <matchesIcon v-else-if="icon === 'matches'" />
  <fxIcon v-else-if="icon === 'fx'" />
  <bankingIcon v-else-if="icon === 'banking'" />
  <utilitiesIcon v-else-if="icon === 'utilities'" />
  <insuranceIcon v-else-if="icon === 'insurance'" />
  <integrationsIcon v-else-if="icon === 'integrations'" />
  <clientsIcon v-else-if="icon === 'clients'" />
  <creditScoreIcon v-else-if="icon === 'credit_score'" />
  <goTo v-else-if="icon === 'goTo'" />
</template>

<script>
import overviewIcon from '@/assets/images/icons/icons_overview.svg?inline'
import matchesIcon from '@/assets/images/icons/icons_matches.svg?inline'
import bankingIcon from '@/assets/images/icons/icons_banking.svg?inline'
import utilitiesIcon from '@/assets/images/icons/icons_utilities.svg?inline'
import fxIcon from '@/assets/images/icons/icons_fx.svg?inline'
import insuranceIcon from '@/assets/images/icons/icons_insurance.svg?inline'
import integrationsIcon from '@/assets/images/icons/icons_integrations.svg?inline'
import clientsIcon from '@/assets/images/icons/icons_clients.svg?inline'
import creditScoreIcon from '@/assets/images/icons/icons_credit_score.svg?inline'
import goTo from '@/assets/images/icons/icons_go_to.svg?inline'

export default {
  components: {
    overviewIcon,
    matchesIcon,
    bankingIcon,
    utilitiesIcon,
    fxIcon,
    insuranceIcon,
    integrationsIcon,
    clientsIcon,
    creditScoreIcon,
    goTo
  },
  props: {
    icon: {
      type: String,
      required: true
    }
  }
}
</script>
