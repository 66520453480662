export default {
  path: 'banking',
  name: 'savings-banking',
  component: () => import(/* webpackChunkName: "savings-banking" */ './banking.vue'),
  meta: {
    permissions: {
      roles: ['user'],
      redirectTo: {
        name: 'login'
      }
    }
  }
}
