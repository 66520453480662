import createRoutine from '@/utils/createRoutine'

export const stepCounterRoutine = createRoutine('stepCounterRoutine')

export const stepCounterGetters = {
  STEP_COUNTER_LIST: 'STEP_COUNTER_LIST',
  ACTIVE_STEP_INDEX: 'ACTIVE_STEP_INDEX'
}

export const SET_STEP_COUNTER_LIST = 'SET_STEP_COUNTER_LIST'
export const SET_ACTIVE_STEP_INDEX = 'SET_ACTIVE_STEP_INDEX'
export const RESET_STEP_COUNTER_STATE = 'RESET_STEP_COUNTER_STATE'
