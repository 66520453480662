// DEPRECATED! Please, do not use this component going forward. This should be replaced with the same component inside /dynamicForms folder.
<template>
  <span class="text-danger" v-if="errors.length">{{ errors[0] }}</span>
</template>

<script>
export default {
  name: 'FieldError',
  props: {
    errors: {
      type: Array,
      required: true
    }
  }
}
</script>
