import { leftHandSideBar, rightHandSideBar } from '@/store/modules/navigationBars/routines'

export default {
  [leftHandSideBar.TRIGGER](context, payload) {
    context.commit(leftHandSideBar.TRIGGER, payload)
  },
  [rightHandSideBar.TRIGGER](context, payload) {
    context.commit(rightHandSideBar.TRIGGER, payload)
  }
}
