import requestFlow from '@/utils/requestFlow'
import { forwardAIIntegrationRoutine, routeNameAfterIntegrationRoutine, FORWARDAI_CLEANUP } from '@/store/modules/forwardAI/routines'

export default {
  ...requestFlow(forwardAIIntegrationRoutine).mockMutations,

  [forwardAIIntegrationRoutine.FAILURE](state, payload) {
    state.forwardAIError = payload || null
  },

  [forwardAIIntegrationRoutine.SUCCESS](state, payload) {
    state.forwardAIIntegration = payload || null
  },

  [routeNameAfterIntegrationRoutine.TRIGGER](state, payload) {
    state.routeNameAfterIntegration = payload
  },

  [FORWARDAI_CLEANUP](state, payload) {
    Object.assign(state, payload)
  }
}
