import createRoutine from '@/utils/createRoutine'

export const formMetaDataRoutine = createRoutine('formMetaDataRoutine')
export const updateFormFileToUploadRoutine = createRoutine('updateFormFileToUploadRoutine')
export const updateFormFileToDeleteRoutine = createRoutine('updateFormFileToDeleteRoutine')

export const formMetaDataGetters = {
  FORM_META_DATA: 'FORM_META_DATA',
  FORM_FILE_TO_UPLOAD: 'FORM_FILE_TO_UPLOAD',
  FORM_FILE_TO_DELETE: 'FORM_FILE_TO_DELETE'
}

export const FORM_META_DATA_CLEANUP = 'FORM_META_DATA_CLEANUP'
