import { tenantGetters } from '@/store/modules/tenant/routines'

export default {
  [tenantGetters.TENANT_NAME]: () => {
    return process.env.VUE_APP_TENANT
  },
  [tenantGetters.TENANT_SUBDOMAIN_NAME]: () => {
    const isDevelopment = process.env.NODE_ENV !== 'production'
    if (isDevelopment) return window.WHITE_LABEL_STYLES.subdomain
    const subDomainName = document.location.hostname.slice(0, document.location.hostname.indexOf('.'))
    return subDomainName || 'app'
  },
  [tenantGetters.TENANT_DICTIONARY]: state => state.tenantDictionary
}
