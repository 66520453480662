import Vue from 'vue'
import axios from 'axios'
import axiosRetry from 'axios-retry'
import { addRequest, pendingRequests } from '@swoop-ltd/api-request-manager'
import store from '@/store'
import { authGetters, logoutLocalRoutine } from '@/store/modules/auth/routines'
import { notificationRoutine } from '@/store/modules/notification/routines'
import { toastTopCenterOptions } from '@/config/vue-toast'
import { defaultGroupId } from '@/utils/constants'
import qs from 'qs'
import siteConfig from '@/config/global'

export function createAxiosInstance(baseURL) {
  const axiosInstance = axios.create({
    baseURL,
    paramsSerializer: params => qs.stringify(params, { encode: false })
  })

  axiosRetry(axiosInstance, {
    retryDelay: axiosRetry.exponentialDelay,
    shouldResetTimeout: true
  })
  axiosInstance.interceptors.response.use(
    response => {
      const { config } = response
      if (config && config.data && config.data.requestId) {
        delete pendingRequests[config.data.requestId]
        delete config.data.requestId
      }
      return response
    },
    error => {
      if (Vue.prototype.$apm && error instanceof Error) {
        Vue.prototype.$apm.captureError(error)
      } else if (Vue.prototype.$apm && error.response) {
        Vue.prototype.$apm.captureError({
          message: error.message,
          response: error.response
        })
      }
      if (error.response) {
        if (error.response.status === 401 && store.getters[authGetters.GROUP_ID] !== defaultGroupId) {
          store.dispatch(logoutLocalRoutine.TRIGGER)
          store.dispatch(notificationRoutine.TRIGGER, {
            group: 'auth',
            type: 'error',
            title: 'Logout',
            text: 'GroupId mismatch, please login through the correct whitelabel.'
          })
          return Promise.reject(error)
        }

        switch (error.response.status) {
          case 400:
            if (window.location.pathname.split('/')[1] === 'login') {
              store.dispatch(notificationRoutine.TRIGGER, {
                title: 'API Error',
                options: { ...toastTopCenterOptions, className: 'toasted-error' },
                text: 'Your Email or Password is incorrect'
              })
            } else if (window.location.pathname.split('/')[1] !== 'get-started') {
              store.dispatch(notificationRoutine.TRIGGER, {
                title: 'API Error',
                options: { ...toastTopCenterOptions, className: 'toasted-error' },
                text: error.response.data?.message ? error.response.data.message : 'There was an error processing your request'
              })
            }

            break
          case 401:
            store.dispatch(logoutLocalRoutine.TRIGGER)
            store.dispatch(notificationRoutine.TRIGGER, {
              group: 'auth',
              type: 'error',
              title: 'Logout',
              text: 'For security reasons, you have been logged out.'
            })

            break
          case 403:
            store.dispatch(notificationRoutine.TRIGGER, {
              title: 'API Error',
              options: { ...toastTopCenterOptions, className: 'toasted-error' },
              text: 'We encountered an error: Access forbidden'
            })

            break
          case 404:
            store.dispatch(notificationRoutine.TRIGGER, {
              title: 'API Error',
              options: { ...toastTopCenterOptions, className: 'toasted-error' },
              text: 'There was an error processing your request'
            })

            break
          case 408:
            store.dispatch(notificationRoutine.TRIGGER, {
              title: 'API Error',
              options: { ...toastTopCenterOptions, className: 'toasted-error' },
              text: 'We encountered an error: The server took to long to respond'
            })

            break
          case 500:
            store.dispatch(notificationRoutine.TRIGGER, {
              title: 'API Error',
              options: { ...toastTopCenterOptions, className: 'toasted-error' },
              text: 'There was an internal server error'
            })

            break
          case 501:
            store.dispatch(notificationRoutine.TRIGGER, {
              title: 'API Error',
              options: { ...toastTopCenterOptions, className: 'toasted-error' },
              text: 'The requested method is not yet supported by our server'
            })

            break
          default:
            if (process.env.NODE_ENV !== 'production') {
              store.dispatch(notificationRoutine.TRIGGER, {
                type: 'error',
                title: 'API Error',
                text: `Status code: ${error.response.status}\n${(error.response.data && error.response.data.message) || 'Unknown.\nNo Response Data.'}`
              })
            }
        }
      } else {
        store.dispatch(notificationRoutine.TRIGGER, {
          title: 'Request Error',
          options: { ...toastTopCenterOptions, className: 'toasted-error' },
          text: error.message || 'Something went wrong, please try again later.'
        })
        console.log('Unknown Error', error)
      }
      return Promise.reject(error)
    }
  )

  axiosInstance.interceptors.request.use(config => {
    const token = store.getters[authGetters.FULL_TOKEN]
    if (token) {
      config.headers.Authorization = token
    }

    const connectingApplicationId = window.WHITE_LABEL_STYLES?.subdomain || 'swoop'

    // set the default headers for each request.
    config.headers['X-Tenant'] = connectingApplicationId
    config.headers['X-Group-Id'] = store.getters[authGetters.GROUP_ID] // default: '77afb219-9221-4132-8667-36fe60ec23a3'
    config.headers['X-Country'] = siteConfig.whitelabel.country.defaultCountry
    config.headers['Content-Type'] = ['application/json']

    if (config.data?.requestId) {
      const source = axios.CancelToken.source()
      config.cancelToken = source.token
      addRequest(config.data.requestId, source.cancel)
    }
    return config
  })

  return axiosInstance
}

export const swoopAPI = createAxiosInstance(process.env.VUE_APP_BASE_API_URL)

export const swoopCompaniesApi = createAxiosInstance(process.env.VUE_APP_BASE_COMPANIES_API_URL)

export const swoopAggregatorApi = createAxiosInstance(process.env.VUE_APP_BASE_AGGREGATOR_API_URL)

export const swoopApiUrl = createAxiosInstance(process.env.VUE_APP_API_URL)

export const swoopIllionApiUrl = createAxiosInstance(process.env.VUE_APP_API_BASE_ILLION_URL)

export const accountingApi = createAxiosInstance(process.env.VUE_APP_ACCOUNTING_API_URL)

export const formFieldsMetaData = createAxiosInstance(process.env.VUE_APP_FORM_FIELDS_METADATA_URL)

export const recommendationApi = createAxiosInstance(process.env.VUE_APP_RECOMMENDATION_API_URL)

export default axios
