import { companyGetters } from '@/store/modules/company/routines'

export default {
  [companyGetters.COMPANIES_LIST]: (state, getters, rootState) => (rootState.auth.user && rootState.auth.user.companies ? rootState.auth.user.companies : []),
  [companyGetters.COMPANY_ID]: (state, getters, rootState) =>
    state.currentCompanyId || (rootState.auth.user && rootState.auth.user.companies && rootState.auth.user.companies[0] && rootState.auth.user.companies[0].companyId) || null,
  [companyGetters.COMPANY_STATE]: state => state.companyState,
  [companyGetters.COMPANY_CURRENT_ID]: state => state.currentCompanyId,
  [companyGetters.COMPANY_CURRENT_LAST_YEAR_TURNOVER]: (state, getters, rootState) => {
    const companyId = getters[companyGetters.COMPANY_ID]
    const currentCompany = rootState.auth.user.companies.find(company => company.companyId === companyId)
    return currentCompany.turnoverLastYear
  },
  [companyGetters.COMPANY_CURRENT]: (state, getters, rootState) => {
    const companyId = getters[companyGetters.COMPANY_ID]
    return companyId && rootState.auth.user && rootState.auth.user.companies ? rootState.auth.user.companies.find(company => company.companyId === companyId) : null
  },
  [companyGetters.COMPANY_OWN]: (state, getters, rootState) => {
    const companies = getters[companyGetters.COMPANIES_LIST]
    if (companies.length === 0) return null
    return companies.reduce((prev, curr) => (Date.parse(prev.assignedDate) < Date.parse(curr.assignedDate) ? prev : curr))
  }
}
