import { formatToCurrency } from '@/utils/currency'

// Currency directive helps change the format of input number to currency.
export default function (element) {
  if (!element.tagName) return

  // Check if the element using this directive is not an input. If not an input element it throws an error.
  if (element.tagName.toLocaleUpperCase() !== 'INPUT') {
    const els = element.getElementsByTagName('input')

    if (els.length !== 1) {
      throw new Error('v-currency directive needs to be used in an input element.')
    } else {
      element = els[0]
    }
  }

  // Acts when something is added into the input element.
  element.oninput = function () {
    let positionFromEnd = element.value.length - element.selectionEnd

    element.value = formatToCurrency(element.value)

    // Gets the correct cursor position on input element.
    positionFromEnd = Math.max(positionFromEnd, 0)
    positionFromEnd = element.value.length - positionFromEnd
    positionFromEnd = Math.max(positionFromEnd, 1)

    // Sets the correct cursors position on input element.
    setCursor(element, positionFromEnd)

    // Dispatches change event for the current input.
    element.dispatchEvent(event('change'))
  }

  element.oninput()

  // Forces the format after the initialization.
  element.dispatchEvent(event('input'))
}

// Creates a JavaScript event.
function event(name) {
  const evt = document.createEvent('Event')

  evt.initEvent(name, true, true)

  return evt
}

// Sets the correct cursor position.
function setCursor(el, position) {
  const setSelectionRange = function () {
    el.setSelectionRange(position, position)
  }

  if (el === document.activeElement) {
    setSelectionRange()

    setTimeout(setSelectionRange, 1)
  }
}
