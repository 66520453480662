import router from '@/router'
import servicesRoute from './services/services.routes'
import bankingRoute from './banking/banking.routes'
import staticBankingRoute from './static-banking/static-banking.routes'
import { AppSidebar, AppHeader } from '@/components'

export default () => {
  router.addRoutes([
    {
      path: '/savings',
      name: 'savings',
      redirect: { name: 'savings-services' },
      components: {
        default: () => import(/* webpackChunkName: "savings" */ './savings.vue'),
        sidebar: AppSidebar,
        header: AppHeader
      },
      children: [servicesRoute, bankingRoute, staticBankingRoute]
    },
    {
      path: '/savings/services/foreign-exchange',
      name: 'foreign-exchange',
      component: () => import(/* webpackChunkName: "savings-foreign-exchange" */ './services/foreignExchange/foreignExchange.vue'),
      meta: {
        permissions: {
          roles: ['user'],
          redirectTo: {
            name: 'login'
          }
        }
      }
    },
    {
      path: '/savings/services/utilities',
      name: 'utilities',
      components: {
        default: () => import(/* webpackChunkName: "savings-utilities" */ './services/utilities/utilities.vue'),
        sidebar: AppSidebar,
        header: AppHeader
      },
      meta: {
        permissions: {
          roles: ['user'],
          redirectTo: {
            name: 'login'
          }
        }
      }
    },
    {
      path: '/savings/services/overdraft-fees',
      name: 'overdraft-fees',
      component: () => import(/* webpackChunkName: "savings-overdraft-fees" */ './services/overdraftFees/overdraftFees.vue'),
      meta: {
        permissions: {
          roles: ['user'],
          redirectTo: {
            name: 'login'
          }
        }
      }
    }
  ])
}
