<template>
  <span>
    <slot v-bind="{ text }">{{ text }}</slot>
  </span>
</template>

<script>
import defaultDictionary from '@/config/dictionary'

export default {
  name: 'TenantText',
  props: {
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    text() {
      return defaultDictionary[this.name] || ''
    }
  }
}
</script>
