import { swoopAPI } from '@/api'

export const findCompanies = data => swoopAPI.get('/api/v1/company/search', { params: data })
export const getCompany = companyId => swoopAPI.get(`/api/v1/company/${companyId}`)
export const getCompanyEntityTypeCode = companyRegistrationNumber => swoopAPI.get(`/api/v1/company/${companyRegistrationNumber}/entityTypeCode`)

// Response
// `CompanyState` [NotFilled, DueDilOnly, Completed]
// `FundingApplicationState` [NotFilled, AmountSet, Completed]
// `FinancialState` [NotFilled, Completed]
export const getCompanyState = companyId => swoopAPI.get(`/api/v1/company/${companyId}/registrationState`)

// data: {companyRegistrationNumber: string}
export const confirmCompany = data => swoopAPI.post('/api/v1/company/confirm', data)
export const getCompanyOfficers = companyId => swoopAPI.get(`/api/v1/company/${companyId}/officers`)
export const patchCompany = data => swoopAPI.patch('/api/v1/company', data)
export const putCompany = data => swoopAPI.put('/api/v1/company', data)
export const patchCurrentlyBanking = data => swoopAPI.patch('/api/v1/company/currentlyBank', data)
export const patchCompanyFromJourney = data => swoopAPI.patch('/api/v1/company/fromJourney', data)
export const patchCompanyForSavings = data => swoopAPI.patch('/api/v1/company/forSavings', data)
export const postCompanyCommon = data => swoopAPI.post('/api/v1/company/common', data)
export const patchCompanyCommon = data => swoopAPI.patch('/api/v1/company/common', data)
export const createCompany = data => swoopAPI.post('/api/v1/company', data)
export const patchCompanyOpenBanking = data => swoopAPI.patch('/api/v1/company/openBanking', data)
export const patchCompanyInfo = data => swoopAPI.patch('/api/v1/company/info', data)
export const applyCompanyForCostSavings = data => swoopAPI.patch('/api/v1/company/applyForCostSavings', data)
export const getCompanyUtilitiesStatus = companyId => swoopAPI.get(`/api/v1/company/${companyId}/utilities`)
export const submitCompanyUtilities = companyId => swoopAPI.patch(`/api/v1/company/${companyId}/utilities`)
export const makeInterestedInCostSavings = data => swoopAPI.patch('/api/v1/company/makeInterestedInCostSavings', data)
export const getCompanyOwners = companyId => swoopAPI.get('/api/v1/company/owners', { params: { companyId } })
export const patchCompanyOwners = data => swoopAPI.patch('/api/v1/company/owners', data)
export const postCompanyRequestSavingsCallback = data => swoopAPI.post('/api/v1/company/request/callback', data)
