import requestFlow from '@/utils/requestFlow'
import { updateFormFileToUploadRoutine, formMetaDataRoutine, updateFormFileToDeleteRoutine, FORM_META_DATA_CLEANUP } from '@/store/modules/formMetaData/routines'

export default {
  ...requestFlow(formMetaDataRoutine).mockMutations,
  [formMetaDataRoutine.SUCCESS] (state, payload) {
    state.formMetaData = payload
  },

  [updateFormFileToUploadRoutine.TRIGGER] (state, payload) {
    state.formFileToUpload = payload
  },

  [updateFormFileToDeleteRoutine.TRIGGER] (state, payload) {
    state.formFileToDelete = payload
  },

  [FORM_META_DATA_CLEANUP] (state, payload) {
    Object.assign(state, payload)
  }
}
