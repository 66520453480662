import { allowOnlyGuests } from '@/router/routerGuards'

import router from '@/router'

// Setup routes
export default () => {
  router.addRoutes([
    {
      path: '/login',
      component: () => import(/* webpackChunkName: "onboarding-root" */ '../../components/OnboardingWrapper.vue'),
      children: [
        {
          path: '',
          name: 'login',
          components: {
            default: () => import(/* webpackChunkName: "sign-in" */ './signIn.vue'),
            geometricBackground: () => import(/* webpackChunkName: "geometricBackground" */ '../../components/GeometricBackground.vue')
          },
          beforeEnter: allowOnlyGuests()
        }
      ]
    }
  ])
}
