<template>
  <div v-if="($config.whitelabel.components.appHeaderEnabled || isLoggedIn)">
    <header class="app-header" :class="[{ 'blur-background': mobileLeftBar || rightBar }, isOnboardingRoute || !isLoggedIn ? 'onboarding' : '']">
      <span v-if="isLoggedIn && !isOnboardingRoute" class="material-icons mobile-only menu-icon" @click="toggleLeftBar()">
        menu
      </span>
      <div class="logo-wrapper">
        <SwoopLogo
          @click="go({ name: 'dashboard' })"
          height="36px"
          v-if="!isInvitedUser && $config.whitelabel.components.appHeaderLogoEnabled"
          :logoType="isOnboardingRoute ? 'signup' : 'default'"
        />
        <h1 v-if="isInvitedUser">{{ userGroupName }}</h1>
      </div>
      <div class="dropdown-menu desktop-only" @click="handleSideBarStatus(true)" v-if="dropdownEnabled && isLoggedIn">
        <p class="welcome-company-message">Hi, {{ companyName }}</p>
        <div class="company-icon navbar">
          {{ companyInitials }}
        </div>
      </div>

      <div v-if="isLoggedIn && isOnboardingRoute" class="dropdown-menu mobile-only">
        <div class="company-icon navbar mobile-only" @click="handleSideBarStatus(true)">{{ companyInitials }}</div>
      </div>

      <div v-if="isLoggedIn && !isOnboardingRoute" class="dropdown-menu mobile-only">
        <div class="company-icon navbar mobile-only" @click="toggleMobileRightBar()">{{ companyInitials }}</div>
      </div>
    </header>

    <div v-if="sidebarOpen" class="overlay" @click="handleSideBarStatus(false)" />
    <aside class="sidebar" :class="{ active: sidebarOpen }">
      <div class="sidebar-content">
        <span class="material-icons close" @click="handleSideBarStatus(false)"> close </span>

        <div class="company-profile">
          <div class="company-icon" :class="'sme-theme'">
            {{ companyInitials }}
          </div>
          <h1>{{ companyName }}</h1>
          <span>{{ user && user.email }}</span>
          <span>{{ user && user.contactNumber }}</span>
        </div>

        <div class="nav-items">
          <div v-if="!isInvitedUser">
            <div class="item" @click="go({ name: 'data-room', params: { step: 'financials' } })">Data Room</div>
            <div class="item" @click="go({ name: 'integrations' })">Integrations</div>
            <div class="item" @click="go({ name: 'profile' })">My Profile</div>
          </div>

          <div class="item logout" role="button" @click="logout">
            <span class="material-icons logoff"> power_settings_new </span>
            Logout
          </div>
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { navigationBarsGetters, leftHandSideBar, rightHandSideBar } from '@/store/modules/navigationBars/routines'
import { authGetters, logoutRoutine } from '@/store/modules/auth/routines'
import { onboardingRoutes } from '@/router/routerGuards'
import SwoopLogo from '@/components/SwoopLogo'

export default {
  name: 'AppHeader',
  components: { SwoopLogo },
  props: {
    dropdownEnabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      sidebarOpen: false,
      isOnboardingRoute: false
    }
  },
  computed: {
    ...mapGetters({
      user: authGetters.USER,
      isLoggedIn: authGetters.IS_AUTHENTICATED,
      isInvitedUser: authGetters.IS_INVITED_USER,
      userGroupName: authGetters.GROUP_NAME,
      mobileLeftBar: navigationBarsGetters.LEFT_HAND_SIDE_BAR,
      rightBar: navigationBarsGetters.RIGHT_HAND_SIDE_BAR
    }),
    companyName() {
      return this.user?.companies[this.user.companies.length - 1]?.companyName || ''
    },
    companyInitials() {
      // Splitting each word of the company name.
      const splittedNames = this.companyName.split(' ') || []

      // Getting the first letter of the first two words only and transforming it into uppercase letter before adding it to the initials list.
      const initialsList = splittedNames.slice(0, 2).map(name => {
        return name.charAt(0).toUpperCase()
      })
      // Getting each letter of the initials list and concatenate into a single string.
      return initialsList.join('')
    }
  },
  methods: {
    ...mapActions({
      triggerLogout: logoutRoutine.TRIGGER,
      handleLeftBarStatus: leftHandSideBar.TRIGGER,
      handleRightBarStatus: rightHandSideBar.TRIGGER
    }),
    trackClickEvent(item) {
      this.$ma.trackEvent({
        eventType: 'Navbar clicked',
        eventProperties: {
          item
        }
      })
    },
    handleSideBarStatus(status) {
      this.sidebarOpen = status
      this.handleRightBarStatus(status)
    },
    go(to) {
      this.trackClickEvent(to.name.replace('-', ' '))
      this.$router
        .push(to)
        .then(() => {
          this.handleSideBarStatus(false)
        })
        .catch(() => {
          this.handleSideBarStatus(false)
        })
    },
    toggleLeftBar() {
      this.$emit('toggle-mobile-sidebar')
      this.handleLeftBarStatus(!this.mobileLeftBar)
    },
    toggleMobileRightBar() {
      this.$emit('toggle-mobile-sidebar')
    },
    logout() {
      // Reseting bars status.
      this.handleLeftBarStatus(false)
      this.handleRightBarStatus(false)

      this.triggerLogout()
      this.trackClickEvent('logout')
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        if (route.matched.length) {
          this.isOnboardingRoute = onboardingRoutes.includes(route.matched[0].name)
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/swoop/variables.scss';

header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: fixed;
  padding: 12px;
  border-bottom: 1px solid var(--color-secondary-50);
  align-items: center;
  background-color: $color-white;
  z-index: 100;

  &.onboarding {
    background-color: $color-onboarding-header;
    color: $color-onboarding-header-text;
  }

  .logo-wrapper {
    display: flex;
    margin-left: 10px;
    min-height: 36px;

    .swoop-logo {
      margin: 0;
    }

    &:hover {
      cursor: pointer;
    }

    h1 {
      margin-bottom: 0;
      font-weight: 600;
      color: $color-black;
    }
  }

  .dropdown-menu {
    display: flex;
    align-items: center;
    cursor: pointer;

    p.welcome-company-message {
      display: flex;
      align-items: center;
      margin: 0px;
      font-size: 14px;
    }
  }

  .company-icon {
    width: 36px;
    height: 36px;
    border: 2px solid;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
    margin-right: 3px;
    font-weight: 500;
  }

  .menu-icon {
    display: none;

    &:hover {
      cursor: pointer;
    }
  }

  .dropdown-menu.mobile-only {
    display: none;
  }

  @media only screen and (min-width: $breakpoint-xxs) {
    .logo-wrapper .swoop-logo {
      width: 160px;
    }
  }

  @media only screen and (min-width: $breakpoint-sm) {
    .logo-wrapper .swoop-logo {
      width: 245px;
    }
  }

  @media only screen and (max-width: $breakpoint-sm-max) {
    .dropdown-menu.desktop-only {
      display: none;
    }

    .menu-icon.mobile-only {
      display: block;
    }

    .dropdown-menu.mobile-only {
      display: flex;

      & .company-icon {
        margin: 0;
      }
    }
  }
}

.overlay {
  position: fixed;
  z-index: 100;
  height: 100vh;
  width: -webkit-fill-available;
  top: 0;
  right: 0;
  background-color: var(--color-primary-500);
  opacity: 0.8;
}

aside {
  height: 100vh;
  width: 0;
  max-width: calc(100vw - 50px);
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  background-color: $color-white;
  transition: all 0.1s linear;

  & > * {
    display: none;
  }

  .sidebar-content {
    width: 100vw;
    height: 100vh;
    padding-top: 16px;

    span.close {
      padding-left: 16px;
      cursor: pointer;
      color: var(--color-primary-300);
      font-size: 24px;
      display: block;
    }

    .company-profile {
      height: 200px;
      width: 370px;
      max-width: calc(100vw - 50px);
      display: flex;
      flex-direction: column;
      position: fixed;
      align-items: center;
      padding: 16px;
      text-align: center;

      .company-icon {
        width: 64px;
        height: 64px;
        border: 2px solid;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 12px;
        margin-right: 3px;
        font-size: 28px;
        font-weight: 500;
      }

      h1 {
        margin: 12px 0;
        margin-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
        word-wrap: break-word;
        text-align: center;
        font-weight: bold;
        font-size: 22px;
      }

      p {
        margin: 0;
      }
    }

    .nav-items {
      margin-top: 250px;

      .item {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: var(--color-primary-300);
        padding: 16px 16px;
        border-top: 1px solid var(--color-secondary-50);

        &:hover {
          background-color: var(--color-secondary-50);
          cursor: pointer;
        }

        &:last-child {
          border-bottom: 1px solid var(--color-secondary-50);
        }

        img {
          margin-right: 20px;
        }

        .material-icons {
          margin-right: 12px;
        }
      }

      .logout {
        border-top: 1px solid var(--color-secondary-50);
        border-bottom: 1px solid var(--color-secondary-50);
        width: 100vw;
        position: absolute;
        bottom: 0;
        left: 0;

        .logoff {
          color: var(--color-error-500);
        }
      }
    }
  }

  &.active {
    width: 370px;
    overflow-x: hidden;

    & > * {
      display: block;
    }
  }

  @media only screen and (max-width: $breakpoint-sm-max) {
    .sidebar-content span.close {
      display: none;
    }
  }

  @media only screen and (max-width: $breakpoint-xxs) {
    .sidebar-content .company-profile {
      font-size: 12px;

      & h1 {
        font-size: 14px;
      }
    }
  }
}

.sme-theme {
  .company-icon {
    background-color: $color-navbar-company-icon-background;
    border-color: $color-navbar-company-icon-background;
    color: white;
  }

  .logo-wrapper .swoop-logo {
    width: 115px;
  }
}
</style>
