import whitelabelConfig from '@/config/whitelabel'

export default {
  path: 'services',
  name: 'savings-services',
  component: () => import(/* webpackChunkName: "savings-services" */ './services.vue'),
  meta: {
    permissions: {
      roles: ['user'],
      redirectTo: {
        name: 'login'
      }
    }
  },
  beforeEnter: () => {
    // This route is not being used anymore. Redirecting the user to marketing website.
    window.location.href = whitelabelConfig.links.potentialSavingsLink
  }
}
