import createRoutine from '@/utils/createRoutine'

export const saveStageGoal = createRoutine('saveStageGoal')
export const saveGetStartedFormData = createRoutine('saveGetStartedFormData')

export const onboardingGetters = {
  STAGE_GOAL: 'STAGE_GOAL',
  GET_STARTED_FORM_DATA: 'GET_STARTED_FORM_DATA'
}

export const CLEAR_GET_STARTED_FORM_DATA = 'CLEAR_GET_STARTED_FORM_DATA'
