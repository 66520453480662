import { notificationMessagesRoutine, NOTIFICATION_MESSAGES_CLEANUP } from '@/store/modules/notificationMessages/routines'

export default {
  [notificationMessagesRoutine.TRIGGER] (state, payload) {
    state.hasNewNotificationMessages = payload
  },
  [NOTIFICATION_MESSAGES_CLEANUP] (state, payload) {
    Object.assign(state, payload)
  }
}
