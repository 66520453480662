import createRoutine from '@/utils/createRoutine'

export const sortByAmountRoutine = createRoutine('sortByAmountRoutine')
export const sortByInterestRateRoutine = createRoutine('sortByInterestRateRoutine')
export const sortByDecisionTimeRoutine = createRoutine('sortByDecisionTimeRoutine')
export const sortByProductTypeRoutine = createRoutine('sortByProductTypeRoutine')

export const sortBarGetters = {
  SORT_BY_AMOUNT: 'SORT_BY_AMOUNT',
  SORT_BY_INTEREST_RATE: 'SORT_BY_INTEREST_RATE',
  SORT_BY_DECISION_TIME: 'SORT_BY_DECISION_TIME',
  SORT_BY_PRODUCT_TYPE: 'SORT_BY_PRODUCT_TYPE'
}

export const SORT_BAR_CLEANUP = 'SORT_BAR_CLEANUP'
