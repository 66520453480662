import { swoopAPI, recommendationApi } from '@/api'

export const getOpportunity = opportunityId => swoopAPI.get(`/api/v1/opportunity/${opportunityId}/details`)
// data: {companyId: string}
export const getOpportunitySummary = data => swoopAPI.get('/api/v1/opportunity/summary', { params: data })
// data: {skip: number, take: number, companyId: string}
export const getOpportunityList = data => swoopAPI.get('/api/v1/opportunity/list', { params: data }) // before recommendationApi we used this

export const getRecommendationsList = (companyId, useCase) => recommendationApi.get(`/recommendations/${companyId}?useCase=${useCase}`)

export const getInsights = (companyId, useCase) => recommendationApi.get(`/insights/company/${companyId}?useCase=${useCase}`)

export const updateInsights = (performActionUrl) => recommendationApi.put(`${performActionUrl}`)

export const opportunityCallback = data => swoopAPI.post('/api/v1/opportunity/callback', data)

export const registerInterest = data => recommendationApi.post('/stats/register-interest', data)

export const getProductStatus = (companyId, productId) => recommendationApi.get(`/application/company/${companyId}/product/${productId}/status`)
