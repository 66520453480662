import router from '@/router'
import exploreRoute from './explore/explore.routes'
import applicationsRoute from './applications/applications.routes'
import dealsRoute from './deals/deals.routes'
import { AppSidebar, AppHeader } from '@/components'

export default () => {
  router.addRoutes([
    {
      path: '/funding',
      name: 'funding',
      redirect: { name: 'loan-matches' },
      components: {
        default: () => import(/* webpackChunkName: "funding" */ './funding.vue'),
        sidebar: AppSidebar,
        header: AppHeader
      },
      children: [exploreRoute, applicationsRoute, dealsRoute]
    }
  ])
}
