export default {
  path: 'funding-requirements',
  name: 'journey-funding-requirements',
  component: () => import(/* webpackChunkName: "funding-requirements" */ './fundingRequirements.vue'),
  meta: {
    permissions: {
      roles: ['user'],
      redirectTo: {
        name: 'login'
      }
    }
  }
}
