import { camelToKebab } from '../utils/utils'

import AppSidebar from './layout/AppSidebar'
import AppHeader from './layout/AppHeader'
import AppAnonymousHeader from './layout/AppAnonymousHeader'
import AppFooter from './layout/AppFooter'
import AppOnboarding from './layout/AppOnboarding'

import Spinner from './Spinner'
import Dropdown from './Dropdown'
import SwoopLogo from './SwoopLogo'
import TenantText from './TenantText'
import InputCheckbox from './forms/Checkbox'
import InputRadio from './forms/Radio'
import DateDropdown from './forms/DateDropdown'
import SlideToggle from './animations/SlideToggle'

// Re-export layout components to have access from one place
export { AppSidebar, AppHeader, AppAnonymousHeader, AppFooter, AppOnboarding }

// Components to automatically registered
const componentsToRegister = { Spinner, Dropdown, SwoopLogo, TenantText, InputCheckbox, InputRadio, SlideToggle, DateDropdown }

export default function registerComponents(Vue) {
  Object.keys(componentsToRegister).forEach((componentKey, index) => {
    // transform name to kebab case ( IconFrame -> icon-frame )
    const name = camelToKebab(componentKey)
    Vue.component(name, componentsToRegister[componentKey])
  })
}
