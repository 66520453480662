import defaultRoute from './default.routes'
import login from '@/features/signIn/signIn.routes'
import signUp from '@/features/signUp/signUp.routes'
import resetPassword from '@/features/resetPassword/resetPassword.routes'
import partner from '@/features/partner/partner.routes'
import secured from '@/features/secured/secured.routes'
import journey from '@/features/journey/journey.routes'
import path from '@/features/path/path.routes'
import truelayer from '@/features/truelayer/truelayer.routes'
import forwardAI from '@/features/forwardAI/forwardAI.routes'
import dashboard from '@/features/dashboard/dashboard.routes'
import funding from '@/features/funding/funding.routes'
import financialInformation from '@/features/financialInformation/financialInformation.routes'
import additionalFinancialInformation from '@/features/additionalFinancialInformation/additionalFinancialInformation.routes'
import profile from '@/features/profile/profile.routes'
import savings from '@/features/savings/savings.routes'
import banks from '@/features/banks/banks.routes'
import bankIntegration from '@/features/bankIntegration/bankIntegration.routes'
import notifications from '@/features/notifications/notifications.routes'
import dataRoom from '@/features/dataRoom/dataRoom.routes'
import integrations from '@/features/integrations/integrations.routes'
import applicationForm from '@/features/applicationForm/applicationForm.routes'
import insurance from '@/features/insurance/insurance.routes'
import bulkCompanies from '@/features/bulkCompanies/bulkCompanies.routes'
import stage from '../features/onboarding/routes'
import products from '../../app/product/features/products/products.routes'
import cashflowForecast from '@/features/cashflowForecast/cashflowForecast.routes'
import natwest from '@/features/natwest/natwest.routes'
import creditPassport from '@/features/creditPassport/creditPassport.routes'
import aggregator from '@product/features/aggregator/aggregator.routes'
import lloyds from '@/features/lloyds/lloyds.routes'

export default () => {
  defaultRoute('login')
  resetPassword()
  login()
  signUp()
  creditPassport()
  aggregator()
  products()
  stage()
  partner()
  secured()
  journey()
  path()
  truelayer()
  forwardAI()
  dashboard()
  natwest()
  bulkCompanies()
  funding()
  financialInformation()
  additionalFinancialInformation()
  profile()
  savings()
  banks()
  bankIntegration()
  notifications()
  dataRoom()
  integrations()
  applicationForm()
  insurance()
  lloyds()
  cashflowForecast()
}
