import router from '@/router'

export const lloydsGrantFinderRoutes = [
  {
    path: '/grant-finder',
    name: 'lloyds-landing',
    component: () => import(/* webpackChunkName: "lloyds-landing" */ './landing.vue')
  },
  {
    path: '/lloyds-grants-eligibility',
    name: 'lloyds-form',
    component: () => import(/* webpackChunkName: "lloyds-grants-form" */ './grants/grantsForm.vue')
  },
  {
    path: '/lloyds-grants-matches',
    name: 'lloyds-grants',
    component: () => import(/* webpackChunkName: "lloyds-grants" */ './grants/grants.vue')
  },
  {
    path: '/lloyds-grants-matches/:id',
    name: 'lloyds-grants-overlay',
    component: () => import(/* webpackChunkName: "lloyds-grants-overlay" */ './grants/grant.vue')
  }
]

export default () => {
  router.addRoutes(lloydsGrantFinderRoutes)
}
