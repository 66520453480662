import { swoopCompaniesApi, swoopAggregatorApi } from '@/api'

export const createCompanyOpportunity = companyId =>
  swoopCompaniesApi.post(`/api/companies/${companyId}/opportunities`, {
    productCategory: 'BusinessLoan'
  })

export const checkWithProviders = (companyId, opportunityId) => swoopAggregatorApi.post(`/api/companies/${companyId}/opportunities/${opportunityId}/check-with-providers`)

export const getFieldDefinitions = () => swoopCompaniesApi.get('/api/fielddefinitions')

export const updateCompanyOpportunity = (companyId, companyApplicationId, data) =>
  swoopCompaniesApi.put(`/api/companies/${companyId}/opportunities/${companyApplicationId}`, { fieldValues: data })

export const getCompanyOpportunities = companyId => swoopCompaniesApi.get(`/api/companies/${companyId}/opportunities`)

export const deleteCompanyOpportunities = (companyId, opportunityId) => swoopCompaniesApi.delete(`/api/companies/${companyId}/opportunities/${opportunityId}`)

export const getRequiredFields = (companyId, opportunityId) => swoopAggregatorApi.get(`/api/companies/${companyId}/opportunities/${opportunityId}/required-fields`)

export const createCompanyContact = companyId =>
  swoopCompaniesApi.post(`/api/companies/${companyId}/contacts`, {
    headers: {
      'Content-Type': 'application/json'
    }
  })

export const updateCompanyContact = (companyId, contactId, data) => swoopCompaniesApi.put(`/api/companies/${companyId}/contacts/${contactId}`, { fieldValues: data })

export const createCompanyContactAddress = (companyId, contactId) => swoopCompaniesApi.post(`/api/companies/${companyId}/contacts/${contactId}/addresses`)

export const updateCompanyContactAddress = (companyId, contactId, contactAddressId, data) =>
  swoopCompaniesApi.put(`/api/companies/${companyId}/contacts/${contactId}/addresses/${contactAddressId}`, { fieldValues: data })

export const getOpportunityDecisions = (companyId, opportunityId) => swoopCompaniesApi.get(`/api/companies/${companyId}/opportunities/${opportunityId}/applications`)

export const getUserEvents = (companyId, opportunityId, applicationId) =>
  swoopCompaniesApi.post(`/api/companies/${companyId}/opportunities/${opportunityId}/applications/${applicationId}/user-events`, { userEventType: 'FollowedOfferLink' })
