import { allowOnlyWithCompletedJourney } from '@/router/routerGuards'

export default {
  path: 'applications',
  name: 'funding-applications',
  component: () => import(/* webpackChunkName: "funding-applications" */ './applications.vue'),
  meta: {
    permissions: {
      roles: ['user'],
      redirectTo: {
        name: 'login'
      }
    }
  },
  beforeEnter: allowOnlyWithCompletedJourney()
}
