import createRoutine from '@/utils/createRoutine'

export const loanFormFieldsRoutine = createRoutine('loanFormFieldsRoutine')
export const enquiryFormFieldsRoutine = createRoutine('enquiryFormFieldsRoutine')
export const enquiryFormSectionsRoutine = createRoutine('enquiryFormSectionsRoutine')

export const grantFormFieldsRoutine = createRoutine('grantFormFieldsRoutine') // Australia
export const equityFormFieldsRoutine = createRoutine('equityFormFieldsRoutine') // Australia

export const formFieldsGetters = {
  LOAN_FORM_FIELDS: 'LOAN_FORM_FIELDS',
  ENQUIRY_FORM_FIELDS: 'ENQUIRY_FORM_FIELDS',
  ENQUIRY_FORM_SECTIONS: 'ENQUIRY_FORM_SECTIONS',
  GRANT_FORM_FIELDS: 'GRANT_FORM_FIELDS',
  EQUITY_FORM_FIELDS: 'EQUITY_FORM_FIELDS'
}

export const FORM_FIELDS_CLEANUP = 'FORM_FIELDS_CLEANUP'
