import router from '@/router'
import { AppFooter } from '@/components'

// Setup routes
export default () => {
  router.addRoutes([
    {
      path: '/secured',
      name: 'secured',
      components: {
        default: () => import(/* webpackChunkName: "secured" */ './secured.vue'),
        footer: AppFooter
      },
      meta: {
        permissions: {
          roles: ['user'],
          redirectTo: {
            name: 'login'
          }
        }
      }
    }
  ])
}
