import defaultWhitelabelConfig from '@extend/config/whitelabel'

export default {
  ...defaultWhitelabelConfig,
  features: {
    ...defaultWhitelabelConfig.features,
    linkBankAccount: false,
    illionEnabled: true,
    savingsTabsEnabled: false,
    aggregatorEnabled: false,
    savingsServices: {
      amounts: false,
      fx: true,
      banking: false,
      utilities: true,
      overdraftFees: false,
      insurance: false
    },
    cashflowForecastingEnabled: false
  },
  components: {
    ...defaultWhitelabelConfig.components,
    creditPassportEnabled: false,
    creditScorePlusEnabled: false,
    companySearch: {
      soleTraderConfirmation: true,
      countryCode: false,
      cantFindCompany: true,
      allowAddCompanyNumber: true
    }
  },
  journey: {
    ...defaultWhitelabelConfig.journey,
    grantsEnabled: false,
    equityEnabled: false
  },
  links: {
    ...defaultWhitelabelConfig.links,
    privacyPolicyLink: 'https://swoopfunding.com.au/privacy-policy',
    termsAndConditionsLink: 'https://swoopfunding.com.au/terms-conditions',
    potentialSavingsLink: 'https://swoopfunding.com/compare-business-current-account/?prev_page=app_BCA'
  },
  signUp: {
    ...defaultWhitelabelConfig.signUp,
    countryRequired: false
  },
  country: {
    defaultCountry: 'Australia',
    defaultCountryCode2: 'AU',
    defaultLocaleString: 'en-AU',
    currency: {
      defaultCurrencySymbol: '$',
      defaultCurrencyName: 'AUD',
      defaultCurrencyFormat: {
        thousandsSeparator: ',',
        decimalsSeparator: '.'
      }
    }
  },
  forms: {
    ...defaultWhitelabelConfig.forms,
    states: true,
    provinces: false,
    hideMonthlyRecurringRevenue: false,
    currentlyBankOptions: [
      { label: 'Adelaide Bank', value: 'Adelaide Bank' },
      { label: 'ANZ', value: 'ANZ' },
      { label: 'Auswide Bank', value: 'Auswide Bank' },
      { label: 'AWA Alliance Bank', value: 'AWA Alliance Bank' },
      { label: 'Bank Australia', value: 'Bank Australia' },
      { label: 'Bank of China', value: 'Bank of China' },
      { label: 'Bank of Melbourne', value: 'Bank of Melbourne' },
      { label: 'Bank of Queensland', value: 'Bank of Queensland' },
      { label: 'Bank of Sydney', value: 'Bank of Sydney' },
      { label: 'Bank of Us', value: 'Bank of Us' },
      { label: 'BankSA', value: 'BankSA' },
      { label: 'Bankwest', value: 'Bankwest' },
      { label: 'Bendigo Bank', value: 'Bendigo Bank' },
      { label: 'Beyond Bank', value: 'Beyond Bank' },
      { label: 'Citibank', value: 'Citibank' },
      { label: 'Commonwealth Bank', value: 'Commonwealth Bank' },
      { label: 'Delphi Bank', value: 'Delphi Bank' },
      { label: 'Heritage', value: 'Heritage' },
      { label: 'HSBC', value: 'HSBC' },
      { label: 'IMB', value: 'IMB' },
      { label: 'ING Bank', value: 'ING Bank' },
      { label: 'Macquarie Bank', value: 'Macquarie Bank' },
      { label: 'National Australia Bank', value: 'National Australia Bank' },
      { label: 'Westpac', value: 'Westpac' },
      { label: 'Other', value: 'Other' }
    ],
    stateOptions: [
      { label: 'New South Wales', value: 'New South Wales' },
      { label: 'Queensland', value: 'Queensland' },
      { label: 'Victoria', value: 'Victoria' },
      { label: 'South Australia', value: 'South Australia' },
      { label: 'Tasmania', value: 'Tasmania' },
      { label: 'Western Australia', value: 'Western Australia' },
      { label: 'Australian Capital Territory', value: 'Australian Capital Territory' },
      { label: 'Northern Territory', value: 'Northern Territory' }
    ],
    provinceOptions: []
  }
}
