import { accountingApi } from '@/api'

export const checkIntegrationStatus = companyId => accountingApi.get(`/aggregator/api/v1/companies/${companyId}/integration/status`) // notIntegrated, disconnected, pending, connected, ready

export const accountingOnboard = (companyId, payload) => accountingApi.post(`/aggregator/api/v1/companies/${companyId}/integration/onboarding`, payload)

export const getShortTermCashflow = companyId => accountingApi.get(`/dashboard/api/v1/cashflow/${companyId}/shortterm`)

export const getCashflowForecast = (companyId, selectedHistory, selectedForecast) =>
  accountingApi.get(`/dashboard/api/v1/cashflow/${companyId}?actualMonthAmounts=${selectedHistory}&forecastMonthAmounts=${selectedForecast}`)
