import { notificationMessagesRoutine, NOTIFICATION_MESSAGES_CLEANUP } from '@/store/modules/notificationMessages/routines'
import { companyGetters } from '@/store/modules/company/routines'
import { logoutRoutine } from '@/store/modules/auth/routines'
import { state as initialState } from './index'
import { getAnyUnreadNotification } from '@/api/companyNotification'

export default {
  async [notificationMessagesRoutine.TRIGGER] (context) {
    const companyId = context.getters[companyGetters.COMPANY_ID]
    if (!companyId) return
    let result = null
    try {
      const res = await getAnyUnreadNotification(companyId)
      result = res.data
    } catch (e) {}
    if (companyId === context.getters[companyGetters.COMPANY_ID]) {
      context.commit(notificationMessagesRoutine.TRIGGER, result)
    }
    return result
  },
  [logoutRoutine.SUCCESS] (context) {
    context.commit(NOTIFICATION_MESSAGES_CLEANUP, initialState)
  }
}
