export default {
  vat: 'VAT',
  taxCreditLabel: 'R&D',
  turnover: 'turnover',
  contactNumber: '+44 203 514 3044',
  ReviewingYourDetails: 'In Swoop review',
  signInLeftPanelTitle: 'Welcome back to Swoop!',
  signInLeftPanelSubtitle: 'Sign in to search for whole of market funding and savings solutions, tailored to your clients needs',
  signInLeftPanelFooterText: 'We work with world class partners',
  signupSubtitle: 'Find funding and make savings. Fast, secure and free.',
  journeyIntegrationModalText:
    'Swoop clients who integrate are more likely to access better funding and saving opportunities. By making your data do all they heavy lifting, we are also able to help you quicker.',
  projectName: 'Swoop',
  swoopReviewProcess: 'The Swoop review process',
  startSwoopReview: 'Start Swoop Review',
  submitApplication: 'Submit application',
  youCanSubmitForSwoopReview: 'Before you can Submit for Swoop Review please make sure that you have uploaded all of the required documents',
  submitForSwoopReview: 'Submit for Swoop Review',
  labelEmailMarketing: 'Send me updates on products, news and information on selected partners.',
  contactNumberPlaceholder: '+44 (XXX) XXXXXXX',
  companyNumber: 'Company number',
  companyNameLabel: 'Firm name',
  accountDescription: 'You can discuss your matches with your dedicated Swoop Advisor.',
  financialInfoText: 'Swoop will help you achieve your financial goals - whatever they are.',
  companyAsideText: 'Swoop combines its advanced matching algorithm with decades of funding experience to identify the best options for your business.',
  emptyProductListSubtext: 'Or perfom an Instant Loan search below.',
  welcomeHeader: 'Welcome',
  welcomeSubtitle: '',
  welcomeDescription: '',
  footerOptionalDisclaimer: '',
  stagePage: {
    title: 'How can Swoop help your business?',
    description: "Let's get to know you and your business - choose which suits your current situation best",
    bulletPoints: [
      "Our matching technology won't harm your credit score",
      "You'll be able to compare products from more than 1000 providers",
      "When you're ready to apply for a product, Swoop will get in touch to help you each step of the way"
    ]
  },
  getStartedPage: {
    title: "Let's get started!",
    editTitle: 'Edit your answers',
    description: ['The first step is to get some information from you.', 'These questions help us understand your funding needs.', 'Need help? Contact us on ']
  },
  needHelpText: {
    beforeLink: 'Need help?',
    afterLink: '',
    linkLabel: '+44 203 514 3044',
    linkValue: 'tel:+442035143044'
  },
  matchesContactBanner: {
    icon: '',
    actionText: 'Speak to your Swoop relationship manager',
    name: 'Tom Butterworth',
    phoneNumber: '+442035143044',
    email: 'testEmail@gmail.com'
  },
  tooltips: {
    loanForm: {
      fundingRequired: 'Different lenders within our market place offer a range of loan sizes. By letting us know how much funding you require, we can match you to the most suitable lenders.',
      fundingRequiredTimescale: "We'll do our best to get you your funding in time.",
      fundingPurposes: 'Where the activities involve assets, we can match you with asset finance products.',
      sectors: 'Some lenders are sector specific. By letting us know which industry you operate in, we can match you to the most suitable lenders.',
      firstCommercialSale: 'Some of our lenders specialise in lending to young businesses. By telling us this data we can match you with specialist lenders.',
      turnoverLastYear: 'Knowing your previous year turnover helps us to match you to loans with suitable repayments.',
      cardPaymentsAccepted: 'The amount of revenue generated through card or online payments allow us to match you with suitable lenders.',
      invoicesSent: 'If you issue invoices, we can match you with invoice finance providers.',
      invoiceAverageMonthlyRevenue: 'The amount of revenue generated through invoices allows us to match you with suitable invoice finance lenders.'
    },
    grantForm: {
      fundingRequired: 'Some grants only offer a certain amount of funding, we need to know your requirement so that we can match you to the best suited options.',
      fundingPurposes: 'Different grants are available depending on what you plan to spend the money on. For example some grants are for spending on developing new products or processes, others are to buy new machinery.',
      turnoverLastYear: 'Knowing your previous year turnover helps us to match you to grants which require the business to be trading',
      firstCommercialSale: 'Some Grants require the business to have been trading for a certain amount of time.',
      sectors: 'This will help us to match you to sector specific grant funds that are currently running',
      fundingPurposeSummary: 'This will help our team know what type of grant you are looking for. Maximum of 250 characters.',
      tradingAddressIsDifferent: 'Some grants are specific to your companies location, so we need this data to match you to appropriate grants'
    }
  }
}
