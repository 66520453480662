import { currentFormNameRoutine, fundingAmountRoutine, CURRENT_FORM_NAME_CLEANUP } from '@/store/modules/currentFormDetails/routines'
import { logoutRoutine } from '@/store/modules/auth/routines'
import { state as initialState } from './index'

export default {
  [currentFormNameRoutine.TRIGGER](context, payload) {
    context.commit(currentFormNameRoutine.TRIGGER, payload)
  },

  [fundingAmountRoutine.TRIGGER](context, payload) {
    context.commit(fundingAmountRoutine.TRIGGER, payload)
  },

  [logoutRoutine.SUCCESS](context) {
    context.commit(CURRENT_FORM_NAME_CLEANUP, initialState)
  }
}
