import Vue from 'vue'
import * as Sentry from '@sentry/vue'

export const initSentry = () => {
  const dsn = process.env.VUE_APP_SENTRY_DSN
  const environment = process.env.VUE_APP_SENTRY_ENVIRONMENT

  if (dsn && environment) {
    Sentry.init({
      Vue,
      dsn,
      environment,

      // Replay configuration
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,

      integrations: [
        new Sentry.Replay({})
      ]
    })
  }
}
