import requestFlow from '@/utils/requestFlow'
import { bankMandateListRoutine, BANK_CLEANUP } from '@/store/modules/bank/routines'

export default {
  ...requestFlow(bankMandateListRoutine).mockMutations,
  [bankMandateListRoutine.SUCCESS] (state, payload) {
    state.bankMandateList = payload
  },

  [BANK_CLEANUP] (state, payload) {
    Object.assign(state, payload)
  }
}
