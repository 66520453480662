import { pageOverlayRoutine, PAGE_OVERLAY_CLEANUP } from '@/store/modules/pageOverlay/routines'

export default {
  [pageOverlayRoutine.TRIGGER](state, payload) {
    state.isOverlayOpen = payload
  },

  [PAGE_OVERLAY_CLEANUP](state, payload) {
    Object.assign(state, payload)
  }
}
