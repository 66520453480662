import { loginRoutine, logoutRoutine, logoutLocalRoutine, registerRoutine, userRoutine, productsRoutine, groupRoutine } from '@/store/modules/auth/routines'
import { setTenantNameCookie, getURLSubdomain, getLockedProducts, deleteCookie } from '@/utils/utils'
import { tenantCookieName } from '@/utils/constants'
import { login, logout } from '@/api/authorization'
import { loginSSO } from '@/api/sso'
import { getUser } from '@/api/user'
import router from '@/router'

export default {
  async [loginRoutine.TRIGGER](context, payload) {
    let response = null

    try {
      if ('correlationId' in payload) response = await loginSSO(payload)
      else response = await login(payload)

      await context.dispatch(loginRoutine.SUCCESS, { ...response.data, loginDate: new Date().toISOString() })
    } catch (error) {
      context.dispatch(loginRoutine.FAILURE, error)

      throw error
    }
    return response
  },

  async [loginRoutine.SUCCESS](context, payload) {
    // GroupId is returned on login from the token endpoint
    context.commit(groupRoutine.TRIGGER, { id: payload.groupId, isExternalGroup: context.state.isExternalGroup })
    context.commit(loginRoutine.SUCCESS, payload)

    try {
      await context.dispatch(userRoutine.TRIGGER)
    } catch (e) {
      console.error(e)
    }
  },

  [loginRoutine.FAILURE](context, error) {
    context.commit(loginRoutine.FAILURE, error)
  },

  async [productsRoutine.TRIGGER](context) {
    const lockedProducts = await getLockedProducts(context.state.user?.goalsCompleted)

    context.commit(productsRoutine.SUCCESS, Object.values(lockedProducts).includes(false))
  },

  async [userRoutine.TRIGGER](context) {
    let response = null

    try {
      response = await getUser()

      context.dispatch(userRoutine.SUCCESS, response.data)
    } catch (error) {
      context.dispatch(userRoutine.FAILURE, error)

      throw error
    }
    return response
  },

  [userRoutine.SUCCESS](context, payload) {
    context.commit(userRoutine.SUCCESS, payload)
    // Other group data is returned from the user/current endpoint
    context.commit(groupRoutine.TRIGGER, { fromInvite: payload.fromInvite, inviteSource: payload.inviteSource, name: payload.name || '' })
    context.dispatch(productsRoutine.TRIGGER)

    setTenantNameCookie(getURLSubdomain())
  },

  [userRoutine.FAILURE](context, error) {
    context.commit(userRoutine.FAILURE, error)
    console.log('failure', error)
  },

  async [logoutRoutine.TRIGGER](context) {
    try {
      deleteCookie(tenantCookieName)

      await logout()
    } catch (e) {
      console.error(e)
    }

    await context.dispatch(logoutLocalRoutine.TRIGGER)
  },

  [logoutRoutine.SUCCESS](context) {
    context.commit(logoutRoutine.SUCCESS)
  },

  async [logoutLocalRoutine.TRIGGER](context) {
    context.commit(logoutRoutine.TRIGGER)

    localStorage.clear()
    await router.push({ name: 'login' })

    context.dispatch(logoutRoutine.SUCCESS)
  },

  [registerRoutine.TRIGGER](context, payload) {
    context.commit(registerRoutine.TRIGGER, payload)
  },

  [groupRoutine.TRIGGER](context, payload) {
    context.commit(groupRoutine.TRIGGER, payload)
  }
}
