import { swoopAssistantRoutine, SWOOP_ASSISTANT_CLEANUP } from '@/store/modules/swoopAssistant/routines'

export default {
  [swoopAssistantRoutine.TRIGGER](state, payload) {
    state.swoopAssistant = !state.swoopAssistant
  },
  [SWOOP_ASSISTANT_CLEANUP](state, payload) {
    Object.assign(state, payload)
  }

}
