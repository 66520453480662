import { aggregatorCompanyOpportunitiesRoutine, aggregatorOpportunityIdRoutine, OPPORTUNITY_CLEANUP } from './routines'
import requestFlow from '@/utils/requestFlow'
import { getCompanyOpportunities } from '@product/api/aggregator'
import { logoutRoutine } from '@/store/modules/auth/routines'
import { state as initialState } from './index'

export default {
  ...requestFlow(aggregatorCompanyOpportunitiesRoutine, getCompanyOpportunities).actions, // calls all opportunities

  [aggregatorOpportunityIdRoutine.TRIGGER](context, payload) {
    context.commit(aggregatorOpportunityIdRoutine.TRIGGER, payload)
  },

  [logoutRoutine.SUCCESS](context) {
    context.commit(OPPORTUNITY_CLEANUP, initialState)
  }
}
