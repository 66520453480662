const generatedScripts = [
  {
    scriptId: 'bing-ad-tracking-script',
    scriptText: `(function(w,d,t,r,u)
    {
        var f,n,i;
        w[u]=w[u]||[],f=function()
        {
            var o={ti:"56022360"};
            o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")
        },
        n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function()
        {
            var s=this.readyState;
            s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)
        },
        i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)
    })
    (window,document,"script","//bat.bing.com/bat.js","uetq");`,
    restraints: {
      regions: ['uk'],
      whitelabels: ['swoop']
    }
  }
]

export const loadExternal = ({ src, onload, configEnabled = true, forceReplace = false, defer = true }) => {
  const id = src.substring(src.lastIndexOf('/') + 1)
  const existingElement = document.getElementById(id)

  if (!configEnabled || (!forceReplace && existingElement)) {
    return onload ? onload() : undefined
  } else if (forceReplace && existingElement) {
    existingElement.parentNode.removeChild(existingElement)
  }

  if (id.endsWith('.js')) {
    const s = document.createElement('script')

    s.setAttribute('id', id)
    s.src = src
    s.async = true
    s.defer = defer

    const e = document.getElementsByTagName('script')[0]

    e.parentNode.insertBefore(s, e)
    e.onload = onload

    if (forceReplace && existingElement) {
      onload()
    }
  } else if (id.endsWith('.css')) {
    const s = document.createElement('link')
    s.setAttribute('id', id)
    s.href = src
    s.rel = 'stylesheet'
    s.async = true

    const e = document.getElementsByTagName('link')[0]

    e.parentNode.insertBefore(s, e)
  }
}

export const loadGeneratedScripts = () => {
  generatedScripts.forEach(script => {
    generateScript(script)
  })
}

export const generateScript = ({ scriptId, scriptText, restraints = { regions: [], whitelabels: [] }, async = true }) => {
  if (document.getElementById(scriptId)) return
  if (restraints.regions.length && !restraints.regions.includes(process.env.VUE_APP_TENANT)) return
  if (restraints.whitelabels.length && !restraints.whitelabels.includes(window.WHITE_LABEL_STYLES.subdomain)) return

  const f = document.getElementsByTagName('head')[0]
  const j = document.createElement('script')

  j.id = scriptId
  j.async = async
  j.text = scriptText
  f.insertBefore(j, f.firstChild)
}

export const removeExternal = src => {
  const id = src.toString().substring(src.lastIndexOf('/') + 1)
  const existingElement = document.getElementById(id)

  if (existingElement) {
    existingElement.parentNode.removeChild(existingElement)
  }
}
