import { allowOnlyGuests } from '@/router/routerGuards'

import router from '@/router'

// Setup routes
export default () => {
  router.addRoutes([
    {
      path: '/forgot-password',
      component: () => import(/* webpackChunkName: "onboarding-root" */ '../../components/OnboardingWrapper.vue'),
      children: [
        {
          path: '',
          name: 'forgot-password',
          components: {
            default: () => import(/* webpackChunkName: "reset-password" */ './resetPassword.vue'),
            geometricBackground: () => import(/* webpackChunkName: "geometric-background" */ '../../components/GeometricBackground.vue')
          },
          beforeEnter: allowOnlyGuests()
        },
        {
          path: 'check-email',
          name: 'forgot-password-check-email',
          component: () => import(/* webpackChunkName: "reset-password-check-email" */ './resetPasswordCheckEmail.vue'),
          beforeEnter: allowOnlyGuests()
        }
      ]
    }
  ])
}
