import defaultDictionary from '@extend/config/dictionary'

export default {
  ...defaultDictionary,
  contactNumber: '+61 2 7908 3807',
  journeyIntegrationModalText: 'Swoop clients who integrate are more likely to access better funding opportunities. By making your data do all they heavy lifting, we are also able to help you quicker.',
  vat: 'BAS',
  contactNumberPlaceholder: '+61 XXX XXX XXX',
  companyNumber: 'ABN',
  financialInfoText: 'Swoop will help you achieve your financial goals - whatever they are.',
  companyAsideText: 'Swoop combines its advanced matching algorithm with decades of funding experience to identify the best options for your business.',
  emptyProductListSubtext: '',
  needHelpText: {
    ...defaultDictionary.needHelpText,
    linkLabel: '02 7908 3807',
    linkValue: 'tel:0279083807',
  }
}
