import { notificationRoutine, NOTIFICATION_CLEANUP } from '@/store/modules/notification/routines'

export default {
  [notificationRoutine.TRIGGER] (state, payload) {
    state.notificationConfig = payload
  },
  [NOTIFICATION_CLEANUP] (state, payload) {
    Object.assign(state, payload)
  }
}
