import { sidebarRoutine, SIDEBAR_CLEANUP } from '@/store/modules/sidebar/routines'
import { logoutRoutine } from '@/store/modules/auth/routines'
import { state as initialState } from './index'

export default {
  [sidebarRoutine.TRIGGER](context, payload) {
    context.commit(sidebarRoutine.TRIGGER, payload)
  },
  [logoutRoutine.SUCCESS](context) {
    context.commit(SIDEBAR_CLEANUP, initialState)
  }
}
