import { checkDynamicFormListUpdatedRoutine, updateDynamicFormListRoutine } from '@/store/modules/dynamicFormList/routines'
import { getAllForms } from '@/api/goals/index'
import { notificationRoutine } from '../notification/routines'
import { toastTopCenterOptions } from '@/config/vue-toast'

export default {
  async [updateDynamicFormListRoutine.TRIGGER](context, payload) {
    if (payload) {
      return context.commit(updateDynamicFormListRoutine.SUCCESS, payload)
    }

    return await getAllForms()
      .then(({ data }) => {
        context.commit(updateDynamicFormListRoutine.SUCCESS, data)
      })
      .catch(() => {
        context.dispatch(updateDynamicFormListRoutine.FAILURE)
      })
  },

  async [checkDynamicFormListUpdatedRoutine.TRIGGER](context) {
    if (context.state.dynamicFormList.length) return

    await getAllForms()
      .then(({ data }) => {
        context.commit(checkDynamicFormListUpdatedRoutine.SUCCESS, data)
      })
      .catch(() => {
        context.commit(checkDynamicFormListUpdatedRoutine.FAILURE)
      })
  },

  [updateDynamicFormListRoutine.FAILURE]() {
    this.$store.dispatch(notificationRoutine.TRIGGER, {
      title: 'No matching form found',
      options: { ...toastTopCenterOptions, className: 'toasted-error' },
      text: 'Sorry, we could not find any goals forms for you.'
    })
  },

  [checkDynamicFormListUpdatedRoutine.FAILURE]() {
    this.$store.dispatch(notificationRoutine.TRIGGER, {
      title: 'No matching form found',
      options: { ...toastTopCenterOptions, className: 'toasted-error' },
      text: 'Sorry, we could not find any goals forms for you.'
    })
  }
}
