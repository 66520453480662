import { notificationRoutine, NOTIFICATION_CLEANUP } from '@/store/modules/notification/routines'
import { logoutRoutine } from '@/store/modules/auth/routines'
import { state as initialState } from './index'

export default {
  [notificationRoutine.TRIGGER] (context, payload) {
    context.commit(notificationRoutine.TRIGGER, payload)
  },
  [logoutRoutine.SUCCESS] (context) {
    context.commit(NOTIFICATION_CLEANUP, initialState)
  }
}
