import router from '@/router'

import personalInfo from './personalInfo/personalInfo.routes'
import email from './email/email.routes'
import password from './password/password.routes'
import settings from './settings/settings.routes'

export default () => {
  router.addRoutes([
    {
      path: '/profile',
      component: () => import(/* webpackChunkName: "profile" */ './profile.vue'),
      children: [personalInfo, email, password, settings],
      meta: {
        permissions: {
          roles: ['user'],
          redirectTo: {
            name: 'login'
          }
        }
      }
    }
  ])
}
