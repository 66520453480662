<template>
  <div class="core-documents">
    <div class="ui flex row">
      <div class="col-sm-6 description m-b-15">
        <label class="ui text dark header m-b-10">Accelerate your funding process</label>
        <p class="text dark">
          To receive faster decisions and accelerate your unding process, please upload the following 6 key documents using a single PDF for each:
        </p>
        <ul>
          <li>
            1) 6 Months Bank Statements
          </li>
          <li>
            2) Last 1 Year Filed Accounts
          </li>
          <li>
            3) Management Accounts
          </li>
          <li>
            4) Last 4 {{$dictionary.vat}} Returns (If trading less than 4 years, please include all existing {{$dictionary.vat}} Returns)
          </li>
          <li>
            5) Proof of Identity for all Director(s)
          </li>
          <li>
            6) Proof of Address for all Director(s)
          </li>
        </ul>
        <div class="action-container">
          <button class="ui button circular m-r-10" @click.prevent="$modal.hide('coreDocumentsModal')">
            Back
          </button>
          <button class="ui button circular btn-primary" @click.prevent="$modal.hide('coreDocumentsModal')" :disabled="!allDocumentsUploaded">
            Complete
          </button>
        </div>
      </div>
      <div class="col-sm-6 list">
        <div v-if="loadingDocs" class="ui active inverted dimmer page-loader">
          <div class="ui medium text loader"><spinner :size="64" :loader="true" color /></div>
        </div>
        <ProductRequiredDocuments v-else v-bind="{ documentsList, requiredDocumentsList }" @change="documentsListChangeHandler" :allowMultipleFiles="false" />
      </div>
    </div>
  </div>
</template>

<script>
import ProductRequiredDocuments from '@/components/ProductRequiredDocuments'
import { mapGetters } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import { getDocumentsList } from '@/api/document'
import { requiredDocumentsAdapter } from '@/utils/utils'

export default {
  name: 'CoreDocuments',
  components: {
    ProductRequiredDocuments
  },
  data() {
    return {
      documentsList: [],
      loadingDocs: true
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID
    }),
    allDocumentsUploaded() {
      return this.documentsList && this.documentsList.length >= 6 && this.requiredDocumentsList.every(doc => doc.isDocumentUploaded)
    },
    requiredDocumentsList() {
      const docs = requiredDocumentsAdapter({
        documentsList: this.documentsList || [],
        requiredDocuments: {
          hasBankStatements: 6,
          filedAccounts: 1,
          hasManagementAccounts: true,
          hasVATReturns: true,
          proofOfIdentity: true,
          proofOfAddress: true
        },
        isPrivateLimitedCompany: false,
        opportunity: {
          bankStatements: 6
        }
      })

      return docs
    }
  },
  methods: {
    loadDocumentsList() {
      this.documentsList = []
      this.loadingDocs = true
      return getDocumentsList(this.companyId).then(res => {
        this.documentsList = res.data
        this.loadingDocs = false
      })
    },
    documentsListChangeHandler() {
      this.loadDocumentsList()
    }
  },
  created() {
    this.loadDocumentsList()
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/swoop/variables';

.core-documents {
  .description {
    display: flex;
    flex-direction: column;
    .header {
      font-size: 1.25rem;
      font-weight: 500;
    }
    ul {
      margin-top: 8px;
      font-size: 1rem;
      list-style-type: none;
      padding-inline-start: 0;
    }
    .action-container {
      margin-top: auto;
      > button {
        min-width: 100px;
      }
    }
  }
  .list {
    position: relative;
    .page-loader {
      max-height: 100%;
    }
    /deep/ {
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
          margin-bottom: 4px;
          border: 1px solid var(--color-primary-100);
          border-radius: 12px;
          a.link,
          .list-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px;
            text-decoration: none;
            > i {
              flex-shrink: 0;
              margin-left: 10px;
            }
          }
        }
      }
      .file-names {
        display: block;
        > li {
          display: inline-block;
          font-size: 0.75em;
          line-height: 1em;
          margin-bottom: 0;
          border: 0;
          &:not(:last-child) {
            margin-right: 0.6em;
            &::after {
              content: ',';
            }
          }
        }
      }
    }
  }
}
</style>
