import router from '@/router'

export const natwestGrantFinderRoutes = [
  {
    path: '/natwest-grants-get-started',
    name: 'natwest-landing',
    component: () => import(/* webpackChunkName: "natwest-landing" */ './landing.vue')
  },
  {
    path: '/natwest-grants-eligibility',
    name: 'natwest-form',
    component: () => import(/* webpackChunkName: "natwest-grants-form" */ './grants/grantsForm.vue')
  },
  {
    path: '/natwest-grants-matches',
    name: 'natwest-grants',
    component: () => import(/* webpackChunkName: "natwest-grants" */ './grants/grants.vue')
  },
  {
    path: '/natwest-grants-matches/:id',
    name: 'natwest-grants-overlay',
    component: () => import(/* webpackChunkName: "natwest-grants-overlay" */ './grants/grant.vue')
  }
]

export default () => {
  router.addRoutes(natwestGrantFinderRoutes)
}
