import requestFlow from '@/utils/requestFlow'
import { opportunityRoutine, OPPORTUNITY_CLEANUP } from './routines'

export default {
  ...requestFlow(opportunityRoutine).mockMutations,

  [opportunityRoutine.SUCCESS] (state, payload) {
    state.opportunityList = payload.opportunities
  },
  [OPPORTUNITY_CLEANUP] (state, payload) {
    Object.assign(state, payload)
  }
}
