import { creditScoreDetailsRoutine, CREDIT_SCORE_DETAILS_CLEANUP } from './routines'
import requestFlow from '@/utils/requestFlow'
import { getCreditReport } from '@/api/creditReport'
import { logoutRoutine } from '@/store/modules/auth/routines'
import { state as initialState } from './index'

export default {
  ...requestFlow(creditScoreDetailsRoutine, getCreditReport).actions,

  [logoutRoutine.SUCCESS] (context) {
    context.commit(CREDIT_SCORE_DETAILS_CLEANUP, initialState)
  }
}
