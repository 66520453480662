import { companyGetters, companyRoutine, companyStateRoutine, companySelectRoutine, COMPANY_CLEANUP } from '@/store/modules/company/routines'
import { userRoutine, logoutRoutine, productsRoutine } from '@/store/modules/auth/routines'
import { getCompanyState } from '@/api/company'
import requestFlow from '@/utils/requestFlow'
import { state as initialState } from './index'

export default {
  ...requestFlow(companyRoutine).actions,
  ...requestFlow(companyStateRoutine, getCompanyState).actions,

  async [companyRoutine.SUCCESS](context, payload) {
    await context.dispatch(userRoutine.TRIGGER)
    await context.dispatch(companySelectRoutine.TRIGGER, payload ? payload.companyId : null)
    context.dispatch(companyRoutine.FULFILL, payload)
  },

  async [companySelectRoutine.TRIGGER](context, payload) {
    context.commit(companySelectRoutine.SUCCESS, payload)
    const companyId = context.getters[companyGetters.COMPANY_ID]
    await context.dispatch(productsRoutine.TRIGGER, payload ? payload.companyId : null)
    await context.dispatch(userRoutine.TRIGGER)
    await context.dispatch(companyStateRoutine.TRIGGER, companyId)
  },

  [logoutRoutine.SUCCESS](context) {
    context.commit(COMPANY_CLEANUP, initialState)
  },
  [COMPANY_CLEANUP](context) {
    context.commit(COMPANY_CLEANUP, initialState)
  }
}
