<template>
  <div class="app-onboarding-container">
    <aside class="left-panel">
      <slot name="left-panel" />
    </aside>
    <!-- Routes transitions only affects the main-panel while the left-panel is kept. -->
    <!-- Transition effects (name) are based on routes. -->
    <transition :name="transitionConfig.transition" mode="in-out" appear>
      <main class="main-panel">
        <slot name="main-panel" />
      </main>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { transitionGetters } from '@/store/modules/transition/routines'

export default {
  name: 'AppOnboarding',
  computed: {
    ...mapGetters({
      transitionConfig: transitionGetters.TRANSITION_CONFIG
    })
  }
}
</script>

<style lang="scss" scoped>
// Default styles for container, main-panel and left-panel. All values can be overriden by the components using the slots.

@import '@/assets/styles/swoop/_variables.scss';

.app-onboarding-container {
  height: 100vh;
  display: flex;

  .left-panel {
    position: relative;
    width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: $color-white;
    background-size: auto;
    z-index: 1;
  }

  .main-panel {
    width: 50vw;
  }
}
</style>
