import { swoopAPI } from '@/api'
import { legacyLoanFormId } from '@/utils/constants'

// New journey
export const getFormCategory = category => swoopAPI.get(`/api/v1/forms/category/${category}`)

// getStarted page
export const getFormDetailsSection = formName => swoopAPI.get(`/api/v1/forms//section/${formName}/detailsSection`)
export const getOnboardingSection = () => swoopAPI.get('/api/v1/forms/section/onboardingSection')

// Remove filled legacy loans form from company
export const removeLegacyLoansForm = companyId => swoopAPI.delete(`/api/v1/forms/${legacyLoanFormId}/${companyId}`)

export const getAllForms = () => swoopAPI.get('/api/v1/forms')

// For all forms
export const getFormStructure = (companyId, formId) => swoopAPI.get(`/api/v1/forms/${formId}/fields/values/${companyId}`)
export const getFormFields = formId => swoopAPI.get(`/api/v1/forms/${formId}/fields`)
export const saveForm = (companyId, formId, fieldValues) => swoopAPI.post(`/api/v1/forms/${formId}/fields/values/${companyId}`, fieldValues)
