import createRoutine from '@/utils/createRoutine'

export const bankMandateListRoutine = createRoutine('bankMandateListRoutine')

export const bankGetters = {
  BANK_MANDATE_ID: 'BANK_MANDATE_ID',
  BANK_MANDATE_LIST: 'BANK_MANDATE_LIST',
  BANK_MANDATE_STATUS: 'BANK_MANDATE_STATUS',
  BANK_MANDATE_STATUS_MAX: 'BANK_MANDATE_STATUS_MAX'
}

export const BANK_CLEANUP = 'BANK_CLEANUP'
