<template>
  <header class="select-goal-header">
    <img :src="require(`@/assets/images/swoop-logo-white.svg`)" alt="Swoop logo" class="swoop-logo" />
    <span>for Business</span>
  </header>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';
@import '@/assets/styles/swoop/_mixins.scss';

.select-goal-header {
  @include flex-display(row, center, flex-start);
  width: 100vw;
  height: 56px;
  position: absolute;
  top: 0;
  left: 0;
  padding: 12px;
  background-color: $color-primary;

  .swoop-logo {
    width: 72px;
    height: 72px;
    margin-right: 6px;
  }

  span {
    margin-bottom: 2px;
    color: $color-inactive;
    font-size: 20px;
    font-weight: 500;
  }
}
</style>
