import router from '@/router'
import { allowOnlyWithCompletedJourney } from '@/router/routerGuards'

router.addRoutes([
  {
    path: '/deal/:opportunityApplicationId',
    name: 'deal-details',
    component: () => import(/* webpackChunkName: "opportunity-view" */ '../../../components/OpportunityView.vue'),
    meta: {
      permissions: {
        roles: ['user'],
        redirectTo: {
          name: 'login'
        }
      }
    },
    beforeEnter: allowOnlyWithCompletedJourney()
  }
])

export default {
  path: 'deals',
  name: 'funding-deals',
  component: () => import(/* webpackChunkName: "funding-deals" */ './deals.vue'),
  meta: {
    permissions: {
      roles: ['user'],
      redirectTo: {
        name: 'login'
      }
    }
  },
  beforeEnter: allowOnlyWithCompletedJourney()
}
