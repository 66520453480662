export default {
  path: 'password',
  name: 'profile-password',
  component: () => import(/* webpackChunkName: "profile-password" */ './password.vue'),
  meta: {
    permissions: {
      roles: ['user'],
      redirectTo: {
        name: 'login'
      }
    }
  }
}
