<template>
  <div class="footer-links">
    <p class="whitelabel-footer-text" v-if="footerOptionalDisclaimer">{{footerOptionalDisclaimer}}</p>
    <ul class="outgoing-footer-links-widget">
      <li v-if="$config.whitelabel.links.footerSwoopLinkEnabled">
        <span class="link">Swoop Website</span>
      </li>
      <li v-else-if="$config.whitelabel.components.swoopMarketingUrlEnabled">
        <a :href="marketingUrl" target="_blank" class="link">
          Swoop Website
        </a>
      </li>
      <li>
        <a :href="$config.whitelabel.links.privacyPolicyLink" target="_blank" class="link">
          Privacy Policy
        </a>
      </li>
      <li>
        <a :href="$config.whitelabel.links.termsAndConditionsLink" target="_blank" class="link">
          Terms & Conditions
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { marketingUrl } from '@/utils/constants'

import config from '@/config/global'
import Vue from 'vue'
Vue.prototype.$config = config

export default {
  name: 'FooterOutgoingLinks',
  data() {
    return {
      marketingUrl,
      footerOptionalDisclaimer: this.$dictionary?.footerOptionalDisclaimer || ''
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/styles/swoop/variables';
.footer-links {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 32px 0;

  .whitelabel-footer-text {
    flex-basis: 100%;
    font-size: 14px;
    margin: 0 20px;
  }

  .outgoing-footer-links-widget {
    list-style-type: none;
    display: flex;
    margin: 0 20px;
    padding-left: 0;
    width: 100%;
    gap: 32px;

    @media only screen and (max-width: $breakpoint-sm-max) {
      margin-top: 75px;
    }

    li {
      &:last-of-type {
        margin-left: auto;
      }
      @media only screen and (max-width: $breakpoint-md-max) {
        font-size: 12px;
      }
      @media only screen and (max-width: $breakpoint-xs-max) {
        font-size: 10px;
        flex-direction: column;
      }
    }
    a.link,
    .link {
      font-size: 12px;
      letter-spacing: -0.5px;
      line-height: 16px;
      color: $color-link;
      text-decoration: none;
      &:hover {
          color: $color-link-hover;
      }
    }
  }
}
</style>
