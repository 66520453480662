import { sidebarRoutine, SIDEBAR_CLEANUP } from '@/store/modules/sidebar/routines'

export default {
  [sidebarRoutine.TRIGGER](state, payload) {
    state.showSidebar = !state.showSidebar
  },
  [SIDEBAR_CLEANUP](state, payload) {
    Object.assign(state, payload)
  }
}
