import { bankGetters } from '@/store/modules/bank/routines'

const bankMandateStatusWeights = {
  FetchingData: 1,
  FetchingDataCompleted: 2,
  Processing: 3,
  ProcessingCompleted: 4
}

export default {
  [bankGetters.BANK_MANDATE_ID]: state => state.bankMandateList && state.bankMandateList.length ? state.bankMandateList[0].bankMandateId : null,
  [bankGetters.BANK_MANDATE_NAME]: state => state.bankMandateList && state.bankMandateList.length ? state.bankMandateList[0].bankName : null,
  [bankGetters.BANK_MANDATE_LIST]: state => state.bankMandateList,
  [bankGetters.BANK_MANDATE_STATUS]: state => {
    if (!state.bankMandateList || !state.bankMandateList.length) return null
    const { status } = state.bankMandateList.reduce((acc, bankMandate) => Math.min(bankMandateStatusWeights[acc.status], bankMandateStatusWeights[bankMandate.status]))
    return status
  },
  [bankGetters.BANK_MANDATE_STATUS_MAX]: state => {
    if (!state.bankMandateList || !state.bankMandateList.length) return null
    const { status } = state.bankMandateList.reduce((acc, bankMandate) => Math.max(bankMandateStatusWeights[acc.status], bankMandateStatusWeights[bankMandate.status]))
    return status
  }
}
