import router from '@/router'
import { AppSidebar, AppHeader } from '@/components'
import whitelabelConfig from '@/config/whitelabel'

export default () => {
  router.addRoutes([
    {
      path: '/credit-health-check-page',
      name: 'credit-health-check-page',
      components: {
        default: () => import(/* webpackChunkName: "credit-health-check-page" */ './creditPassport.vue'),
        header: AppHeader
      }
    },
    {
      path: '/credit-health-check',
      name: 'credit-health-check',
      redirect: whitelabelConfig.components.creditPassportEnabled ? null : 'dashboard',
      components: {
        default: () => import(/* webpackChunkName: "credit-health-check" */ './creditPassport.vue'),
        sidebar: AppSidebar,
        header: AppHeader
      }
    },
    {
      path: '/credit-health-check/report',
      name: 'credit-score',
      components: {
        default: () => import(/* webpackChunkName: "credit-score" */ '../creditScore/creditScore.vue'),
        sidebar: AppSidebar,
        header: AppHeader
      }
    }
  ])
}
