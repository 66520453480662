import router from '@/router'
import { allowOnlyWithCompletedJourney } from '@/router/routerGuards'

router.addRoutes([
  {
    path: '/funding/explore/:opportunityId',
    name: 'funding-explore-deal',
    components: {
      default: () => import(/* webpackChunkName: "funding-explore-deal" */ '../../../components/OpportunityView.vue'),
      meta: {
        permissions: {
          roles: ['user'],
          redirectTo: {
            name: 'login'
          }
        }
      }
    },
    beforeEnter: allowOnlyWithCompletedJourney()
  }
])

export default {
  path: 'explore',
  name: 'funding-explore',
  components: () => import(/* webpackChunkName: "funding-explore" */ './explore.vue'),
  meta: {
    permissions: {
      roles: ['user'],
      redirectTo: {
        name: 'login'
      }
    }
  },
  beforeEnter: allowOnlyWithCompletedJourney()
}
