<script>
export default {
  name: 'Dropdown',
  render (h) {
    return h(
      this.tag,
      {
        class: {
          dropdown: !this.dropup,
          dropup: this.dropup,
          open: this.show
        }
      },
      [
        this.$scopedSlots.default({
          show: this.show
        }),
        h(
          this.menuTag,
          {
            class: {
              [this.dropdownClass]: true,
              'dropdown-menu-right': this.menuRight
            },
            ref: 'dropdown'
          },
          [this.$slots.dropdown]
        )
      ]
    )
  },
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    menuTag: {
      type: String,
      default: 'ul'
    },
    dropdownClass: {
      type: String,
      default: 'dropdown-menu'
    },
    triggerSelector: {
      type: String,
      default: '[data-role="trigger"]'
    },
    appendToBody: {
      type: Boolean,
      default: false
    },
    value: Boolean,
    dropup: {
      type: Boolean,
      default: false
    },
    menuRight: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    notCloseElements: Array,
    positionElement: null
  },
  data () {
    return {
      show: false,
      triggerEl: undefined
    }
  },
  watch: {
    value (v) {
      this.toggle(v)
    }
  },
  mounted () {
    this.initTrigger()
    if (this.triggerEl) {
      this.triggerEl.addEventListener('click', this.toggle)
    }
    window.addEventListener('click', this.windowClicked)
    if (this.value) {
      this.toggle(true)
    }
  },
  beforeDestroy () {
    this.removeDropdownFromBody()
    if (this.triggerEl) {
      this.triggerEl.removeEventListener('click', this.toggle)
    }
    window.removeEventListener('click', this.windowClicked)
  },
  methods: {
    initTrigger () {
      const trigger = this.$el.querySelector(this.triggerSelector) || this.$el.querySelector('.dropdown-toggle') || this.$el.firstChild
      this.triggerEl = trigger && trigger !== this.$refs.dropdown ? trigger : null
    },
    toggle (show) {
      if (this.disabled) {
        return
      }
      if (isBoolean(show)) {
        this.show = show
      } else {
        this.show = !this.show
      }
      if (this.appendToBody) {
        this.show ? this.appendDropdownToBody() : this.removeDropdownFromBody()
      }
      this.$emit('input', this.show)
    },
    windowClicked (event) {
      const target = event.target
      if (this.show && target) {
        let targetInNotCloseElements = false
        if (this.notCloseElements) {
          for (let i = 0, l = this.notCloseElements.length; i < l; i++) {
            if (this.notCloseElements[i].contains(target)) {
              targetInNotCloseElements = true
              break
            }
          }
        }
        const targetInDropdownBody = this.$refs.dropdown.contains(target)
        const targetInTrigger = this.$el.contains(target) && !targetInDropdownBody
        if (!targetInTrigger && !targetInNotCloseElements) {
          this.toggle(false)
        }
      }
    },
    appendDropdownToBody () {
      try {
        const el = this.$refs.dropdown
        el.style.display = 'block'
        document.body.appendChild(el)
        const positionElement = this.positionElement || this.$el
        setDropdownPosition(el, positionElement, this)
      } catch (e) {
        // Silent
      }
    },
    removeDropdownFromBody () {
      try {
        const el = this.$refs.dropdown
        el.removeAttribute('style')
        this.$el.appendChild(el)
      } catch (e) {
        // Silent
      }
    }
  }
}

function isBoolean (obj) {
  return typeof obj === 'boolean'
}

function setDropdownPosition (dropdown, trigger, options = {}) {
  const doc = document.documentElement
  const containerScrollLeft = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0)
  const containerScrollTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
  const rect = trigger.getBoundingClientRect()
  const dropdownRect = dropdown.getBoundingClientRect()
  dropdown.style.right = 'auto'
  dropdown.style.bottom = 'auto'
  if (options.menuRight) {
    dropdown.style.left = containerScrollLeft + rect.left + rect.width - dropdownRect.width + 'px'
  } else {
    dropdown.style.left = containerScrollLeft + rect.left + 'px'
  }
  if (options.dropup) {
    dropdown.style.top = containerScrollTop + rect.top - dropdownRect.height - 4 + 'px'
  } else {
    dropdown.style.top = containerScrollTop + rect.top + rect.height + 'px'
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/swoop/variables';

.dropup,
.dropdown {
  position: relative;
}

.dropdown-toggle:focus {
  outline: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 3px 0;
  margin: 0;
  list-style: none;
  text-align: left;
  background-color: #fff;
  border: 1px solid $default-border-color;
  border-radius: $default-border-radius;
  box-shadow: $default-box-shadow;
  background-clip: padding-box;
  > li {
    > a,
    > .dropdown-item {
      display: block;
      padding: 7px 16px;
      clear: both;
      font-weight: normal;
      white-space: nowrap;
      cursor: pointer;
      &.active,
      &.router-link-exact-active {
        &,
        &:hover,
        &:focus {
          color: $dropdown-link-active-color;
          background-color: $dropdown-link-active-bg;
          text-decoration: none;
          outline: 0;
        }
      }
    }
    > a {
      color: $color-main;
      &:hover,
      &:focus {
        color: $dropdown-link-hover-color;
        background-color: $dropdown-link-hover-bg;
        text-decoration: none;
      }
    }
  }
}

.divider {
  display: block;
  height: 1px;
  overflow: hidden;
  border-bottom: 1px solid $default-border-color;
}

.open {
  > .dropdown-menu {
    display: block;
  }
}

.dropdown-menu-right {
  left: auto;
  right: 0;
}

.dropdown-menu-left {
  left: 0;
  right: auto;
}
</style>
