import { AppHeader } from '@/components/index'
import SwoopForBusinessHeader from '@/components/SwoopForBusinessHeader.vue'
import router from '@/router'

const isWhitelabel = window?.WHITE_LABEL_STYLES?.subdomain !== 'swoop'

// Setup routes
export default () => {
  router.addRoutes([
    {
      path: '/stage',
      name: 'stage',
      components: {
        default: () => import(/* webpackChunkName: "Stage" */ './pages/stage/Stage.vue'),
        header: isWhitelabel ? AppHeader : SwoopForBusinessHeader
      },
      alias: ['/goals', '/goal', '/welcome']
    },
    {
      path: '/get-started',
      name: 'get-started',
      components: {
        default: () => import(/* webpackChunkName: "GetStarted" */ './pages/getStarted/GetStarted.vue'),
        header: isWhitelabel ? AppHeader : SwoopForBusinessHeader
      }
    },
    {
      path: '/details-page',
      name: 'details-page',
      components: {
        default: () => import(/* webpackChunkName: "DetailsPage" */ './pages/detailsPage/DetailsPage.vue'),
        header: isWhitelabel ? AppHeader : SwoopForBusinessHeader
      }
    }
  ])
}
