import { AppSidebar, AppHeader } from '@/components'
import router from '@/router'

export default () => {
  router.addRoutes([
    {
      path: '/cashflow-forecast-integration',
      name: 'cashflow-forecasting-integration',
      components: {
        default: () => import(/* webpackChunkName: "cashflow-forecast-integration" */ './cashflowForecastIntegration.vue'),
        header: AppHeader,
        sidebar: AppSidebar
      }
    },
    {
      path: '/cashflow-forecast',
      name: 'cashflow-forecast',
      components: {
        default: () => import(/* webpackChunkName: "cashflow-forecast-2" */ './cashflowForecast.vue'),
        header: AppHeader,
        sidebar: AppSidebar
      }
    }
  ])
}
