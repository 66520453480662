import { leftHandSideBar, rightHandSideBar } from '@/store/modules/navigationBars/routines'

export default {
  [leftHandSideBar.TRIGGER](state, payload) {
    state.leftHandSideBar = payload
  },
  [rightHandSideBar.TRIGGER](state, payload) {
    state.rightHandSideBar = payload
  }
}
