<template>
  <span>{{ defaultCurrencySymbol }}</span>
</template>

<script>
import config from '@/config/global'

export default {
  data() {
    return {
      defaultCurrencySymbol: config.whitelabel.country.currency.defaultCurrencySymbol
    }
  }
}
</script>
