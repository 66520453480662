import requestFlow from '@/utils/requestFlow'
import { creditScoreDetailsRoutine, CREDIT_SCORE_DETAILS_CLEANUP } from './routines'

export default {
  ...requestFlow(creditScoreDetailsRoutine).mockMutations,

  [creditScoreDetailsRoutine.SUCCESS](state, payload) {
    state.creditScoreDetails = payload
  },

  [CREDIT_SCORE_DETAILS_CLEANUP](state, payload) {
    Object.assign(state, payload)
  }
}
