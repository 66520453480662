import router from '@/router'

export default () => {
  router.addRoutes([
    {
      path: '/truelayer/callback',
      name: 'truelayer-callback',
      component: () => import(/* webpackChunkName: "true-layer-callback" */ './truelayerCallback.vue')
    }
  ])
}
