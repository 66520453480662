import { loginRoutine, logoutRoutine, registerRoutine, userRoutine, productsRoutine, groupRoutine } from '@/store/modules/auth/routines'

export default {
  [loginRoutine.SUCCESS](state, payload) {
    state.auth.error = null
    state.auth.payload = payload
  },
  [loginRoutine.FAILURE](state, error) {
    state.auth.payload = null
    state.auth.error = error
  },
  [logoutRoutine.TRIGGER](state) {
    state.auth.payload = null
    state.auth.error = null
  },
  [logoutRoutine.SUCCESS](state) {
    state.user = null
  },
  [registerRoutine.TRIGGER](state, payload) {
    state.user = {
      ...state.user,
      id: payload.userId
    }
  },
  [userRoutine.SUCCESS](state, payload) {
    state.user = payload
  },
  [productsRoutine.SUCCESS](state, payload) {
    state.productsUnlocked = payload
  },
  [userRoutine.FAILURE](state, error) {
    state.user = null
  },
  [groupRoutine.TRIGGER](state, payload) {
    // Store data related to the group the user is currently in
    if (payload.id !== undefined) {
      state.groupId = payload.id
    }
    if (payload.groupName !== undefined) {
      state.groupName = payload.name
    }
    // Store data related to a group the user was invited to
    if (payload.isExternalGroup !== undefined) {
      state.isExternalGroup = payload.isExternalGroup
    }
    if (payload.fromInvite !== undefined) {
      state.fromInvite = payload.fromInvite
    }
    if (payload.inviteSource !== undefined) {
      state.inviteSource = payload.inviteSource
    }
  }
}
