<template>
<header>
  <div class="container">
    <strong class="logo m-r-15">
      <a class="logo-link" href="https://swoopfunding.com">Swoop</a>
    </strong>
    <nav class="navigation">
      <a href="https://swoopfunding.com">Back to home</a>
    </nav>
  </div>
</header>
</template>

<script>
export default {
  name: 'AppAnonymousHeader'
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/swoop/variables';
@import '../../assets/styles/swoop/mixins';

header {
  padding: 13px 0;
  color: $color-white;
  background: var(--color-tertiary-700);

  & > .container {
    display: flex;
    align-items: center;
  }

  .logo {
    background: url('../../assets/images/swoop-logo-white.svg') 50% 50% no-repeat;
    flex-basis: 70px;
    flex-shrink: 0;
    width: 70px;
    height: 25px;
    cursor: pointer;
    @include hide-text();
  }

  .logo-link {
    display: block;
    height: 100%;
  }

  nav {
    a {
      display: inline-block;
      margin-left: 2px;
      margin-right: 2px;
      padding: 4px 6px;
      color: $color-white;
      border-radius: $default-border-radius;
      transition: background .15s ease-out;
      &.router-link-exact-active,
      &:hover {
        background: rgba(255, 255, 255, 0.15);
      }
    }
  }
}
</style>
