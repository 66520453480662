import router from '@/router'
import { AppSidebar, AppHeader, AppFooter } from '@/components'

export default () => {
  router.addRoutes([
    {
      path: '/notifications',
      name: 'notifications',
      components: {
        default: () => import(/* webpackChunkName: "notifications" */ './notifications.vue'),
        sidebar: AppSidebar,
        header: AppHeader
      },
      meta: {
        permissions: {
          roles: ['user'],
          redirectTo: {
            name: 'login'
          }
        }
      }
    },
    {
      path: '/notifications/view/:id',
      name: 'notifications-view',
      components: {
        default: () => import(/* webpackChunkName: "notifications-view" */ './notificationsView.vue'),
        sidebar: AppSidebar,
        header: AppHeader,
        footer: AppFooter
      },
      meta: {
        permissions: {
          roles: ['user'],
          redirectTo: {
            name: 'login'
          }
        }
      }
    }
  ])
}
