import router from '@/router'

import { AppFooter } from '@/components'

export default () => {
  router.addRoutes([
    {
      path: '/partner/redirect',
      name: 'login-sso',
      component: () => import(/* webpackChunkName: "login-sso" */ './redirect/loginSSO.vue')
    },
    {
      path: '/partner/password/change',
      name: 'partner-password-change',
      components: {
        default: () => import(/* webpackChunkName: "change-partner-password" */ './changePassword/changePartnerPassword.vue'),
        footer: AppFooter
      }
    },
    {
      path: '/partner/password/verification',
      name: 'partner-password-verification',
      components: {
        default: () => import(/* webpackChunkName: "change-partner-password-verification" */ './changePassword/changePartnerPasswordVerification.vue'),
        footer: AppFooter
      }
    }
  ])
}
