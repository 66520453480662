import router from '@/router'
import { AppHeader } from '@/components'

export default () => {
  router.addRoutes([
    {
      path: '/matches/loans/instant/form',
      name: 'aggregator-form',
      components: {
        default: () => import(/* webpackChunkName: "aggregator-form" */ './AggregatorForm.vue'),
        header: AppHeader
      }
    }
  ])
}
