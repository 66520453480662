import requestFlow from '@/utils/requestFlow'
import { tenantGetters, tenantDictionaryRoutine } from '@/store/modules/tenant/routines'
import dictionary from '@/config/dictionary'

export default {
  ...requestFlow(tenantDictionaryRoutine, null).actions,

  async [tenantDictionaryRoutine.TRIGGER](context, payload) {
    const tenantName = context.getters[tenantGetters.TENANT_NAME]
    if (!tenantName) {
      await context.dispatch(tenantDictionaryRoutine.SUCCESS, {})
      return null
    }
    try {
      await context.dispatch(tenantDictionaryRoutine.SUCCESS, dictionary)
    } catch (error) {
      context.dispatch(tenantDictionaryRoutine.FAILURE, error)
      throw error
    }
    return null
  }
}
