import { illionIframeRoutine, illionBankstatementsRoutine, ILLION_CLEANUP } from '@/store/modules/illion/routines'
import requestFlow from '@/utils/requestFlow'
import { state as initialState } from './index'

export default {
  ...requestFlow(illionIframeRoutine).actions,
  ...requestFlow(illionBankstatementsRoutine).actions,

  async [illionIframeRoutine.TRIGGER](context, payload) {
    context.commit(illionIframeRoutine.SUCCESS, payload)
  },

  async [illionBankstatementsRoutine.TRIGGER](context, payload) {
    context.commit(illionBankstatementsRoutine.SUCCESS, payload)
  },

  [ILLION_CLEANUP](context) {
    context.commit(ILLION_CLEANUP, initialState)
  }
}
