import requestFlow from '@/utils/requestFlow'
import { illionIframeRoutine, illionBankstatementsRoutine, ILLION_CLEANUP } from '@/store/modules/illion/routines'

export default {
  ...requestFlow(illionIframeRoutine).mockMutations,
  ...requestFlow(illionBankstatementsRoutine).mockMutations,

  [illionIframeRoutine.SUCCESS](state, payload) {
    state.iframeUrl = payload
  },

  [illionBankstatementsRoutine.SUCCESS](state, payload) {
    state.bankStatements = payload
  },

  [ILLION_CLEANUP] (state, payload) {
    Object.assign(state, payload)
  }
}
