export default {
  path: 'static-banking',
  name: 'savings-static-banking',
  component: () => import(/* webpackChunkName: "static-banking" */ './static-banking.vue'),
  meta: {
    permissions: {
      roles: ['user'],
      redirectTo: {
        name: 'login'
      }
    }
  },
  beforeEnter: () => {
    // This route is not being used anymore. Redirecting the user to marketing website.
    window.location.href = 'https://swoopfunding.com/compare-business-current-account/?prev_page=app_BCA'
  }
}
