<template>
  <ValidationProvider class="file-upload text-center m-b-20" :rules="rules" :name="name" :vid="name" ref="validationProvider" #default="{ errors, validate }" tag="div">
    <input
      id="file-upload-input"
      class="file-upload-input"
      ref="fileEl"
      type="file"
      :name="name"
      :multiple="multiple"
      @change="validate"
      @change.prevent="filesChangeHandler($event, validate)"
    />
    <label class="browse-disk-btn" for="file-upload-input">
      <i class="icon material-icons ui m-r-10">cloud_upload</i>
      <span>Drag and drop or browse to upload</span>
    </label>
    <div>
      <FieldError v-bind="{ errors }" />
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import FieldError from '@/components/forms/FieldError'

export default {
  name: 'DropFile',
  components: {
    ValidationProvider,
    FieldError
  },
  props: {
    name: {
      type: String,
      default: 'files'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    rules: {
      type: [Object, String],
      required: true
    }
  },
  methods: {
    filesChangeHandler(e, validate) {
      return validate().then(result => {
        if (result.valid) {
          const fileList = Array.prototype.map.call(e.target.files, file => ({ type: null, file }))
          if (fileList.length) {
            this.$emit('change', fileList)
          }
        }
      })
    },
    renew() {
      if (this.$refs.fileEl) {
        this.$refs.fileEl.value = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/swoop/variables';

.file-upload {
  position: relative;
  flex-basis: 50%;
  min-width: 40%;
  background-color: var(--color-primary-100);
  border: 1px dashed var(--color-primary-100);
  border-radius: $default-border-radius;
  padding: 20px 0;
  @media only screen and (max-width: $breakpoint-sm-max) {
    flex-basis: 100%;
    margin-left: 0;
  }
  .file-upload-input {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
  }
  .cloud-icon,
  .or-label {
    color: $color-secondary;
  }
  .browse-disk-btn {
    position: relative;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
