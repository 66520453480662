import { transitionRoutine, TRANSITION_CLEANUP } from '@/store/modules/transition/routines'
import { state as initialState } from './index'

export default {
  [transitionRoutine.TRIGGER](context, payload) {
    context.commit(transitionRoutine.TRIGGER, payload)
  },
  [TRANSITION_CLEANUP](context) {
    context.commit(TRANSITION_CLEANUP, initialState)
  }
}
