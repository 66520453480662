<template>
  <div v-if="requiredDocumentsList">
    <ul v-if="requiredDocumentsList.length">
      <li v-for="doc in requiredDocumentsList" :key="doc.key">
        <Checkbox class="list-item" :name="doc.type + doc.title" :label="doc.label" :value="doc.isDocumentUploaded" @input="(checked) => $emit('click:checkbox', {...doc, checked })" v-if="doc.isCheckBox" />
        <a :class="{ link: true, disabled: allowMultipleFiles === false && doc.isDocumentUploaded === true }" @click.prevent="uploadDocumentClickHandler(doc)" v-else>
          <div>
            <span>{{ doc.label }}</span>
            <ul class="file-names" v-if="doc.uploadedDocumentNames && doc.uploadedDocumentNames.length">
              <li v-for="fileName in doc.uploadedDocumentNames" :key="fileName">{{ fileName }}</li>
            </ul>
          </div>
          <i :class="doc.isDocumentUploaded ? 'icon-upload-completed' : 'icon-upload'"></i>
        </a>
      </li>
    </ul>
    <div class="ui small message" v-else>No required documents</div>

    <modal name="productDocumentsModal" height="auto" :scrollable="true" :width="780" @closed="productDocumentsModalCloseHandler">
      <div class="product-documents-modal" v-if="currentDocument">
        <div class="ui header dark text">Upload your {{ currentDocument.label }}</div>
        <div class="ui subtitle">To upload, make sure it's a PDF smaller than 20MB</div>
        <div class="ui active centered inline loader m-t-2" v-if="isDocumentUploading">
          <spinner :size="120" :loader="true" />
        </div>
        <template v-else>
          <DropFile class="ui m-t-1" :rules="`size:20480|ext:${whiteListDocumentExtensions}`" @change="dropFileChangeHandler" ref="dropFile" />
          <div class="text-danger ui m-b-1" v-if="documentUploadErrorResponse && documentUploadErrorResponse.data">{{ documentUploadErrorResponse.data.message }}</div>
          <DocumentsList class="documents-list" :filesList="filteredFilesList" @change="fileListChangeHandler" />
        </template>
        <div class="p-t-50 text-right">
          <button class="btn btn-primary btn-sm p-l-40 p-r-40" type="button" @click="$modal.hide('productDocumentsModal')">Done</button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import DocumentsList from '@/components/DocumentsList'
import DropFile from '@/components/forms/DropFile'
import Checkbox from '@/components/forms/Checkbox'
import { sendDocument } from '@/api/document'
import { whiteListDocumentExtensions } from '@/utils/constants'
import { sendBasicEventToAmplitude, journeyStepsButtonAmplitudeEvent } from '@/utils/utils'

export default {
  name: 'ProductRequiredDocuments',
  components: {
    DocumentsList,
    DropFile,
    Checkbox
  },
  props: {
    documentsList: {
      type: Array
    },
    requiredDocumentsList: {
      type: Array
    },
    allowMultipleFiles: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      whiteListDocumentExtensions,
      currentDocument: null,
      isDocumentUploading: false,
      documentUploadErrorResponse: null,
      sendBasicEventToAmplitude,
      journeyStepsButtonAmplitudeEvent
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID
    }),
    filteredFilesList() {
      if (!this.currentDocument || !this.documentsList) return []
      return this.documentsList.filter(doc => doc.title === this.currentDocument.title && doc.type === this.currentDocument.type)
    }
  },
  methods: {
    uploadDocumentClickHandler(doc) {
      sendBasicEventToAmplitude(this.$ma, journeyStepsButtonAmplitudeEvent(doc.amplitudeEvent))
      if (this.allowMultipleFiles === false && doc.isDocumentUploaded === true) return
      this.currentDocument = doc
      this.$modal.show('productDocumentsModal')
    },
    productDocumentsModalCloseHandler() {
      this.currentDocument = null
      this.documentUploadErrorResponse = null
    },
    dropFileChangeHandler(fileList) {
      this.isDocumentUploading = true
      this.documentUploadErrorResponse = null
      const uDocPromises = fileList.map(doc => {
        const params = {
          companyId: this.companyId,
          type: this.currentDocument.type,
          title: this.currentDocument.title
        }
        const formData = new FormData()
        formData.append('file', doc.file)
        return sendDocument(formData, params)
      })
      return Promise.all(uDocPromises)
        .then(() => {
          this.fileListChangeHandler()
          this.$nextTick(() => {
            this.isDocumentUploading = false
            this.$modal.hide('productDocumentsModal')
          })
        })
        .catch(e => {
          this.documentUploadErrorResponse = e.response
          this.isDocumentUploading = false
        })
    },
    fileListChangeHandler() {
      this.$emit('change')
    }
  }
}
</script>

<style lang="scss" scoped>
.product-documents-modal {
  .h2,
  .h4 {
    font-weight: normal;
  }
  .loader {
    display: flex;
    justify-content: center;
  }
}
.documents-list {
  /deep/ {
    .file {
      .delete-btn {
        justify-content: center;
      }
    }
  }
}
.link:not(.disabled) {
  cursor: pointer;
}
.file-names {
  padding: 0;
  margin: 0;
  list-style: none;
}
ul {
  /deep/ {
    .checkbox {
      > .flex {
        flex-grow: 1;
        flex-direction: row-reverse;
        justify-content: space-between;
        font-size: initial;
        > .icon {
          margin: 0 2px 0 calc(0.25rem + 2px);
        }
      }
    }
  }
}
</style>
