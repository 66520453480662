import router from '@/router'

// Setup sign up routes.
export default () => {
  router.addRoutes([
    {
      path: '/sign-up',
      name: 'sign-up',
      component: () => import(/* webpackChunkName: "sign-up-page" */ './signUpForm.vue'),
      alias: ['/sign-up/user']
    }
  ])
}
