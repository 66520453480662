import requestFlow from '@/utils/requestFlow'
import { cashFlowRoutine, CASH_FLOW_DETAILS_CLEANUP } from './routines'

export default {
  ...requestFlow(cashFlowRoutine).mockMutations,

  [cashFlowRoutine.SUCCESS](state, payload) {
    state.cashFlowDetails = payload
  },

  [CASH_FLOW_DETAILS_CLEANUP](state, payload) {
    Object.assign(state, payload)
  }
}
