export default {
  path: 'welcome',
  name: 'journey-welcome',
  component: () => import(/* webpackChunkName: "welcome" */ './welcome.vue'),
  meta: {
    permissions: {
      roles: ['user'],
      redirectTo: {
        name: 'login'
      }
    }
  }
}
