<template>
  <div class="sidebar-wrapper" :class="{ 'mobile-sidebar-open': mobileSidebarOpen, 'mobile-sidebar-closed': !mobileSidebarOpen }" @click="closeSidebar">
    <aside :class="{ 'sidebar-closed': !sidebarOpen }" @click.stop>
      <div class="menu">
        <div v-for="item in menuItems" :key="item.text" class="menu-item">
          <div class="menu-item-main" :class="{ 'menu-item-has-children': item.children }" v-if="item.onClick" @click="item.onClick">
            <AppSidebarIcons :icon="item.icon" :class="'menu-item-icon'" />
            <span class="menu-item-text">
              {{ item.text }}
            </span>
          </div>

          <a
            :href="item.externalLink"
            @click="() => trackClickEvent(item) && $emit('close-mobile-sidebar')"
            target="_blank"
            class="menu-item-main link"
            :class="{ 'menu-item-has-children': item.children }"
            v-else-if="item.externalLink"
          >
            <AppSidebarIcons :icon="item.icon" :class="'menu-item-icon'" />
            <span class="menu-item-text">
              {{ item.text }}
            </span>
          </a>

          <div class="menu-item-main" :class="{ 'menu-item-has-children': item.children }" v-else-if="!item.routeName">
            <AppSidebarIcons :icon="item.icon" :class="'menu-item-icon'" />
            <span class="menu-item-text">
              {{ item.text }}
            </span>
          </div>

          <router-link
            @click.native="() => trackClickEvent(item) && $emit('close-mobile-sidebar')"
            :to="{ name: item.routeName }"
            :params="item.routeParams ? item.routeParams : {}"
            class="menu-item-main link"
            :class="{ 'menu-item-has-children': item.children }"
            v-else
          >
            <AppSidebarIcons :icon="item.icon" :class="'menu-item-icon'" />
            <span class="menu-item-text">
              {{ item.text }}
            </span>
          </router-link>
          <div class="menu-item-children" v-if="item.children">
            <router-link
              class="menu-item-child link"
              v-for="childItem in fundingItems"
              :to="{ name: childItem.routeName }"
              :key="`${childItem.text}`"
              @click.native="trackClickEvent(childItem) && $emit('close-mobile-sidebar')"
            >
              <span class="text">{{ childItem.text }}</span>
              <span v-if="childItem" class="tooltip">{{ childItem.text }} <span class="counts"></span></span>
              <span class="material-icons" :data-cy="`${childItem.routeName}-locked`" v-if="childItem.locked"> lock </span>
              <span class="material-icons" :data-cy="`${childItem.routeName}-unlocked`" v-else> check </span>
            </router-link>
          </div>
        </div>

        <div class="mobile-only">
          <div class="menu-item">
            <router-link :to="{ name: 'data-room', params: { step: 'financials' } }" class="link" @click.native="trackClickEvent({ text: 'data room' })"> Data Room </router-link>
          </div>
          <div class="menu-item">
            <router-link :to="{ name: 'profile' }" class="link" @click.native="trackClickEvent({ text: 'my profile' })"> My Profile </router-link>
          </div>
          <div class="menu-item" v-if="$config.whitelabel.links.footerSwoopLinkEnabled">Swoop Website</div>
          <div class="menu-item" v-if="$config.whitelabel.components.swoopMarketingUrlEnabled">
            <a :href="marketingUrl" target="_blank" class="link"> Swoop Website </a>
          </div>
          <div class="menu-item">
            <a :href="$config.whitelabel.links.privacyPolicyLink" target="_blank" class="link"> Privacy Policy </a>
          </div>
          <div class="menu-item">
            <a :href="$config.whitelabel.links.termsAndConditionsLink" target="_blank" class="link"> Terms & Conditions </a>
          </div>
          <div class="menu-item" @click="logout">Logout</div>
          <div class="menu-item"></div>
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getLockedProducts } from '@/utils/utils'
import { authGetters, logoutRoutine } from '@/store/modules/auth/routines'
import { companyGetters, companySelectRoutine } from '@/store/modules/company/routines'
import AppSidebarIcons from './AppSidebarIcons'
import { marketingUrl } from '@/utils/constants'
import config from '@/config/global'
import { getCompany } from '@/api/company'

export default {
  components: { AppSidebarIcons },
  props: {
    mobileSidebarOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sidebarOpen: true,
      windowSize: window.innerWidth,
      marketingUrl,
      isSoleTrader: false,
      fundingItems: [],
      lockedProducts: {}
    }
  },
  mounted() {
    if (!this.sidebarItems) {
      this.updateLockedProducts()
    }
    this.isCompanySoleTrader()

    window.addEventListener('resize', () => {
      this.windowSize = window.innerWidth
    })
  },
  watch: {
    windowSize(value) {
      if (value >= 1024) this.$emit('close-mobile-sidebar')
    },
    user() {
      this.updateLockedProducts()
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.windowSize)
  },
  methods: {
    ...mapActions({
      selectCompany: companySelectRoutine.TRIGGER,
      triggerLogout: logoutRoutine.TRIGGER
    }),
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen
    },
    trackClickEvent(item) {
      this.$emit('close-mobile-sidebar')

      this.$ma.trackEvent({
        eventType: 'Sidenav clicked',
        eventProperties: {
          item: item.text.toLowerCase()
        }
      })
    },
    updateLockedProducts() {
      this.fundingItems = []
      this.lockedProducts = getLockedProducts(this.user?.goalsCompleted)

      Object.keys(this.lockedProducts).forEach(lockedProduct => {
        const productName = lockedProduct.replace('Locked', '')
        this.setLockedProduct(productName, this.lockedProducts[lockedProduct])
      })
    },
    setLockedProduct(productName, locked) {
      const foundItem = this.menuItems.find(item => item.text && item.text.toLowerCase() === 'matches')

      if (foundItem) {
        const foundChild = foundItem.children.find(child => child.productName === productName)

        if (foundChild) {
          foundChild.locked = locked
          this.fundingItems.push(foundChild)
        }
      }
    },
    backToClients() {
      this.selectCompany(null).then(() => {
        this.$emit('close-mobile-sidebar')
        this.$router.push({ name: 'dashboard' })
      })
    },
    logout() {
      this.trackClickEvent({ text: 'logout' })
      this.triggerLogout()
    },
    defaultMatchesRouteName() {
      if (this.$config.whitelabel.country.defaultCountry === 'United Kingdom') return 'matches-overview'
      if (this.$config.whitelabel.journey.loansEnabled) return 'loan-matches'
      if (this.$config.whitelabel.journey.grantsEnabled) return 'grant-matches'
      if (this.$config.whitelabel.journey.equityEnabled) return 'equity-matches'
      return 'dashboard'
    },
    isCompanySoleTrader() {
      getCompany(this.companyId)
        .then(res => {
          this.isSoleTrader = res?.data?.companyStructures?.includes('Sole Trader')
        })
        .catch(err => {
          console.error(err)
        })
    },
    closeSidebar() {
      this.$emit('close-mobile-sidebar')
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID,
      currentCompanyId: companyGetters.COMPANY_CURRENT_ID,
      user: authGetters.USER,
      isInvitedUser: authGetters.IS_INVITED_USER
    }),
    menuItems() {
      return this.sidebarItems || this.defaultSidebarItems
    },
    isMobile() {
      return this.windowSize < 770
    },
    defaultSidebarItems() {
      return [
        {
          text: 'Documents',
          icon: 'overview',
          routeName: 'data-room',
          routeParams: { step: 'financials' },
          enabled: this.isInvitedUser
        },
        {
          text: 'Overview',
          icon: 'overview',
          routeName: 'dashboard',
          enabled: !this.isInvitedUser
        },
        {
          text: 'Matches',
          icon: 'matches',
          routeName: this.defaultMatchesRouteName(),
          enabled:
            !this.isInvitedUser && (this.$config.whitelabel.journey.loansEnabled || this.$config.whitelabel.journey.equityEnabled || this.$config.whitelabel.journey.grantsEnabled),
          children: [
            {
              text: 'Loan funding',
              productName: 'loans',
              routeName: 'loan-matches',
              locked: true,
              enabled: this.$config.whitelabel.journey.loansEnabled
            },
            {
              text: 'Equity funding',
              productName: 'equity',
              routeName: 'equity-matches',
              locked: true,
              enabled: this.$config.whitelabel.journey.equityEnabled
            },
            {
              text: 'Grant funding',
              productName: 'grants',
              routeName: 'grant-matches',
              locked: false,
              enabled: this.$config.whitelabel.journey.grantsEnabled
            }
          ].filter(item => item.enabled)
        },
        {
          text: 'Cash Flow & Forecasting',
          icon: 'banking',
          routeName: 'cashflow-forecasting-integration',
          enabled: !this.isInvitedUser && this.$config?.whitelabel.features.cashflowForecastingEnabled
        },
        {
          text: 'Banking',
          externalLink: 'https://swoopfunding.com/compare-business-current-account/?prev_page=app_BCA',
          icon: 'banking',
          enabled: !this.isInvitedUser && config && this.$config.whitelabel.features.savings && config.whitelabel.features.savingsServices.banking
        },
        {
          text: 'Utilities',
          routeName: 'utilities',
          icon: 'utilities',
          enabled: !this.isInvitedUser && config && config.whitelabel.features.savings && config.whitelabel.features.savingsServices.utilities
        },
        {
          text: 'Credit health check',
          routeName: 'credit-health-check',
          icon: 'credit_score',
          enabled: !this.isInvitedUser && !this.isSoleTrader && config.whitelabel.components.creditPassportEnabled
        },
        {
          text: 'FX',
          routeName: 'foreign-exchange',
          icon: 'fx',
          enabled: !this.isInvitedUser && config && config.whitelabel.features.savings && config.whitelabel.features.savingsServices.fx
        },
        {
          text: 'Insurance',
          routeName: 'insurance',
          icon: 'insurance',
          enabled: !this.isInvitedUser && config && config.whitelabel.features.savingsServices.insurance
        },
        {
          text: 'Integrations',
          routeName: 'integrations',
          icon: 'integrations',
          enabled:
            config.whitelabel.features.integrationsEnabled &&
            (config.whitelabel.features.illionEnabled || config.whitelabel.features.linkAccountancy || config.whitelabel.features.linkBankAccount)
        }
      ].filter(item => item.enabled)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/swoop/_variables.scss';
@import '@/assets/styles/swoop/_mixins.scss';

.sidebar-wrapper {
  &.mobile-sidebar-closed {
    display: none;

    @media only screen and (min-width: $breakpoint-md) {
      display: block;
    }
  }

  &.mobile-sidebar-open {
    position: fixed;
    z-index: 99;
    height: 100%;
    width: 100%;
    background-color: $color-mobile-sidebar-background;

    aside {
      height: -webkit-fill-available;
      width: 80%;
      min-width: $sidebar-width;
      max-width: 310px;
      position: fixed;
      overflow: auto;
    }

    @media only screen and (min-width: $breakpoint-md) {
      position: relative;
      z-index: 2;
      width: $sidebar-width;
      height: initial;
      background-color: transparent;

      aside {
        width: $sidebar-width;
      }
    }
  }

  aside {
    height: -webkit-fill-available;
    width: $sidebar-width;

    &.sidebar-closed {
      width: 65px;
      padding: 5px;

      .menu {
        .menu-item {
          .menu-item-main {
            margin: 10px 0;

            .menu-item-icon {
              &:hover,
              &:focus {
                color: $color-sidebar-active;
              }
            }

            .menu-item-text {
              opacity: 0;
              pointer-events: none;
            }
          }
        }
      }

      .menu-item-children {
        border: 1px solid var(--color-primary-200);
        background-color: var(--color-tertiary-50);
        border-radius: 4px;

        .menu-item-child {
          margin: 15px 0 !important;

          span.text {
            display: none;
          }

          &:parent {
            display: none !important;
          }

          .menu-item-child-icon {
            display: block !important;
            &:hover,
            &:focus {
              color: $color-sidebar-active;
            }
          }

          .material-icons {
            opacity: 0;
          }

          .tooltip {
            display: none !important;
          }

          &:hover {
            span.tooltip {
              display: block !important;
              left: 42px;
              width: 150px;
              background-color: $color-primary-button-hover;
              border: 1px solid var(--color-neutral-50);
              color: $color-primary-button-text;
              text-align: center;
              font-size: 14px;
              padding: 5px;
              border-radius: 6px;
              position: absolute;
              font-family: 'Public Sans', sans-serif;
              z-index: 1;
              transition: all 0.3s;

              .counts {
                margin-left: 5px;
                background-color: $color-white;
                color: $color-primary;
                font-weight: bold;
                border-radius: 50%;
                padding: 5px;
              }
            }
          }
        }
      }
      .toggle-sidebar {
        transform: rotate(-180deg);
      }
    }

    .menu {
      height: calc(100vh - 55px);
      width: $sidebar-width;
      position: fixed;
      margin-top: 56px;
      padding: 15px;
      border-right: 1px solid #e9eaea;
      background-color: #fff;
      overflow: auto;
      transition: width 0.6s;

      .mobile-only {
        @media only screen and (min-width: $breakpoint-md) {
          display: none;
        }

        .menu-item {
          padding: 8px;
          color: $color-inactive;
          font-size: 14px;
          &:nth-of-type(1) {
            border-top: 1px solid var(--color-primary-200);
            padding-top: 14px;
          }

          &:nth-of-type(7) {
            border-bottom: 1px solid var(--color-primary-200);
            padding-bottom: 14px;
          }

          &:last-of-type {
            padding-top: 14px;
          }

          .link {
            text-decoration: none;
            color: $color-inactive;
            font-size: 14px;

            &:hover {
              color: $color-sidebar-active;
            }
          }

          &:hover {
            color: $color-primary-button-hover;
            cursor: pointer;
          }
        }
      }
      .menu-item .menu-item-main {
        display: flex;
        align-self: center;
        position: relative;
        padding: 8px;
        cursor: pointer;
        margin-bottom: 16px;
        border-radius: 8px;
        font-size: 14px;
        color: $color-inactive;
        transition: all 0.3s;
        margin: 20px 0;

        &:first-child {
          margin-top: 0px;
        }

        &.menu-item-has-children {
          margin: 0;
        }

        &.link {
          text-decoration: none;
        }

        .menu-item-icon {
          width: 25px;
          height: 25px;
          fill: $color-inactive;
        }

        &:hover,
        &.router-link-active {
          color: $color-sidebar-active;
          .menu-item-icon {
            fill: $color-sidebar-active;
          }
        }

        .router-link-active .menu-item-icon {
          fill: $color-sidebar-active;
        }

        .material-icons {
          margin-right: 8px;
          transition: all 0.5s;
        }

        .menu-item-text {
          margin-left: 10px;
        }
      }

      .menu-item-children {
        margin: 0 8px;

        .menu-item-child {
          font-size: 14px;
          padding: 2px 0;
          min-width: 115px;
          margin: 7px 0;
          color: $color-inactive;
          cursor: pointer;
          border-radius: 8px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          transition: all 0.3s;
          text-decoration: none;

          .menu-item-child-icon {
            display: none;
          }

          &:hover,
          &.router-link-active {
            color: $color-sidebar-active;

            .material-icons {
              background-color: $color-sidebar-active;
              color: $color-white;
            }
          }

          .material-icons {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            background-color: $color-sidebar-icon-inactive;
            color: $color-sidebar-icon-inactive-text;
            border-radius: 10px;
            width: 20px;
            height: 20px;
            transition: all 0.3s;
          }
          .tooltip {
            display: none !important;
          }
          .link {
            text-decoration: none;
          }
        }
      }
    }

    .toggle-sidebar {
      position: absolute;
      width: 26px;
      height: 26px;
      display: flex;
      justify-content: center;
      color: var(--color-secondary-50);
      background-color: $color-white;
      align-items: center;
      right: -12px;
      top: 12px;
      cursor: pointer;
      border: 1px solid var(--color-secondary-50);
      border-radius: 50%;
      font-size: 16px;
      transition: all 0.3s;

      &:hover {
        background-color: $color-primary;
        color: $color-white;
        box-shadow: $focus-box-shadow;
      }
    }
  }
}
</style>
