import createRoutine from '@/utils/createRoutine'

export const companyRoutine = createRoutine('companyRoutine')
export const companyStateRoutine = createRoutine('companyStateRoutine')
export const companySelectRoutine = createRoutine('companySelectRoutine')

export const companyGetters = {
  COMPANIES_LIST: 'COMPANIES_LIST',
  COMPANY_ID: 'COMPANY_ID',
  COMPANY_CURRENT: 'COMPANY_CURRENT',
  COMPANY_STATE: 'COMPANY_STATE',
  COMPANY_CURRENT_ID: 'COMPANY_CURRENT_ID',
  COMPANY_OWN: 'COMPANY_OWN',
  COMPANY_CURRENT_LAST_YEAR_TURNOVER: 'COMPANY_CURRENT_LAST_YEAR_TURNOVER'
}

export const COMPANY_CLEANUP = 'COMPANY_CLEANUP'
