// DEPRECATED! Please, do not use this component going forward. This should be replaced with the same component inside /dynamicForms folder.
<template>
  <div :class="[containerClassName]">
    <!-- Day -->
    <div :class="[selectWrapperClassName]">
      Day
      <select v-model="selectedDay" :class="[selectClassName, selectDayClassName]" :rules="rules" :name="name" :field="field">
        <option value="-1" disabled selected>DD</option>
        <option v-for="day in days" :key="day.day" :value="day.day">
          {{ day.day }}
        </option>
      </select>
    </div>

    <!-- Month -->
    <div class="date-dropdown-select-wrapper-month" :class="[selectWrapperClassName]">
      Month
      <select v-model="selectedMonth" @change="updateDays()" :class="[selectClassName, selectMonthClassName]" :rules="rules" :name="name" :field="field">
        <option value="-1" disabled selected>MM</option>
        <option v-for="(month, index) in months" :value="index" :key="month.month">
          {{ month.month }}
        </option>
      </select>
    </div>

    <!-- Year -->
    <div :class="[selectWrapperClassName]">
      Year
      <select v-model="selectedYear" @change="updateDays()" :class="[selectClassName, selectYearClassName]" :rules="rules" :name="name" :field="field">
        <option value="-1" disabled selected>YYYY</option>
        <option v-for="year in years" :key="year.year" :value="year.year">
          {{ year.year }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
const defaultMonths = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '10',
  11: '11',
  12: '12'
}
export default {
  name: 'DateDropdown',
  props: {
    default: {
      type: String,
      required: false
    },
    min: {
      type: String,
      required: false
    },
    max: {
      type: String,
      required: false,
      default: (new Date().getFullYear() + 1).toString()
    },
    monthsNames: {
      type: String,
      required: false
    },
    selectClassName: {
      type: String,
      required: false,
      default: 'date-dropdown-select'
    },
    selectDayClassName: {
      type: String,
      required: false,
      default: 'day'
    },
    selectMonthClassName: {
      type: String,
      required: false,
      default: 'month'
    },
    selectYearClassName: {
      type: String,
      required: false,
      default: 'year'
    },
    selectWrapperClassName: {
      type: String,
      required: false,
      default: 'date-dropdown-select-wrapper'
    },
    containerClassName: {
      type: String,
      required: false,
      default: 'date-dropdown-container'
    },
    rules: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: false
    },
    field: {
      type: [Object, String],
      required: false
    }
  },
  data() {
    return {
      days: [],
      selectedDay: '',
      selectedMonth: '',
      selectedYear: ''
    }
  },
  computed: {
    initialDate() {
      return !!(this.default || this.min)
    },
    specifiedDate() {
      return new Date(this.default)
    },
    minDate() {
      if (this.min) {
        return new Date(this.min)
      } else {
        return false
      }
    },
    maxDate() {
      if (this.max) {
        return new Date(this.max)
      } else {
        return false
      }
    },
    calculatedDate() {
      const day = this.selectedDay >= 10 ? this.selectedDay : `0${this.selectedDay}`
      const month = this.selectedMonth + 1 >= 10 ? this.selectedMonth + 1 : `0${this.selectedMonth + 1}`
      return `${month}/${day}/${this.selectedYear}`
    },
    dividedNamesOfMonths() {
      if (this.monthsNames) {
        return this.monthsNames.replace(/\s/g, '').split(',')
      } else {
        return false
      }
    },
    months() {
      const months = []
      for (let i = 1; i < 13; i++) {
        if (this.dividedNamesOfMonths) {
          months.push(this.dividedNamesOfMonths[i])
        } else {
          months.push(defaultMonths[i])
        }
      }
      return months.map((month, index) => {
        return { month, selected: index === this.selectedMonth }
      })
    },
    years() {
      let firstYear
      if (this.min) {
        firstYear = this.minDate.getFullYear()
      } else if (this.default) {
        firstYear = this.specifiedDate.getFullYear()
      } else {
        firstYear = new Date().getFullYear()
      }
      const through = this.max ? this.maxDate.getFullYear() + 1 - firstYear : 101
      const years = []
      for (let i = firstYear, len = firstYear + through; i < len; i++) {
        years.push(i)
      }
      return years.reverse().map(year => {
        return { year, selected: year === this.selectedYear }
      })
    }
  },
  methods: {
    getDays() {
      const days = []
      const daysNumberOfMonth = new Date(this.selectedYear, this.selectedMonth + 1, 0).getDate()
      for (let i = 1; i < daysNumberOfMonth + 1; i++) {
        days.push(i)
      }
      return days.map(day => {
        return { day, selected: days === this.selectedDay }
      })
    },
    updateDays() {
      this.days = this.getDays()
    },
    sendDate() {
      const date = this.format ? this.format(this.calculatedDate) : this.calculatedDate
      if (!isNaN(Date.parse(date))) {
        this.$emit('input', date)
      } else {
        this.$emit('input', null)
      }
    },
    setDate() {
      this.updateDays()
      let date
      if (this.min && this.max && !this.default) {
        date = new Date(this.min)
        this.selectedDay = '-1'
        this.selectedMonth = '-1'
        this.selectedYear = '-1'
      } else if (this.default) {
        date = new Date(this.default)
        this.selectedDay = date.getDate()
        this.selectedMonth = date.getMonth()
        this.selectedYear = date.getFullYear()
      } else {
        date = new Date()
        this.selectedDay = '-1'
        this.selectedMonth = '-1'
        this.selectedYear = '-1'
      }
      this.sendDate()
    }
  },
  created() {
    this.setDate()
  },
  updated() {
    this.sendDate()
  }
}
</script>

<style lang="scss" scoped>
.date-dropdown-container {
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 770px) {
    flex-direction: column;
  }
}
.date-dropdown-select {
  display: inline-block;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  padding: 8px;
  margin-right: 10px;
  height: 38px !important;
  border-radius: 10px !important;
  @media only screen and (max-width: 770px) {
    margin-bottom: 10px;
  }
}
.date-dropdown-select-wrapper {
  width: 100%;
}
.date-dropdown-select-wrapper-month {
  margin: 0 7px;

  @media only screen and (max-width: 770px) {
    margin: 0;
  }
}
</style>
